import React from "react";
import * as echarts from "echarts";

const LineChart = ({ data }) => {
  const chartRef = React.useRef(null);

  React.useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const option = {
      title: {
        text: data?.title ?? "",
        textStyle: {
          color: "white",
          fontSize: 12,
        },
        left: "20px",
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        name: data?.xaxis,
        nameLocation: "center",
        nameGap: 37,
        nameTextStyle: {
          color: "white",
          fontSize: 12,
        },
        type: "category",
        data: data?.xData ?? [],
        axisLabel: {
          textStyle: {
            color: "white",
            fontSize: 12,
          },
        },
      },
      yAxis: {
        name: data?.yaxis,
        nameLocation: "center",
        nameGap: 37,
        nameTextStyle: {
          color: "white",
          fontSize: 12,
        },
        min: data?.min,
        max: data?.max,
        type: "value",
        axisLabel: {
          textStyle: {
            color: "white",
            fontSize: 12,
          },
        },
      },
      series: [
        {
          name: "Actual",
          type: "line",
          data: data?.data.actual,
          color: "green",
        },
        {
          name: "Predicted",
          type: "line",
          data: data?.data.predicted,
          color: "orange",
        },
      ],
    };

    chartInstance.setOption(option);

    return () => {
      chartInstance.dispose();
    };
  }, [data]);

  return <div ref={chartRef} className="chart-container m-2" style={{ height: "60vh", width: "45vw" }} />;
};

export default LineChart;

import React, { useEffect, useRef, useState, useContext, useCallback } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { GoogleMap } from "@react-google-maps/api";
import { HttpServiceContext } from "../../config/httpContext";
import Sop from "../common/sop";
import { CommonServiceContext } from "../../config/commonContext";
import ProgressTracking from "../traffic/accidents/progressTracking";

import AqiTabs from "../common/dashboardtabs";

const Aqi = () => {
  const mapRef = useRef(null);
  const markerRef = useRef([]);
  const [mapKey, setMapKey] = useState(0);
  const [selectedOption, setSelectedOption] = useState();
  const [mapHeight, setMapHeight] = useState("95vh");
  const [showWindow, setShowWindow] = useState(false);
  const [sopSuccessful, setSopSuccessFul] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [activeTab, setActiveTab] = useState("Progress");
  const httpContext = useContext(HttpServiceContext);
  const [sopClose, setSopClose] = useState(false);
  const commonContext = useContext(CommonServiceContext);
  const [accidentDetails, setAccidentDetails] = useState({
    latitude: 37.783413877011085,
    longitude: -122.42432091193467,
    place: "",
  });
  const [mapCenter, setMapCenter] = useState({
    lat: 37.783413877011085,
    lng: -122.42432091193467,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDMlVAALBCx-2tliIzQKST8qFwIyCvIRc4",
  });
  const mapContainerStyle = {
    width: "100%",
    border: "transparent",
    borderRadius: "25px",
  };
  const center = {
    lat: 37.7839,
    lng: -122.4248,
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    commonContext.setSelectedType([]);
  };

  const onMapLoad = (map) => {
    mapRef.current = map;
    updateMarkers(map);
  };
  async function setPlacesName() {
    let plc = await httpContext.getLocation(mapCenter.lat, mapCenter.lng);
    if (plc) {
      setAccidentDetails({
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        place: plc.display_name,
      });
    }
  }

  const updateMarkers = (map) => {
    // Clear existing markers
    markerRef.current.forEach((marker) => marker.setMap(null));
    markerRef.current = [];

    // Add CCTV marker
    const cctvMarker = new window.google.maps.Marker({
      position: { lat: 37.784, lng: -122.4251 },
      map: map,
      title: "Incident Location",
      icon: {
        url: "./assets/cctv.png",
        scaledSize: new window.google.maps.Size(70, 70),
      },
    });

    // Add alert marker
    const alertMarker = new window.google.maps.Marker({
      position: { lat: 37.784, lng: -122.42455 },
      map: map,
      title: "Incident Location",
      icon: {
        url: "./assets/alert.png",
        scaledSize: new window.google.maps.Size(40, 40),
      },
    });
    new window.google.maps.Circle({
      strokeColor: "#FFCCCC",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF6666",
      fillOpacity: 0.35,
      map: map,
      center: { lat: 37.784, lng: -122.4251 },
      radius: 60,
    });
    markerRef.current.push(cctvMarker, alertMarker);

    alertMarker.addListener("click", () => setSelectedMarker(true));
    cctvMarker.addListener("click", () => {
      setSelectedMarker(null);
      setMapHeight("95vh");
    });

    if (workOrder) {
      let isVisible = true;
      const blinkInterval = setInterval(() => {
        isVisible = !isVisible;
        alertMarker.setMap(isVisible ? map : null);
      }, 500);
      return () => clearInterval(blinkInterval);
    }
  };

  useEffect(() => {
    setPlacesName();
  });

  const handlesop = async () => {
    setSopSuccessFul(true);
    setWorkOrder(true);
    setMapHeight("60vh");

    // const url =
    //   "http://omnific_api.astrikos.xyz:3080/sop/create?Summary=AQI%20PM2.5 high&Details=PM2.5 high&MasterCriticalityId=2&MasterCategoryTypeId=83";
    let sopResponse = await httpContext.createSop("AQI PM2.5 high", "PM2.5 high", 2, 83);
    const summary = "AQI PM2.5 high";

    // fetchSOP(url);

    const intervalId = setInterval(async () => {
      try {
        const status = await httpContext.verifyIncident(summary);
        if (status && status.status === "Closed") {
          setSopClose(true);
          const deviceData = await httpContext.getDeviceState();
          if (deviceData) {
            deviceData["SIM-008"].status = false;
            httpContext.setDeviceState(deviceData);
          }
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error verifying incident:", error);
      }
    }, 1000);
  };

  const fetchSOP = (url) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  useEffect(() => {
    if (mapRef.current) {
      updateMarkers(mapRef.current);
    }
  }, [workOrder]);

  const openWindow = () => {
    setShowWindow(true);
  };

  const closeSOP = () => {
    setSopSuccessFul(false);
  };

  const closeWindow = () => {
    setShowWindow(false);
    setMapHeight("60vh");
  };

  function sopTab() {
    setActiveTab("Incidents");
    setSopSuccessFul(false);
  }

  if (loadError) {
    return <div>Error Loading map</div>;
  }

  return isLoaded ? (
    <div className="sop-panel p-2">
      {!commonContext.showAnalytics && !showWindow && (
        <div className="row">
          <div className="col-7">
            <GoogleMap key={mapKey} mapContainerStyle={{ ...mapContainerStyle, height: mapHeight }} center={center} zoom={19} onLoad={onMapLoad} />
            {workOrder && (
              <div className="mytabs m-2">
                <div className="tabOptions">
                  <div>
                    <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : ""}`}>
                      Incidents
                    </button>
                  </div>
                  <div>
                    <button onClick={() => setActiveTab("Progress")} className={`tabBtns ${activeTab === "Progress" ? "active" : ""}`}>
                      Progress
                    </button>
                  </div>
                  <div>
                    <th className="mini col-6">
                      <img src="/maximize.png" alt="" className="maximize" onClick={openWindow} />
                    </th>
                  </div>
                </div>
                <div className="tabContent p-0">
                  {activeTab === "Incidents" && <iframe className="sop-iframe" src={httpContext.sop} title="Sop" />}
                  {activeTab === "Progress" && (
                    <div>
                      <div className="set m-2">
                        {workOrder && <ProgressTracking title="AQI" label="aqi" started={workOrder} reached="" closed={sopClose} />}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="col-5">
            <div className="m-1 side-panel side-panel-up">
              <div className="m-1 p-2 details">
                <div className="fs-5 fw-bold text-center">Incident Details</div>
                <div className="m-2">
                  Location : &nbsp;
                  <span className="fs-7 fw-lighter">
                    <a href={`https://www.google.com/maps/place/${accidentDetails.latitude},${accidentDetails.longitude}`}>{accidentDetails.place}</a>
                  </span>
                </div>
                <div className="m-2">
                  Coordinates : &nbsp;
                  <span className="fs-7 fw-lighter">
                    {accidentDetails.latitude}, {accidentDetails.longitude}
                  </span>
                </div>
                <div className="m-2">
                  Violation-Type :&nbsp;
                  <span className="fw-lighter">PM2.5 surge.</span>
                </div>

                {selectedMarker ? (
                  <img className="h-60 w-100" src="./assets/aqiboard.png" />
                ) : (
                  <video className="m-0" autoPlay muted loop width="90%" height="90%">
                    <source src="./assets/aqivideo.mp4" type="video/mp4" />
                  </video>
                )}
                <div className="m-2">
                  Observation :<span className="fw-lighter"> PM2.5 is high by construction dictated in vesanity.</span>
                </div>
                <div className="m-2">
                  Resolution :
                  <span className="fw-lighter">
                    {" "}
                    Advise construction sites to implement dust control measures like water spraying, and restrict truck routes during peak pollution
                    hours.
                  </span>
                </div>
              </div>
            </div>
            <div className="m-3 side-panel side-panel-down">
              <div className="fw-bold fs-5 p-2"> Select Option for SOP</div>
              <div className="main-block-report">
                <label>
                  <input type="radio" name="reportOptions" /> Ignore
                </label>
                <label>
                  <input type="radio" name="reportOptions" onChange={() => handleOptionChange("File Report")} /> File Report
                </label>
              </div>
              {selectedOption && (
                <div className="m-2 action p-2">
                  <div className="fw-bold fs-6">Action</div>
                  <div className="d-flex fs-7 align-items-center justify-content-around">
                    <div>Create a new work order request for File Report</div>
                    <div>
                      <button className="bttns btn fs-7" onClick={handlesop}>
                        Work Order
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showWindow && <AqiTabs closeWindow={closeWindow} active={activeTab} label={"AqiTabs"} />}
      {sopSuccessful && <Sop closeSOP={closeSOP} settab={sopTab} />}
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Aqi;

import React, { useContext, useState } from "react";
import { CommonServiceContext } from "../../config/commonContext";
import { ChartServiceContext } from "../../config/chartContext";
import deviceData from "../../assets/device_state.json";
import Sop from "./sop";

function AdvisoryModel({ showIssue, closeadv, sopTab }) {
  const [sopSuccessful, setSopSuccessFul] = useState(false);
  const [assetInfo, setAssetInfo] = useState([]);
  const [assetModal, setAssetModal] = useState(false);

  const commonContext = useContext(CommonServiceContext);
  const chartContext = useContext(ChartServiceContext);

  function openAnalytics(event, issue) {
    if (issue?.url) {
      let url = issue.url;
      let options = "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600";
      window.open(url, "_blank", options);
    } else {
      let url = commonContext.analyticsURL;
      let options = "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600";
      window.open(url, "_blank", options);
    }
  }

  async function openSopAction(event, issues) {
    var url;
    if (issues.sopURL) {
      url = issues.sopURL;
      setSopSuccessFul(true);
    } else {
      url = commonContext.sopURL;
      setSopSuccessFul(true);
    }
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
    let intervalId;
    if (issues.key === "ahu") {
      intervalId = setInterval(async () => {
        let status = await commonContext.verifyIncident("RAT Anomaly Present");
        if (status && status.status === "Closed") {
          chartContext.setAhuIncident(false);
          commonContext.setDeviceState(deviceData);
          clearInterval(intervalId);
        }
      }, 5000);
    }
  }

  function closeSOP() {
    setSopSuccessFul(false);
  }

  function openAssetDetails(event, data) {
    if (data.metrix) {
      setAssetInfo(data.metrix);
    }
    setAssetModal(true);
  }
  return (
    <div>
      <div className="modal d-block modal-md z-1" tabIndex="1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex border-0 justify-content-end">
              <div>
                <button type="button" className="close btn bttn w-10" onClick={closeadv} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body px-2 m-0">
              <div className="advContainer">
                <div className="m-2">
                  <div className="fs-5 fw-bold headings">Issue</div>
                  <div className="Issue text-justify fs-7">&emsp;{showIssue.observation}</div>
                </div>
                {showIssue.recommendation && (
                  <div className="m-2">
                    <div className="fs-5 fw-bold headings">Recommendation</div>
                    {showIssue.recommendation.map((rec) => {
                      return (
                        <div>
                          <div className="Issue text-justify  fs-7"> &emsp;{rec}</div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {showIssue?.possible_cause && (
                  <div className="m-2">
                    <div className="fs-5 fw-bold headings">Possible Cause</div>
                    <div>
                      <div className="Issue text-justify  fs-7">&emsp; {showIssue?.possible_cause}</div>
                    </div>
                  </div>
                )}
                {showIssue.effect && (
                  <div className="m-2">
                    <div className="fs-5 fw-bold headings">Effect</div>
                    <div>
                      <div className="Issue text-justify  fs-7"> &emsp; {showIssue.effect}</div>
                    </div>
                  </div>
                )}
                <div className="m-2">
                  <div className="headings fs-5 fw-bold">Details</div>
                  <div className="d-flex justify-content-start">
                    <div className="m-2">
                      <button id="viewSource" className="btn bttn" onClick={(event) => openAnalytics(event, showIssue)}>
                        View Source
                      </button>
                    </div>
                    {showIssue?.assetDetails?.id && (
                      <div className="m-2">
                        <button id="assetDetails" className="btn bttn" onClick={(event) => openAssetDetails(event, showIssue.assetDetails)}>
                          Asset Details
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="m-2">
                  <div className="headings fs-5 fw-bold">Actions</div>
                  <div className="advActions">
                    <div className="actionDetails">
                      <p className="fs-7">Create a new work order request</p>
                      <p className="fs-7">Create a new maintainence ticket</p>
                      <p className="fs-7">Create a new Asset management request</p>
                      <p className="fs-7">Assign a call centre executive</p>
                      <p className="fs-7">Ignore the issue</p>
                    </div>
                    <div className="actionBtns">
                      <div className="cursorPointer">
                        <img
                          src={require("../../assets/icons/Adv/workOrder.svg").default}
                          id="openSopAction"
                          onClick={(event) => openSopAction(event, showIssue)}
                          alt=""
                        />
                      </div>
                      <div className="cursorPointer">
                        <img src={require("../../assets/icons/Adv/maintenance.svg").default} alt="" />
                      </div>
                      <div className="cursorPointer">
                        <img src={require("../../assets/icons/Adv/manage.svg").default} alt="" />
                      </div>
                      <div className="cursorPointer">
                        <img src={require("../../assets/icons/Adv/call.svg").default} alt="" />
                      </div>
                      <div className="cursorPointer">
                        <img src={require("../../assets/icons/Adv/ignore.svg").default} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {assetModal && (
        <div className="modal d-block modal-md z-2 m-1" tabIndex="200" role="dialog">
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-between px-2">
                <h5 className="modal-title fs-6">Asset Information</h5>
                <div>
                  <button type="button" className="close btn bttn w-10" onClick={() => setAssetModal(false)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body p-3">
                {assetInfo && assetInfo.length > 0 ? (
                  <ul className="list-unstyled">
                    {assetInfo.map((info, index) => (
                      <li key={index} className="mb-2">
                        <strong>{info.title}:</strong> {info.value}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No asset information available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {sopSuccessful && (
        <Sop
          closeSOP={closeSOP}
          settab={() => {
            closeSOP();
            sopTab();
          }}
        ></Sop>
      )}
    </div>
  );
}

export default AdvisoryModel;

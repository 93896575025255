/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { CommonServiceContext } from "../../config/commonContext";
import { ChartServiceContext } from "../../config/chartContext";

function PieChart(props) {
  const { buildingId, data, style } = props;
  const [chartData, setChartData] = useState([]);
  const [option, setOption] = useState({});
  const [chartType, setchartType] = useState("pie");

  const commonContext = useContext(CommonServiceContext);
  const chartContext = useContext(ChartServiceContext);
  function handlechartType(event) {
    setchartType(event.target.value);
  }
  async function getBmsAlarmsData() {
    let dt = await commonContext.getBmsAlarmsDataCount(data.type, null, null, null, buildingId, data.order, data.limit ?? data.limit);
    if (dt) {
      setChartData(dt.data);
    }
  }

  async function getTrafficData() {
    let dt = await commonContext.getTrafficVolumneByCategory(data.type, data.datatype);
    if (dt) {
      setChartData(dt.data);
    }
  }

  useEffect(() => {
    if (data.apicategory === "timeseries" || data.apicategory === "frequency") {
      getBmsAlarmsData();
    }
    if (data?.key === "traffic") {
      if (data.column === "street") {
        getTrafficData();
      } else {
        setChartData(data.data);
      }
    }
  }, [buildingId, data]);

  useEffect(() => {
    let newOption;

    if (chartType === "pie") {
      newOption = {
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: chartContext.colorStops.blueShade,
          },
        ],
        title: {
          text: data?.title ?? "Frequency of Alarm Severities",
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "80%",
          itemHeight: 10,
          itemWidth: 15,
          orient: "horizontal",
          bottom: "center",
          textStyle: {
            fontSize: 8,
            color: "white",
          },
        },
        xAxis: {
          show: false,
        },
        series: [
          {
            type: chartType,
            radius: "55%",
            center: ["50%", "42%"], // Centering the pie chart vertically and horizontally
            labelLine: {
              show: false,
            },

            label: {
              position: "inside",
              formatter: "{c}",
              color: "white",
              fontSize: 10,
            },
            data: chartData?.map((item) => ({
              value: item[1],
              name: item[0],
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    } else if (chartType === "bar") {
      newOption = {
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: chartContext.colorStops.blueShade,
          },
        ],
        title: {
          text: data?.title ?? "Frequency of Alarm Severities",
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "80%",
          itemHeight: 10,
          itemWidth: 15,
          orient: "horizontal",
          bottom: "center",
          textStyle: {
            fontSize: 8,
            color: "white",
          },
        },
        xAxis: {
          type: "category",
          data: chartData?.map((item) => item[0]),
          axisLabel: {
            color: "white",
            fontSize: 8,
            interval: 0,
            rotate: 30,
          },
          // show: chartType === "bar",

          axisLine: {
            lineStyle: {
              color: "#ffffff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#ffffff",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#ffffff",
            },
          },
        },
        series: [
          {
            type: chartType,
            data: chartData.map((item) => item[1]),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    }

    setOption(newOption);
  }, [chartData, chartType]);

  const onEvents = (chart) => {
    chart.on("click", (event) => chartContext.handleChartClick(event.name, data));
  };

  return (
    <div className="chart-container mt-2">
      <select className="dropdown" value={chartType} onChange={handlechartType}>
        <option value="pie" className="opt">
          Pie
        </option>
        <option value="bar" className="opt">
          Bar
        </option>
      </select>
      <ReactECharts option={option} onChartReady={onEvents} style={style} />;
    </div>
  );
}

export default PieChart;

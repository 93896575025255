import React, { useEffect, useContext } from "react";
import { ChartServiceContext } from "../../config/chartContext";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import Variablepie from "highcharts/modules/variable-pie";

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
Variablepie(Highcharts);
function LineHighChart(props) {
  const { data, style } = props;

  const chartContext = useContext(ChartServiceContext);

  function getNestedValue(obj, path) {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  }

  useEffect(() => {
    data.data.forEach((d) => {
      if (d.value === "random") {
        let y = "y";
        let x = "x";
        d[x] = [];
        d[y] = [];
        let time = new Date().getTime();
        let i;

        for (i = -19; i <= 0; i++) {
          d[x].push(time + i * 1000);
          d[y].push(Math.random());
        }
      }
    });
    const chart = Highcharts.chart(data.id, {
      chart: {
        type: "line",
        backgroundColor: "hsla(202, 87%, 53%, 0.16)",
        marginTop: 30,
        marginRight: 30,
        borderRadius: "10px",
      },

      accessibility: {
        enabled: false,
      },

      time: {
        useUTC: false,
      },

      title: {
        text: data.title,
        style: {
          fontSize: "10px",
          color: "#fff",
        },
        floating: true,
        align: "left",
        x: 10,
        y: 10,
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        itemStyle: {
          color: "#FFFFFF",
        },
        textStyle: {
          color: "#FFFFFF",
        },
        itemWidth: 30,
        y: 15,
        x: -15,
        symbolHeight: 5,
        navigation: {
          arrowSize: 5,
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: data.xData,
        tickPixelInterval: 200,
        lineColor: "#fff",
        labels: {
          x: 15,
          y: 8,
          autoRotation: 0,
          rotation: -20,
          style: { color: "#FFFFFF", fontSize: 7 },
        },
      },
      yAxis: {
        min: data.min ? data.min : null,
        max: data.max ? data.max : null,
        gridLineWidth: 0,
        lineWidth: 1,
        lineColor: "#fff",
        title: {
          enabled: false,
        },
        labels: {
          x: -5,
          y: 10,
          style: { color: "#FFFFFF", fontSize: 10 },
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: "#808080",
          },
        ],
      },
      tooltip: {
        headerFormat: `<div style="font-size:0.6rem">Date: {point.key}</div>`,
        pointFormat: `<div style="font-size:0.6rem">{series.name}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      series: data.data.map((dataItem) => ({
        name: dataItem.name,
        data: !chartContext.ahuIncident ? dataItem.normalVal : dataItem.value,
        color: dataItem.color ? getNestedValue(chartContext, dataItem.color) : null,
        marker: {
          symbol: dataItem.marker ? dataItem.marker : null,
          size: 2,
        },
      })),
    });

    data.data.forEach((dataItem) => {
      if (dataItem.value === "random") {
        var series = chart.series.find((seriesItem) => seriesItem.name === dataItem.name);
        setInterval(() => {
          if (series.data) {
            var x = new Date().getTime(),
              y = Math.random();
            series.addPoint([x, y]);
          }
        }, 5000);
      }
    });
  }, [data.id, chartContext.ahuIncident]);

  return <div id={data.id} style={style}></div>;
}

export default LineHighChart;

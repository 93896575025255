/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import ReactECharts from "echarts-for-react";
import { HttpServiceContext } from "../../config/httpContext";
import { CommonServiceContext } from "../../config/commonContext";

const HeatmapChart = (props) => {
  const { buildingId, data } = props;

  const [chartData, setChartData] = useState([]);
  const [option, setOption] = useState({});
  const [ylabel, setYlabel] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);

  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);

  async function getBmsAlarmsDataCount() {
    let dt = await httpContext.getBmsAlarmsColumnDataCount(data.xaxis, data.yaxis, null, null, null, buildingId);
    if (dt) {
      console.log(dt);
      setChartData(dt.data);
      setYlabel(dt.yLabels);
      setXAxisData(dt.xLabels);
    }
  }

  async function gettrafficheatmap() {
    let dt = await httpContext.gettrafficheatmap(data.time, data.value);
    if (dt) {
      setChartData(dt.data);
      setYlabel(dt.yLabels);
      setXAxisData(dt.xLabels);
    }
  }
  useEffect(() => {
    if (data.apicategory === "timeseries") {
      getBmsAlarmsDataCount();
    }
    if (data?.key === "traffic") {
      gettrafficheatmap();
    }
  }, [buildingId, data]);
  useEffect(() => {
    const values = chartData?.map((item) => item[2]);
    let min = 0;
    let max = 100;
    if (values?.length) {
      min = Math.min(...values);
      max = Math.max(...values);
    }
    const newOption = {
      title: {
        text: data.title,
        textStyle: {
          color: "#ffffff",
          fontSize: 10,
        },
        textAlign: "left",
        left: "3%",
        top: "1%",
      },

      animation: false,
      grid: {
        top: "13%",
        left: "10%",
        right: "11%",
        height: "70%",
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: "category",
        data: ylabel,
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        top: "12%",
        right: "1%",
        min: min ?? 0,
        max: max ?? 1000,
        calculable: true,
        textStyle: {
          color: "#ffffff",
          fontSize: 10,
        },
        inRange: {
          color: commonContext.colorStops.lightBlueViolet.map((stop) => stop.color),
        },
      },
      series: [
        {
          name: "traffic",
          type: "heatmap",
          data: chartData,
          label: {
            show: true,
            fontSize: 7,
            color: "white",
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "#333",
            },
          },
        },
      ],
    };
    setOption(newOption);
  }, [chartData, xAxisData, ylabel]);

  const onEvents = (chart) => {
    chart.on("click", (event) => commonContext.handleChartClick(event.value, data));
  };

  return <ReactECharts option={option} onChartReady={onEvents} className="chart-container" style={{ height: "30vh", width: "100%" }} />;
};

export default HeatmapChart;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import BarChart from "../echarts/bar-chart";
import PieChart from "../echarts/pieChart";
import HeatmapChart from "../echarts/heatmap";
import Analytics from "./analytics";
import ChartDetails from "../common/chartDetails";
import LineChart from "../echarts/lineChart";
import AhuAnalytics from "./ahuAnalytics";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Autocomplete, TextField } from "@mui/material";
import { Stack } from "react-bootstrap";
import { HttpServiceContext } from "../../config/httpContext";
import { CommonServiceContext } from "../../config/commonContext";
import BmsTabs from "../common/dashboardtabs";
import Forecast from "./forecast";
import AdvisoryModel from "../common/advisoryModel";
import subsystems from "../../assets/subsystem.json";
import { useLocation } from "react-router-dom";
import Iframe from "../common/iframes";

const Home = () => {
  const [buildingId, setBuildingId] = useState();
  const [isForecast, setIsForecast] = useState(false);
  const [activeTab, setActiveTab] = useState("analytics");
  const [showAdv, setShowAdv] = useState(false);
  const [showIssue, setShowIssue] = useState("");
  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);
  const [data, setData] = useState();
  const [showWindow, setShowWindow] = useState(false);
  const [showframe, setShowframe] = useState(false);
  const [show3dView, setShow3dView] = useState(false);
  const [show3dViewButton, setShow3dViewButton] = useState(false);
  const [askAi, setAskAi] = useState(false);
  const [aiInput, setAiInput] = useState();
  const [aiIframe, setAiIframe] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [showAiPromt, setShowAiPromt] = useState(false);
  const [inputSuggestions, setInputSuggestions] = useState([
    "Can you analyze the hour column to show how alarms are distributed throughout the day? Please identify peak and non-peak hours and any notable patterns.",
    "Can you analyze the correlation between the severity of alarms and the type of issue in the issues column? Please provide insights into which issues are linked to higher severity alarms and any patterns or trends that emerge. and represent using plots",
    "Identify trends in alarm occurrences based on the DayOfWeek column and plot pie chart for this.",
    "Can you analyze alarm occurrences across different buildings using the building column and identify any trends?",
    "Can you analyze how the severity of alarms changes over time? Use the timestamp data to identify any trends in alarm severity over different periods, such as months or years.",
    "Can you analyze if certain buildings are associated with specific types of issues? Identify any buildings that have a higher frequency of certain issues compared to others.",
    "Can you plot line chart for month wise alarm",
    "Identify trends in alarm frequency based on the year.",
  ]);

  const [visioMap, setVisioMap] = useState(false);

  const visio = useRef();
  const model = useRef();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    const visioParam = params.get("visio");
    if (visioParam === "true") {
      setVisioMap(true);
    } else {
      setVisioMap(false);
    }
    async function getData() {
      let mydata = await httpContext.getData();
      if (mydata) {
        setData(mydata.data);
      }
    }
    getData();
  }, []);

  async function askAiContent() {
    setStartLoader(true);
    let mydata = await httpContext.getAiPromt(aiInput);
    setAiInput("");
    if (mydata) {
      setAiIframe(mydata.data.html_path);
      setStartLoader(false);
    } else {
    }
  }

  function updateInputValue(event) {
    setAiInput(event.target.value);
  }
  const sendMessageToIframe = () => {
    const msgdata = {
      buildingID: "BT07",
      floorID: "BT07-UL0",
      mode: "floor",
    };
    visio?.current?.contentWindow.postMessage(msgdata, "*");
  };

  const sendMessageToModelIframe = (msgdata) => {
    model?.current?.contentWindow.postMessage(msgdata, "*");
    visio?.current?.contentWindow.postMessage(msgdata, "*");
  };

  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;
      if (typeof data === "string") {
        setBuildingId(data);
      }
      if (typeof data === "object") {
        if (data.message === "Launch Digital Twin") {
          setShow3dView(true);
        }

        if (data.message === "Show AHU") {
          commonContext.setShowAssetAnalytics(true);
        }
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    commonContext.setBId(buildingId);
    let intervalId;

    if (buildingId === "T07") {
      intervalId = setInterval(() => {
        httpContext.getDeviceState().then((data) => {
          if (data["SIM-003"].state === true) {
            commonContext.setAhuIncident(true);
            sendMessageToModelIframe({ state: true });
            commonContext.ahutemperaturedata(2800, 3200);
          } else {
            commonContext.setAhuIncident(false);
            sendMessageToModelIframe({ state: false });
            commonContext.ahutemperaturedata(2200, 2800);
          }
        });
      }, 5000);
      return () => clearInterval(intervalId);
    } else {
      commonContext.setAhuIncident(false);
      sendMessageToModelIframe({ state: false });
      commonContext.ahutemperaturedata(2200, 2800);
    }
  }, [buildingId]);

  const onClickAnalytics = () => {
    commonContext.setShowAnalytics(true);
  };
  const onClickForecast = () => {
    setIsForecast(true);
  };

  const handleSop = (event, id) => {
    setShowAdv(true);
    const advEntry = data.advisory.bms.find((adv) => adv.id === id);
    if (advEntry) {
      setShowIssue(advEntry);
    }
  };
  function openWindow() {
    setShowWindow(true);
  }
  function closeWindow() {
    setShowWindow(false);
  }
  function openIframe() {
    setShowframe(true);
  }
  function closeIframe() {
    setShowframe(false);
  }
  const limitedData = data && data.advisory && Array.isArray(data.advisory.bms) ? data.advisory.bms.slice(0, 6) : [];

  const chartStyle = {
    height: "27vh",
    width: "100%",
  };

  function sopTab() {
    setActiveTab("Incidents");
    setShowAdv(false);
  }

  return (
    <div className="h-100vh p-1">
      <div className={`background-data ${commonContext.openModel ? "disabled" : ""}`}>
        {!commonContext.showAnalytics && !showWindow && !showframe && (
          <div>
            <div className="row ">
              <div className="col-7">
                <th className="mini col-6">
                  <img src="/maximize.png" className="maximize" alt="" onClick={openIframe}></img>
                </th>
                <br />
                {!show3dView && (
                  <iframe
                    ref={visio}
                    className="iframe"
                    src={`${visioMap ? commonContext.visioWebURL : commonContext.gmapURL}`}
                    title="visioWeb"
                  ></iframe>
                )}
                {show3dView && <iframe ref={model} className="iframe" src={commonContext.modelURL} title="3d Model"></iframe>}
                <div className="mytabs">
                  <div className="tabOptions">
                    <div>
                      <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                        Incidents
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                        Analytics
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                        Advisory
                      </button>
                    </div>

                    <div>
                      <th className=" mini col-6">
                        <img src="/maximize.png" alt="" className="maximize" onClick={openWindow}></img>
                      </th>
                    </div>
                  </div>
                  <div className="tabContent">
                    {activeTab === "analytics" && (
                      <div className="row">
                        {subsystems.bms.charts.leftpanel.map((chData) =>
                          chData.chartType === "pie" ? (
                            <div className="col-4">
                              <PieChart buildingId={buildingId} data={chData} style={chartStyle} />
                            </div>
                          ) : (
                            <div className="col-4">
                              <BarChart buildingId={buildingId} data={chData} style={chartStyle} />
                            </div>
                          )
                        )}
                      </div>
                    )}
                    {activeTab === "advisory" && (
                      <div className="p-1">
                        {buildingId === "T07" ? (
                          <table className="d-block text-light table px-0" style={{ height: "29vh" }}>
                            <tr className="d-flex justify-content-between  align-items-baseline">
                              <th className="col-11 fs-6 px-4">Observation</th>
                              <th className="col-auto px-0 fs-6">Action</th>
                            </tr>
                            {limitedData.map((adv, i) => {
                              if (i === 0 && !commonContext.ahuIncident) {
                                return null;
                              }
                              return (
                                <tr key={adv.id} className="d-flex justify-content-between px-4">
                                  <td className="col-10 text-justify py-1 border-0 text fs-7">
                                    {adv.observation}
                                    <span
                                      className="span-color"
                                      onClick={() => {
                                        setShow3dViewButton(true);
                                        sendMessageToIframe();
                                      }}
                                    >
                                      {adv?.assetId}
                                    </span>
                                  </td>
                                  <td>
                                    <button
                                      key={adv.id}
                                      className="col-auto myBtns text-white cursor-pointer"
                                      onClick={(event) => handleSop(event, adv.id)}
                                    >
                                      ACT
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        ) : (
                          <table className="d-block text-light table px-0" style={{ height: "29vh" }}>
                            <tr className="d-flex justify-content-between  align-items-baseline">
                              <th className="col-11 fs-6 px-4">Observation</th>
                              <th className="col-auto px-3 fs-6">Action</th>
                            </tr>
                            {data &&
                              data.advisory &&
                              data.advisory.bms.map((adv, i) => {
                                if (i === 0 && !commonContext.ahuIncident) {
                                  return null;
                                }
                                return (
                                  <tr key={adv.id} className="d-flex justify-content-between px-4">
                                    <td className="col-10 text-justify py-1 border-0 text fs-7">
                                      {adv.observation}
                                      <span
                                        className="span-color"
                                        onClick={() => {
                                          setShow3dViewButton(true);
                                          sendMessageToIframe();
                                        }}
                                      >
                                        {adv?.assetId}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        key={adv.id}
                                        className=" col-auto myBtns text-white cursor-pointer"
                                        onClick={(event) => handleSop(event, adv.id)}
                                      >
                                        ACT
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        )}
                      </div>
                    )}
                    {activeTab === "Incidents" && <iframe className="sop-iframe" src={httpContext.sop} title="Sop"></iframe>}
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="d-flex justify-content-between fc-white m-1">
                  <div>{buildingId ? `Building - ${buildingId}` : "Overview"}</div>
                  <div className="d-flex">
                    {(show3dViewButton || buildingId === "T07") && (
                      <div className="mx-1">
                        <button
                          className="btn bttn ml-auto"
                          onClick={() => {
                            setShow3dView(!show3dView);
                          }}
                        >
                          {!show3dView ? "3D Building View" : "GIS Map"}
                        </button>
                      </div>
                    )}
                    {buildingId === "T07" && (
                      <div className="mx-1">
                        <button className="btn bttn ml-auto" onClick={onClickForecast}>
                          Alarm Forecast
                        </button>
                      </div>
                    )}
                    <div className="mx-1">
                      <button className="btn bttn ml-auto" onClick={onClickAnalytics}>
                        Analytics
                      </button>
                    </div>
                  </div>
                </div>
                {subsystems.bms.charts.rightpanel.map((chData) =>
                  chData.chartType === "line" ? (
                    <div className="p-1">
                      <LineChart buildingId={buildingId} data={chData} style={chartStyle} />
                    </div>
                  ) : chData.chartType === "heatmap" ? (
                    <div className="p-1">
                      <HeatmapChart buildingId={buildingId} data={chData} style={chartStyle} />
                    </div>
                  ) : (
                    <div className="p-1">
                      <BarChart buildingId={buildingId} data={chData} style={chartStyle} />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}
        {isForecast && <Forecast setforecast={() => setIsForecast(false)}></Forecast>}
        {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
        {showframe && <Iframe closeIframe={closeIframe} visioMaps={visioMap} label={"bms"} />}
        {showWindow && <BmsTabs closeWindow={closeWindow} active={activeTab} label={"BmsTabs"} />}
        {commonContext.showAnalytics && <Analytics></Analytics>}
      </div>
      {commonContext.openModel && <ChartDetails></ChartDetails>}
      {commonContext.showAssetAnalytics && (
        <div className="ahu-analytics">
          <AhuAnalytics></AhuAnalytics>
        </div>
      )}
      {showAiPromt && (
        <div>
          <div className="chatbot" onClick={() => setAskAi(!askAi)}>
            <img src="/chatbot-button.png" alt="" />
          </div>
          {askAi && (
            <div className="chatbot-body">
              <div className="chat">
                <div className="text-center fw-bold m-2">Chat with Ai</div>
                <div className="chat-window">
                  <div className="generated-data">
                    {!startLoader && <iframe className="aiIframe" src={aiIframe} frameborder="0" title="Ai Promt"></iframe>}
                    {startLoader && <PropagateLoader className="loader" size={15} color="white" />}
                  </div>
                  <div className="asked-question"></div>
                  <div className="text-center d-flex justify-content-around p-2">
                    <Stack spacing={2} sx={{ width: 20 }}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        value={aiInput}
                        options={inputSuggestions}
                        onInputChange={(event, newInputValue) => updateInputValue({ target: { value: newInputValue } })}
                        onKeyDown={(evt) => {
                          if (evt.key === "Enter") {
                            askAiContent();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Ask Ai"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              endAdornment: null,
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <img className="m-2" onClick={() => askAiContent()} src="/send-white.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;

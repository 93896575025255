/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import Variablepie from "highcharts/modules/variable-pie";
import HighchartsWordcloud from "highcharts/modules/wordcloud";
import { CommonServiceContext } from "../../config/commonContext";
import { ChartServiceContext } from "../../config/chartContext";
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
Variablepie(Highcharts);
HighchartsWordcloud(Highcharts);

function Wordcloud(props) {
  const { data, filter } = props;
  const [chartData, setChartData] = useState();

  const commonContext = useContext(CommonServiceContext);
  const chartContext = useContext(ChartServiceContext);

  async function getData() {
    let dt = await commonContext.getTimeseriesColumnData(data.type, filter[0], filter[1], filter[2]);
    if (dt) {
      setChartData(dt.data);
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (data.apicategory === "timeseries") {
        getData();
      } else if (data.apicategory === "frequency") {
        getData();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [filter[0], filter[1], filter[2]]);

  useEffect(() => {
    if (chartData) {
      const combinedText = chartData.join(" ");
      const cleanedText = combinedText.replace(/[():'?]+/g, "");
      const words = cleanedText.split(/[,\. ]+/g);
      const occurrenceMap = words.reduce((acc, word) => {
        if (word) {
          acc[word] = (acc[word] || 0) + 1;
        }
        return acc;
      }, {});
      const worddata = Object.keys(occurrenceMap).map((key) => ({
        name: key,
        weight: occurrenceMap[key],
      }));

      const chart = Highcharts.chart(data.id, {
        chart: {
          marginTop: 50,
          marginRight: 35,
          backgroundColor: "hsla(202, 87%, 53%, 0.16)",
          // backgroundColor: "hsla(237, 61%, 60%, 0.16)",
        },
        series: [
          {
            type: "wordcloud",
            data: worddata,
            name: "Occurrences",
            maxFontSize: 50,
            minFontSize: 5,
            point: {
              events: {
                click: function (params) {
                  chartContext.handleChartClick(params.point.name, data, filter);
                },
              },
            },
          },
        ],
        title: {
          text: data.title,
          style: {
            fontSize: "12px",
            color: "#fff",
          },
          floating: true,
          align: "left",
          x: 50,
          y: 10,
        },
        tooltip: {
          headerFormat: '<span style="font-size: 16px"><b>{point.key}</b></span><br>',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        accessibility: {
          enabled: false,
        },
      });
    }
  }, [data.id, chartData]);

  return <div id={data.id} style={{ width: "46vw", height: "60vh" }}></div>;
}

export default Wordcloud;

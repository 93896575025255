import React from "react";
import LineChartStatic from "../echarts/lineChartStatic";
import subsystems from "../../assets/subsystem.json";

function Delta() {
  const airEnteringTemp = {
    title: "Air Entering Temparature",
    column: "Temperature",
    key: "delta",
    min: 68,
    max: 80,
    data: [
      73.7, 79.5, 77.3, 76.0, 71.6, 71.6, 70.6, 78.7, 76.0, 77.1, 70.2, 79.7, 78.3, 72.1, 71.8, 71.8, 73.0, 75.2, 74.3, 72.9, 76.1, 71.4, 72.9, 73.7,
      74.6, 77.9, 72.0, 75.1, 75.9, 70.5, 76.1, 71.7, 70.7, 79.5, 79.7, 78.1, 73.0, 71.0, 76.8, 74.4, 71.2, 75.0, 70.3, 79.1, 72.6, 76.6, 73.1, 75.2,
      75.5, 71.8, 79.7, 77.8, 79.4, 78.9, 76.0, 79.2, 70.9, 72.0, 70.5, 73.3, 73.9, 72.7, 78.3, 73.6, 72.8, 75.4, 71.4, 78.0, 70.7, 79.9, 77.7, 72.0,
      70.1, 78.2, 77.1, 77.3, 77.7, 70.7, 73.6, 71.2, 78.6, 76.2, 73.3, 70.6, 73.1, 73.3, 77.3, 76.4, 78.9, 74.7, 71.2, 77.1, 77.6, 75.6, 77.7, 74.9,
      75.2, 74.3, 70.3, 71.1, 70.3, 76.4, 73.1, 75.1, 79.1, 72.5, 74.1, 77.6, 72.3, 70.8, 72.9, 71.6, 79.3, 78.1, 76.3, 78.7, 78.0, 71.9, 78.9, 75.4,
      78.1, 79.0, 73.2, 71.1, 72.3, 74.3, 78.2, 78.6, 70.1, 75.1, 74.2, 72.2, 71.2, 73.4, 79.4, 73.2, 75.2, 77.0, 73.6, 79.7, 79.6, 72.5, 75.0, 73.0,
      72.8, 70.4, 76.1, 75.0, 70.5, 72.8, 79.1, 72.4, 71.4, 74.9, 79.9, 72.4, 76.7, 77.6, 72.4, 77.3, 73.7, 76.3, 76.3, 75.4, 70.9, 78.4, 73.2, 71.9,
    ],
    xData: [
      "2024-08-19 00:00:00",
      "2024-08-19 01:00:00",
      "2024-08-19 02:00:00",
      "2024-08-19 03:00:00",
      "2024-08-19 04:00:00",
      "2024-08-19 05:00:00",
      "2024-08-19 06:00:00",
      "2024-08-19 07:00:00",
      "2024-08-19 08:00:00",
      "2024-08-19 09:00:00",
      "2024-08-19 10:00:00",
      "2024-08-19 11:00:00",
      "2024-08-19 12:00:00",
      "2024-08-19 13:00:00",
      "2024-08-19 14:00:00",
      "2024-08-19 15:00:00",
      "2024-08-19 16:00:00",
      "2024-08-19 17:00:00",
      "2024-08-19 18:00:00",
      "2024-08-19 19:00:00",
      "2024-08-19 20:00:00",
      "2024-08-19 21:00:00",
      "2024-08-19 22:00:00",
      "2024-08-19 23:00:00",
      "2024-08-20 00:00:00",
      "2024-08-20 01:00:00",
      "2024-08-20 02:00:00",
      "2024-08-20 03:00:00",
      "2024-08-20 04:00:00",
      "2024-08-20 05:00:00",
      "2024-08-20 06:00:00",
      "2024-08-20 07:00:00",
      "2024-08-20 08:00:00",
      "2024-08-20 09:00:00",
      "2024-08-20 10:00:00",
      "2024-08-20 11:00:00",
      "2024-08-20 12:00:00",
      "2024-08-20 13:00:00",
      "2024-08-20 14:00:00",
      "2024-08-20 15:00:00",
      "2024-08-20 16:00:00",
      "2024-08-20 17:00:00",
      "2024-08-20 18:00:00",
      "2024-08-20 19:00:00",
      "2024-08-20 20:00:00",
      "2024-08-20 21:00:00",
      "2024-08-20 22:00:00",
      "2024-08-20 23:00:00",
      "2024-08-21 00:00:00",
      "2024-08-21 01:00:00",
      "2024-08-21 02:00:00",
      "2024-08-21 03:00:00",
      "2024-08-21 04:00:00",
      "2024-08-21 05:00:00",
      "2024-08-21 06:00:00",
      "2024-08-21 07:00:00",
      "2024-08-21 08:00:00",
      "2024-08-21 09:00:00",
      "2024-08-21 10:00:00",
      "2024-08-21 11:00:00",
      "2024-08-21 12:00:00",
      "2024-08-21 13:00:00",
      "2024-08-21 14:00:00",
      "2024-08-21 15:00:00",
      "2024-08-21 16:00:00",
      "2024-08-21 17:00:00",
      "2024-08-21 18:00:00",
      "2024-08-21 19:00:00",
      "2024-08-21 20:00:00",
      "2024-08-21 21:00:00",
      "2024-08-21 22:00:00",
      "2024-08-21 23:00:00",
      "2024-08-22 00:00:00",
      "2024-08-22 01:00:00",
      "2024-08-22 02:00:00",
      "2024-08-22 03:00:00",
      "2024-08-22 04:00:00",
      "2024-08-22 05:00:00",
      "2024-08-22 06:00:00",
      "2024-08-22 07:00:00",
      "2024-08-22 08:00:00",
      "2024-08-22 09:00:00",
      "2024-08-22 10:00:00",
      "2024-08-22 11:00:00",
      "2024-08-22 12:00:00",
      "2024-08-22 13:00:00",
      "2024-08-22 14:00:00",
      "2024-08-22 15:00:00",
      "2024-08-22 16:00:00",
      "2024-08-22 17:00:00",
      "2024-08-22 18:00:00",
      "2024-08-22 19:00:00",
      "2024-08-22 20:00:00",
      "2024-08-22 21:00:00",
      "2024-08-22 22:00:00",
      "2024-08-22 23:00:00",
      "2024-08-23 00:00:00",
      "2024-08-23 01:00:00",
      "2024-08-23 02:00:00",
      "2024-08-23 03:00:00",
      "2024-08-23 04:00:00",
      "2024-08-23 05:00:00",
      "2024-08-23 06:00:00",
      "2024-08-23 07:00:00",
      "2024-08-23 08:00:00",
      "2024-08-23 09:00:00",
      "2024-08-23 10:00:00",
      "2024-08-23 11:00:00",
      "2024-08-23 12:00:00",
      "2024-08-23 13:00:00",
      "2024-08-23 14:00:00",
      "2024-08-23 15:00:00",
      "2024-08-23 16:00:00",
      "2024-08-23 17:00:00",
      "2024-08-23 18:00:00",
      "2024-08-23 19:00:00",
      "2024-08-23 20:00:00",
      "2024-08-23 21:00:00",
      "2024-08-23 22:00:00",
      "2024-08-23 23:00:00",
      "2024-08-24 00:00:00",
      "2024-08-24 01:00:00",
      "2024-08-24 02:00:00",
      "2024-08-24 03:00:00",
      "2024-08-24 04:00:00",
      "2024-08-24 05:00:00",
      "2024-08-24 06:00:00",
      "2024-08-24 07:00:00",
      "2024-08-24 08:00:00",
      "2024-08-24 09:00:00",
      "2024-08-24 10:00:00",
      "2024-08-24 11:00:00",
      "2024-08-24 12:00:00",
      "2024-08-24 13:00:00",
      "2024-08-24 14:00:00",
      "2024-08-24 15:00:00",
      "2024-08-24 16:00:00",
      "2024-08-24 17:00:00",
      "2024-08-24 18:00:00",
      "2024-08-24 19:00:00",
      "2024-08-24 20:00:00",
      "2024-08-24 21:00:00",
      "2024-08-24 22:00:00",
      "2024-08-24 23:00:00",
      "2024-08-25 00:00:00",
      "2024-08-25 01:00:00",
      "2024-08-25 02:00:00",
      "2024-08-25 03:00:00",
      "2024-08-25 04:00:00",
      "2024-08-25 05:00:00",
      "2024-08-25 06:00:00",
      "2024-08-25 07:00:00",
      "2024-08-25 08:00:00",
      "2024-08-25 09:00:00",
      "2024-08-25 10:00:00",
      "2024-08-25 11:00:00",
      "2024-08-25 12:00:00",
      "2024-08-25 13:00:00",
      "2024-08-25 14:00:00",
      "2024-08-25 15:00:00",
      "2024-08-25 16:00:00",
      "2024-08-25 17:00:00",
      "2024-08-25 18:00:00",
      "2024-08-25 19:00:00",
      "2024-08-25 20:00:00",
      "2024-08-25 21:00:00",
      "2024-08-25 22:00:00",
      "2024-08-25 23:00:00",
    ],
  };

  const airleavingData = {
    title: "Air Leaving Temparature",
    column: "Temperature",
    key: "delta",
    min: 47,
    max: 66,
    data: [
      57.5, 60.0, 57.2, 59.9, 52.5, 54.2, 50.7, 61.7, 55.9, 58.8, 50.0, 62.9, 60.3, 55.4, 50.3, 50.5, 55.5, 55.2, 53.4, 53.6, 56.9, 53.9, 56.3, 52.3,
      53.2, 58.1, 54.0, 57.0, 55.5, 50.0, 54.8, 51.0, 50.8, 63.0, 62.7, 56.7, 53.4, 54.9, 60.2, 54.4, 55.2, 58.0, 51.0, 58.9, 52.7, 59.3, 52.8, 57.8,
      57.5, 51.3, 59.8, 56.7, 59.5, 59.5, 59.4, 61.0, 53.3, 54.5, 50.0, 54.9, 52.5, 52.9, 57.5, 54.6, 53.3, 56.4, 54.2, 57.7, 53.0, 63.8, 57.8, 54.9,
      50.0, 63.8, 63.7, 62.4, 50.0, 50.0, 50.0, 50.0, 50.0, 51.0, 58.8, 56.1, 50.0, 60.3, 64.8, 64.1, 66.7, 61.6, 50.0, 63.5, 62.3, 50.7, 50.0, 62.6,
      61.4, 50.0, 50.0, 56.0, 50.0, 63.8, 50.0, 50.0, 50.0, 50.0, 50.0, 65.1, 50.0, 56.2, 50.0, 57.5, 65.9, 62.8, 50.0, 50.0, 50.0, 56.9, 65.6, 63.3,
      55.7, 50.0, 58.3, 50.0, 50.0, 51.4, 64.0, 64.4, 50.0, 59.2, 61.5, 50.0, 58.1, 50.0, 53.6, 61.1, 63.0, 62.9, 59.4, 52.6, 65.2, 50.0, 59.1, 50.0,
      50.0, 50.0, 64.0, 50.0, 57.4, 50.0, 66.2, 50.0, 57.7, 62.8, 50.0, 60.2, 50.0, 54.2, 50.0, 50.0, 57.9, 50.0, 61.3, 62.4, 50.0, 63.6, 58.0, 57.8,
    ],
    xData: [
      "2024-08-19 00:00:00",
      "2024-08-19 01:00:00",
      "2024-08-19 02:00:00",
      "2024-08-19 03:00:00",
      "2024-08-19 04:00:00",
      "2024-08-19 05:00:00",
      "2024-08-19 06:00:00",
      "2024-08-19 07:00:00",
      "2024-08-19 08:00:00",
      "2024-08-19 09:00:00",
      "2024-08-19 10:00:00",
      "2024-08-19 11:00:00",
      "2024-08-19 12:00:00",
      "2024-08-19 13:00:00",
      "2024-08-19 14:00:00",
      "2024-08-19 15:00:00",
      "2024-08-19 16:00:00",
      "2024-08-19 17:00:00",
      "2024-08-19 18:00:00",
      "2024-08-19 19:00:00",
      "2024-08-19 20:00:00",
      "2024-08-19 21:00:00",
      "2024-08-19 22:00:00",
      "2024-08-19 23:00:00",
      "2024-08-20 00:00:00",
      "2024-08-20 01:00:00",
      "2024-08-20 02:00:00",
      "2024-08-20 03:00:00",
      "2024-08-20 04:00:00",
      "2024-08-20 05:00:00",
      "2024-08-20 06:00:00",
      "2024-08-20 07:00:00",
      "2024-08-20 08:00:00",
      "2024-08-20 09:00:00",
      "2024-08-20 10:00:00",
      "2024-08-20 11:00:00",
      "2024-08-20 12:00:00",
      "2024-08-20 13:00:00",
      "2024-08-20 14:00:00",
      "2024-08-20 15:00:00",
      "2024-08-20 16:00:00",
      "2024-08-20 17:00:00",
      "2024-08-20 18:00:00",
      "2024-08-20 19:00:00",
      "2024-08-20 20:00:00",
      "2024-08-20 21:00:00",
      "2024-08-20 22:00:00",
      "2024-08-20 23:00:00",
      "2024-08-21 00:00:00",
      "2024-08-21 01:00:00",
      "2024-08-21 02:00:00",
      "2024-08-21 03:00:00",
      "2024-08-21 04:00:00",
      "2024-08-21 05:00:00",
      "2024-08-21 06:00:00",
      "2024-08-21 07:00:00",
      "2024-08-21 08:00:00",
      "2024-08-21 09:00:00",
      "2024-08-21 10:00:00",
      "2024-08-21 11:00:00",
      "2024-08-21 12:00:00",
      "2024-08-21 13:00:00",
      "2024-08-21 14:00:00",
      "2024-08-21 15:00:00",
      "2024-08-21 16:00:00",
      "2024-08-21 17:00:00",
      "2024-08-21 18:00:00",
      "2024-08-21 19:00:00",
      "2024-08-21 20:00:00",
      "2024-08-21 21:00:00",
      "2024-08-21 22:00:00",
      "2024-08-21 23:00:00",
      "2024-08-22 00:00:00",
      "2024-08-22 01:00:00",
      "2024-08-22 02:00:00",
      "2024-08-22 03:00:00",
      "2024-08-22 04:00:00",
      "2024-08-22 05:00:00",
      "2024-08-22 06:00:00",
      "2024-08-22 07:00:00",
      "2024-08-22 08:00:00",
      "2024-08-22 09:00:00",
      "2024-08-22 10:00:00",
      "2024-08-22 11:00:00",
      "2024-08-22 12:00:00",
      "2024-08-22 13:00:00",
      "2024-08-22 14:00:00",
      "2024-08-22 15:00:00",
      "2024-08-22 16:00:00",
      "2024-08-22 17:00:00",
      "2024-08-22 18:00:00",
      "2024-08-22 19:00:00",
      "2024-08-22 20:00:00",
      "2024-08-22 21:00:00",
      "2024-08-22 22:00:00",
      "2024-08-22 23:00:00",
      "2024-08-23 00:00:00",
      "2024-08-23 01:00:00",
      "2024-08-23 02:00:00",
      "2024-08-23 03:00:00",
      "2024-08-23 04:00:00",
      "2024-08-23 05:00:00",
      "2024-08-23 06:00:00",
      "2024-08-23 07:00:00",
      "2024-08-23 08:00:00",
      "2024-08-23 09:00:00",
      "2024-08-23 10:00:00",
      "2024-08-23 11:00:00",
      "2024-08-23 12:00:00",
      "2024-08-23 13:00:00",
      "2024-08-23 14:00:00",
      "2024-08-23 15:00:00",
      "2024-08-23 16:00:00",
      "2024-08-23 17:00:00",
      "2024-08-23 18:00:00",
      "2024-08-23 19:00:00",
      "2024-08-23 20:00:00",
      "2024-08-23 21:00:00",
      "2024-08-23 22:00:00",
      "2024-08-23 23:00:00",
      "2024-08-24 00:00:00",
      "2024-08-24 01:00:00",
      "2024-08-24 02:00:00",
      "2024-08-24 03:00:00",
      "2024-08-24 04:00:00",
      "2024-08-24 05:00:00",
      "2024-08-24 06:00:00",
      "2024-08-24 07:00:00",
      "2024-08-24 08:00:00",
      "2024-08-24 09:00:00",
      "2024-08-24 10:00:00",
      "2024-08-24 11:00:00",
      "2024-08-24 12:00:00",
      "2024-08-24 13:00:00",
      "2024-08-24 14:00:00",
      "2024-08-24 15:00:00",
      "2024-08-24 16:00:00",
      "2024-08-24 17:00:00",
      "2024-08-24 18:00:00",
      "2024-08-24 19:00:00",
      "2024-08-24 20:00:00",
      "2024-08-24 21:00:00",
      "2024-08-24 22:00:00",
      "2024-08-24 23:00:00",
      "2024-08-25 00:00:00",
      "2024-08-25 01:00:00",
      "2024-08-25 02:00:00",
      "2024-08-25 03:00:00",
      "2024-08-25 04:00:00",
      "2024-08-25 05:00:00",
      "2024-08-25 06:00:00",
      "2024-08-25 07:00:00",
      "2024-08-25 08:00:00",
      "2024-08-25 09:00:00",
      "2024-08-25 10:00:00",
      "2024-08-25 11:00:00",
      "2024-08-25 12:00:00",
      "2024-08-25 13:00:00",
      "2024-08-25 14:00:00",
      "2024-08-25 15:00:00",
      "2024-08-25 16:00:00",
      "2024-08-25 17:00:00",
      "2024-08-25 18:00:00",
      "2024-08-25 19:00:00",
      "2024-08-25 20:00:00",
      "2024-08-25 21:00:00",
      "2024-08-25 22:00:00",
      "2024-08-25 23:00:00",
    ],
  };

  const deltaT = {
    title: "Delta T temparature",
    column: "Temperature",
    key: "deltaT",
    min: 5,
    max: 31.9,
    High: 22,
    Low: 16,
    xData: [
      "2024-08-19",
      " 00:00:00",
      "2024-08-19",
      " 01:00:00",
      "2024-08-19 02:00:00",
      "2024-08-19 03:00:00",
      "2024-08-19 04:00:00",
      "2024-08-19 05:00:00",
      "2024-08-19 06:00:00",
      "2024-08-19 07:00:00",
      "2024-08-19 08:00:00",
      "2024-08-19 09:00:00",
      "2024-08-19 10:00:00",
      "2024-08-19 11:00:00",
      "2024-08-19 12:00:00",
      "2024-08-19 13:00:00",
      "2024-08-19 14:00:00",
      "2024-08-19 15:00:00",
      "2024-08-19 16:00:00",
      "2024-08-19 17:00:00",
      "2024-08-19 18:00:00",
      "2024-08-19 19:00:00",
      "2024-08-19 20:00:00",
      "2024-08-19 21:00:00",
      "2024-08-19 22:00:00",
      "2024-08-19 23:00:00",
      "2024-08-20 00:00:00",
      "2024-08-20 01:00:00",
      "2024-08-20 02:00:00",
      "2024-08-20 03:00:00",
      "2024-08-20 04:00:00",
      "2024-08-20 05:00:00",
      "2024-08-20 06:00:00",
      "2024-08-20 07:00:00",
      "2024-08-20 08:00:00",
      "2024-08-20 09:00:00",
      "2024-08-20 10:00:00",
      "2024-08-20 11:00:00",
      "2024-08-20 12:00:00",
      "2024-08-20 13:00:00",
      "2024-08-20 14:00:00",
      "2024-08-20 15:00:00",
      "2024-08-20 16:00:00",
      "2024-08-20 17:00:00",
      "2024-08-20 18:00:00",
      "2024-08-20 19:00:00",
      "2024-08-20 20:00:00",
      "2024-08-20 21:00:00",
      "2024-08-20 22:00:00",
      "2024-08-20 23:00:00",
      "2024-08-21 00:00:00",
      "2024-08-21 01:00:00",
      "2024-08-21 02:00:00",
      "2024-08-21 03:00:00",
      "2024-08-21 04:00:00",
      "2024-08-21 05:00:00",
      "2024-08-21 06:00:00",
      "2024-08-21 07:00:00",
      "2024-08-21 08:00:00",
      "2024-08-21 09:00:00",
      "2024-08-21 10:00:00",
      "2024-08-21 11:00:00",
      "2024-08-21 12:00:00",
      "2024-08-21 13:00:00",
      "2024-08-21 14:00:00",
      "2024-08-21 15:00:00",
      "2024-08-21 16:00:00",
      "2024-08-21 17:00:00",
      "2024-08-21 18:00:00",
      "2024-08-21 19:00:00",
      "2024-08-21 20:00:00",
      "2024-08-21 21:00:00",
      "2024-08-21 22:00:00",
      "2024-08-21 23:00:00",
      "2024-08-22 00:00:00",
      "2024-08-22 01:00:00",
      "2024-08-22 02:00:00",
      "2024-08-22 03:00:00",
      "2024-08-22 04:00:00",
      "2024-08-22 05:00:00",
      "2024-08-22 06:00:00",
      "2024-08-22 07:00:00",
      "2024-08-22 08:00:00",
      "2024-08-22 09:00:00",
      "2024-08-22 10:00:00",
      "2024-08-22 11:00:00",
      "2024-08-22 12:00:00",
      "2024-08-22 13:00:00",
      "2024-08-22 14:00:00",
      "2024-08-22 15:00:00",
      "2024-08-22 16:00:00",
      "2024-08-22 17:00:00",
      "2024-08-22 18:00:00",
      "2024-08-22 19:00:00",
      "2024-08-22 20:00:00",
      "2024-08-22 21:00:00",
      "2024-08-22 22:00:00",
      "2024-08-22 23:00:00",
      "2024-08-23 00:00:00",
      "2024-08-23 01:00:00",
      "2024-08-23 02:00:00",
      "2024-08-23 03:00:00",
      "2024-08-23 04:00:00",
      "2024-08-23 05:00:00",
      "2024-08-23 06:00:00",
      "2024-08-23 07:00:00",
      "2024-08-23 08:00:00",
      "2024-08-23 09:00:00",
      "2024-08-23 10:00:00",
      "2024-08-23 11:00:00",
      "2024-08-23 12:00:00",
      "2024-08-23 13:00:00",
      "2024-08-23 14:00:00",
      "2024-08-23 15:00:00",
      "2024-08-23 16:00:00",
      "2024-08-23 17:00:00",
      "2024-08-23 18:00:00",
      "2024-08-23 19:00:00",
      "2024-08-23 20:00:00",
      "2024-08-23 21:00:00",
      "2024-08-23 22:00:00",
      "2024-08-23 23:00:00",
      "2024-08-24 00:00:00",
      "2024-08-24 01:00:00",
      "2024-08-24 02:00:00",
      "2024-08-24 03:00:00",
      "2024-08-24 04:00:00",
      "2024-08-24 05:00:00",
      "2024-08-24 06:00:00",
      "2024-08-24 07:00:00",
      "2024-08-24 08:00:00",
      "2024-08-24 09:00:00",
      "2024-08-24 10:00:00",
      "2024-08-24 11:00:00",
      "2024-08-24 12:00:00",
      "2024-08-24 13:00:00",
      "2024-08-24 14:00:00",
      "2024-08-24 15:00:00",
      "2024-08-24 16:00:00",
      "2024-08-24 17:00:00",
      "2024-08-24 18:00:00",
      "2024-08-24 19:00:00",
      "2024-08-24 20:00:00",
      "2024-08-24 21:00:00",
      "2024-08-24 22:00:00",
      "2024-08-24 23:00:00",
      "2024-08-25 00:00:00",
      "2024-08-25 01:00:00",
      "2024-08-25 02:00:00",
      "2024-08-25 03:00:00",
      "2024-08-25 04:00:00",
      "2024-08-25 05:00:00",
      "2024-08-25 06:00:00",
      "2024-08-25 07:00:00",
      "2024-08-25 08:00:00",
      "2024-08-25 09:00:00",
      "2024-08-25 10:00:00",
      "2024-08-25 11:00:00",
      "2024-08-25 12:00:00",
      "2024-08-25 13:00:00",
      "2024-08-25 14:00:00",
      "2024-08-25 15:00:00",
      "2024-08-25 16:00:00",
      "2024-08-25 17:00:00",
      "2024-08-25 18:00:00",
      "2024-08-25 19:00:00",
      "2024-08-25 20:00:00",
      "2024-08-25 21:00:00",
      "2024-08-25 22:00:00",
      "2024-08-25 23:00:00",
    ],
    data: [
      16.2, 19.5, 20.1, 16.1, 19.1, 17.4, 19.9, 17.0, 20.1, 18.3, 21.6, 16.8, 18.0, 16.7, 21.5, 21.3, 17.5, 20.0, 20.9, 19.3, 19.2, 17.5, 16.6, 21.4,
      21.4, 19.8, 18.0, 18.1, 20.4, 21.4, 21.3, 20.7, 19.9, 16.5, 17.0, 21.4, 19.6, 16.1, 16.6, 20.0, 16.0, 17.0, 19.3, 20.2, 19.9, 17.3, 20.3, 17.4,
      18.0, 20.5, 19.9, 21.1, 19.9, 19.4, 16.6, 18.2, 17.6, 17.5, 21.8, 18.4, 21.4, 19.8, 20.8, 19.0, 19.5, 19.0, 17.2, 20.3, 17.7, 16.1, 19.9, 17.1,
      23.0, 14.4, 13.4, 14.9, 31.3, 23.7, 31.9, 26.7, 29.0, 25.2, 14.5, 14.5, 29.0, 13.0, 12.5, 12.3, 12.2, 13.1, 29.0, 13.6, 15.3, 24.9, 27.8, 12.3,
      13.8, 31.6, 22.2, 15.1, 27.0, 12.6, 28.5, 28.2, 30.8, 25.4, 24.9, 12.5, 26.3, 14.6, 30.9, 14.1, 13.4, 15.3, 31.7, 29.4, 31.4, 15.0, 13.3, 12.1,
      22.4, 30.1, 14.9, 30.5, 25.6, 22.9, 14.2, 14.2, 30.0, 15.9, 12.7, 31.1, 13.1, 30.0, 25.8, 12.1, 12.2, 14.1, 14.2, 27.1, 14.4, 25.9, 15.9, 30.1,
      31.5, 27.2, 12.1, 25.7, 13.1, 31.4, 12.9, 27.7, 13.7, 12.1, 31.1, 12.2, 30.5, 23.4, 30.7, 29.0, 15.8, 27.4, 15.0, 13.0, 27.6, 14.8, 15.2, 14.1,
    ],
  };
  return (
    <div className="chartContainer">
      {subsystems.bms.delta.map((dt) => {
        return (
          <div className="chart3">
            <LineChartStatic data={dt}></LineChartStatic>
          </div>
        );
      })}
    </div>
  );
}

export default Delta;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import "datatables.net-responsive-dt";
import $ from "jquery";
import PropagateLoader from "react-spinners/PropagateLoader";
import Assetchart from "../echarts/assetchart";
import Accordion from "react-bootstrap/Accordion";
import { CommonServiceContext } from "../../config/commonContext";
import { ChartServiceContext } from "../../config/chartContext";

function ChartDetails() {
  const commonContext = useContext(CommonServiceContext);
  const chartContext = useContext(ChartServiceContext);
  const [search, setSearch] = useState("");
  const [modalContent, setModalContent] = useState();
  const [tableData, setTableData] = useState([]);
  const [showAiAdvisory, setShowAiAdvisory] = useState(false);
  const [stoploading, setStoploading] = useState(false);
  const tableRef = useRef(null);
  const [advisory, setadvisory] = useState();
  const [showChart, setShowChart] = useState(false);
  const [uniqueValues, setUniqueValues] = useState({
    "Asset Id": [],
    Building: [],
    "Class Name": [],
    "Day Of Week": [],
    Severity: [],
    State: [],
    Stage: [],
    "Transaction Message": [],
    Year: [],
    Months: [],
    Day: [],
    Season: [],
    Hour: [],
  });
  const [trafficValues, setTrafficValues] = useState({
    Date: [],
    Street: [],
    Time: [],
  });

  const icon = [
    "fa-solid fa-bell ",
    "fa-solid fa-triangle-exclamation ",
    "fa-solid fa-money-check ",
    "fa-solid fa-circle-info ",
    "fa-solid fa-cog ",
    "fa-solid fa-circle-notch ",
    "fa-solid fa-stopwatch ",
  ];
  const [selectedOption, setSelectedOption] = useState("Transaction Message");
  const [selectedChartData, setSelectedChartData] = useState([]);
  const [lightData, setLightData] = useState();
  const [wholeData, setWholeData] = useState();
  const [entireData, setEntireData] = useState(true);
  const [inputValue, setinputValue] = useState("");
  function handlesetEntireData(event) {
    if (event.target.value === "comprehensive") {
      if (wholeData) setTableData(wholeData);
      setEntireData(false);
    } else {
      if (lightData) setTableData(lightData);
      setEntireData(true);
    }
  }
  const handleChange = (value) => {
    if (modalContent?.key === "traffic") {
      setSelectedOption(value);
      const selectedObject = trafficValues[value];
      const result = Object.keys(selectedObject).map((key) => [key, selectedObject[key]]);
      setSelectedChartData(result);
    } else {
      setSelectedOption(value);
      const selectedObject = uniqueValues[value];
      const result = Object.keys(selectedObject).map((key) => [key, selectedObject[key]]);
      setSelectedChartData(result);
    }
  };
  const closeChartModal = () => {
    chartContext.setOpenModel(false);
  };

  async function getChartData() {
    let dt = await commonContext.getData();
    if (dt.data) {
      setadvisory(dt.data.chart[modalContent.adv_id].advisory);
    }
  }

  const handleAdvisory = (event) => {
    if (event.target.id === "ai") {
      setShowAiAdvisory(true);
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().clear().destroy();
      }
      setTimeout(() => {
        setStoploading(true);
      }, 5000);
      getChartData();
    } else {
      setShowAiAdvisory(false);
      if ($.fn.DataTable.isDataTable("#alarmData")) {
        $("#alarmData").DataTable().destroy();
      }

      if (tableData && tableData.length > 0) {
        $(tableRef.current).DataTable({
          responsive: true,
          data: tableData,
          columns: Object.keys(tableData[0]).map((key) => ({ title: key, data: key })),
        });
      } else {
        console.error("tableData is not available or is empty.");
      }
    }
  };

  async function getHeatmapData() {
    let dt = await commonContext.getheatmapTableData(
      modalContent.xaxis,
      modalContent.yaxis,
      chartContext.selectedData.value[0],
      chartContext.selectedData.value[1],
      chartContext.bId
    );
    if (dt) {
      setTableData(dt.data);
    }
  }

  function fortmatDaytoDate(day) {
    const daysOfWeek = { "Sunday": 0, "Monday": 1, "Tuesday": 2, "Wednesday": 3, "Thursday": 4, "Friday": 5, "Saturday": 6 };
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const targetDayOfWeek = daysOfWeek[day];

    if (targetDayOfWeek === undefined) {
      throw new Error(`Invalid day: ${day}`);
    }

    const daysDifference = (currentDayOfWeek - targetDayOfWeek + 7) % 7 || 7;

    const targetDate = new Date(currentDate);
    targetDate.setDate(currentDate.getDate() - daysDifference);
    let formattedDate = targetDate.toISOString().split("T")[0];

    return formattedDate;
  }

  async function getData(value) {
    let dt = await commonContext.getFrequencyOfAssetsAll(
      modalContent.type,
      chartContext.selectedData.assetid,
      chartContext.selectedData.severity,
      chartContext.selectedData.classname,
      chartContext.selectedData.value,
      value ? "True" : "False",
      chartContext.bId
    );
    if (dt) {
      if (value) {
        setWholeData(dt.data);
      } else {
        setTableData(dt.data);
        setLightData(dt.data);
      }
    }
  }

  async function getTrafficAllData() {
    let dt = await commonContext.getTrafficVolumneByCategoryAll(modalContent.type, chartContext.selectedData.value);
    if (dt) {
      setTableData(dt.data);
    }
  }

  async function getTrafficTableData() {
    if (modalContent.column === "Day") {
      let dt = await commonContext.getTrafficDataForTable(modalContent.type, fortmatDaytoDate(chartContext.selectedData.value));
      if (dt) {
        setTableData(dt.data);
      }
    } else {
      let dt = await commonContext.getTrafficDataForTable(modalContent.type, chartContext.selectedData.value);
      if (dt) {
        setTableData(dt.data);
      }
    }
  }

  useEffect(() => {
    if (chartContext.openModel) {
      setModalContent(chartContext.chartData);
    }
  }, [chartContext.openModel]);

  useEffect(() => {
    if (modalContent) {
      if (modalContent?.key === "traffic") {
        if (modalContent.type === "time_only" || modalContent.type === "date_only") {
          getTrafficTableData();
        } else {
          getTrafficAllData();
        }
      } else {
        if (modalContent.chartType === "heatmap") {
          getHeatmapData();
        } else {
          getData();
          getData("entire");
        }
      }
    }
  }, [modalContent]);

  function handleSearch(name, data) {
    setSearch(name);
  }
  useEffect(() => {
    if (tableData.length > 0) {
      if ($.fn.DataTable.isDataTable("#alarmData")) {
        $("#alarmData").DataTable().destroy();
      }

      var table = $("#alarmData").DataTable({
        footer: true,
        responsive: true,
        data: tableData,
        columns: Object.keys(tableData[0]).map((key) => ({ title: key, data: key })),
        // "bAutoWidth": false,
        // "aoColumns": [
        //   { "sWidth": "150px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "20px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "30px" },
        //   { "sWidth": "20px" },
        //   { "sWidth": "20px" },
        // ],
        // "columnDefs": [
        //   {
        //     "width": "20%",
        //     "targets": 0,
        //   },
        // ],
        initComplete: function () {
          this.api()
            .columns()
            .every(function (index) {
              let column = this;
              let title = column.footer().textContent;

              let input = document.createElement("input");
              input.placeholder = title;
              column.footer().replaceChildren(input);
              switch (index) {
                case 0:
                  input.style.width = "150px";
                  break;
                case 1:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 12:
                  input.style.width = "70px";
                  break;
                case 2:
                case 11:
                  input.style.width = "80px";
                  break;
                default:
                  input.style.width = "100px";
              }

              input.addEventListener("keyup", () => {
                if (column.search() !== this.value) {
                  column.search(input.value).draw();
                }
              });

              if (column.title() === selectedOption) {
                input.placeholder = search;
                column.footer().replaceChildren(input);
                column.search(search).draw();
              }
            });
        },
      });
      // table.search(search).draw();

      const updatedValues = {};
      Object.keys(modalContent?.key === "traffic" ? trafficValues : uniqueValues).forEach((col) => {
        const counts = tableData.reduce((acc, row) => {
          const value = row[col];

          acc[value] = (acc[value] || 0) + 1;
          return acc;
        }, {});
        const sortedArray = Object.entries(counts).sort(([, a], [, b]) => b - a);

        const sortedCounts = Object.fromEntries(sortedArray.slice(0, 20));
        updatedValues[col] = sortedCounts;
      });
      if (modalContent?.key === "traffic") {
        setSelectedOption("Street");
        setTrafficValues(updatedValues);
      } else {
        setUniqueValues(updatedValues);
      }
    }
  }, [tableData, showAiAdvisory, search]);

  // useEffect(() => {
  //   if ($.fn.DataTable.isDataTable("#alarmData") && search && selectedOption) {
  //     let table = $("#alarmData").DataTable();
  //     let column = table.column(`${selectedOption}:name`);
  //     if (column) {
  //       column.search(search).draw();
  //     }
  //   }
  // }, [search]);

  useEffect(() => {
    if (modalContent?.key === "traffic") {
      handleChange(selectedOption);
    } else if (uniqueValues) {
      handleChange(selectedOption);
    }
  }, [uniqueValues, trafficValues]);
  return (
    <div>
      <div
        className="modal chart-modal d-block modal-xl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h5 className="modal-title">
                {modalContent?.title ?? "Charts"} -{" "}
                {modalContent?.chartType === "heatmap"
                  ? `${chartContext.selectedData.value[1]} / ${chartContext.selectedData.value[0]}`
                  : chartContext.selectedData.value}
              </h5>
              <div className="d-flex align-items-baseline">
                {/* {modalContent?.key !== "traffic" && modalContent?.chartType !== "heatmap" && (
                  <div>
                    {entireData && (
                      <div className="mx-2">
                        <button className="btn bttn ml-auto" value={"comprehensive"} onClick={handlesetEntireData}>
                          Comprehensive Data
                        </button>
                      </div>
                    )}
                    {!entireData && (
                      <div className="mx-2">
                        <button className="btn bttn ml-auto" value={"minimal"} onClick={handlesetEntireData}>
                          Minimal Data
                        </button>
                      </div>
                    )}
                  </div>
                )} */}
                {!showAiAdvisory &&
                  modalContent?.adv_rest &&
                  (modalContent?.adv_rest === "all" ||
                    modalContent?.adv_rest === chartContext.selectedData.value ||
                    (chartContext.selectedData.value[0] === "September" && chartContext.selectedData.value[1] === "Sunday")) && (
                    <i className="fa-solid fa-bolt fa-beat robo-icon" id="ai" alt="" onClick={(event) => handleAdvisory(event)} />
                  )}
                {showAiAdvisory && <i className="fa-solid fa-table table-icon" onClick={(event) => handleAdvisory(event)}></i>}
                <div>
                  <button type="button" className="close btn bttn w-10" data-dismiss="modal" aria-label="Close" onClick={closeChartModal}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body">
              {!showAiAdvisory && (
                <div className="selectContainer ">
                  <Accordion className="accordion" defaultActiveKey="0" alwaysOpen>
                    <Accordion.Item eventKey="0" className="custom-header">
                      <Accordion.Header elementType="h7">Data Analytics</Accordion.Header>
                      <Accordion.Body className="accordionContent">
                        <div className="selectMenu">
                          <div className="selectContent">
                            <div>Select Option : </div>
                            <div className="mx-2 border rounded">
                              <select name="Select Option" value={selectedOption} onChange={(event) => handleChange(event.target.value)}>
                                {Object.entries(modalContent?.key === "traffic" ? trafficValues : uniqueValues).map(([key, values]) =>
                                  Array.isArray(values) ? (
                                    values.map((val, i) => (
                                      <option key={key + i} value={val}>
                                        {val}
                                      </option>
                                    ))
                                  ) : (
                                    <option key={key} value={key}>
                                      {key}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-8 chartContent">
                            <Assetchart data={selectedChartData} name={selectedOption} handleSearch={handleSearch}></Assetchart>{" "}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Data Grid</Accordion.Header>
                      <Accordion.Body className="accordionContent">
                        <div className="tableData">
                          <table id="alarmData" className="datatable p-2" ref={tableRef}>
                            <thead className="dataTableStyle">
                              <tr>{tableData.length > 0 && Object.keys(tableData[0]).map((key) => <th key={key}>{key}</th>)}</tr>
                            </thead>
                            <tbody className="tableBody">
                              {tableData.map((row, index) => (
                                <tr key={index}>
                                  {Object.values(row).map((cell, i) => (
                                    <td key={i}>{cell}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>{tableData.length > 0 && Object.keys(tableData[0]).map((key) => <th key={key}>{key}</th>)}</tr>
                            </tfoot>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
              {showAiAdvisory && (
                <div className="advisory-body">
                  <PropagateLoader className="loader" size={15} color="white" loading={!stoploading} />
                  {stoploading && (
                    <div>
                      <div className="fs-6 fw-bold mx-3">Smart AI Data Summary:</div>
                      {advisory &&
                        advisory[modalContent.chartType === "heatmap" ? "septSun" : chartContext.selectedData.value].map((adv, index) => {
                          return (
                            <div className="advisory-item row m-2 fs-8 align-content-center">
                              {/* <span>{adv.id}. </span> */}
                              <div className="col-10 mx-2 align-content-center">{adv.observation}</div>
                              <div className="col-1 mx-4 mt-2">
                                {/* <img src={icon[index]} className="png" /> */}
                                <i className={`${icon[index]} png`}></i>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartDetails;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import BarHighChart from "../highcharts/bar-chart";
import LineHighChart from "../highcharts/lineChart";
import PieChart from "../highcharts/pieChart";
import Heatmap from "../highcharts/heatmap";
import { Tab, Tabs } from "react-bootstrap";
import Wordcloud from "../highcharts/wordcloud";
import DonutChart from "../highcharts/donut-chart";
import { CommonServiceContext } from "../../config/commonContext";
import { ChartServiceContext } from "../../config/chartContext";
import subsystems from "../../assets/subsystem.json";

function Analytics() {
  const [key, setKey] = useState("frequency");
  const [assetIds, setAssetIds] = useState();
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [alarmSeverity, setAlarmSeverity] = useState();
  const [selectedAlarmSeverity, setSelectedAlarmSeverity] = useState("");
  const [className, setClassName] = useState();
  const [selectedClassName, setSelectedClassName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [advData, setAdvData] = useState();

  const filter = ["assetid", "alarmseverityname", "alarmclassname"];
  const commonContext = useContext(CommonServiceContext);
  const chartContext = useContext(ChartServiceContext);

  async function getData(type) {
    let dt = await commonContext.getBmsAlarmsDataCount(type);
    if (dt) {
      if (type === "assetid") {
        setAssetIds(dt.data);
      } else if (type === "alarmseverityname") {
        setAlarmSeverity(dt.data);
      } else if (type === "alarmclassname") {
        setClassName(dt.data);
      }
    }
  }

  useEffect(() => {
    async function getAdvisoryData() {
      let mydata = await commonContext.getData();
      if (mydata) {
        setAdvData(mydata.data);
      }
    }
    getAdvisoryData();
  }, []);

  function SOPAction() {
    var URL = commonContext.sopURL;

    window.open(URL, "mywin", "left=250,top=100,width=1000,height=800,toolbar=1,resizable=0");
  }
  useEffect(() => {
    filter.map((ftr) => {
      getData(ftr);
    });
  }, []);
  function handlesort(event, label) {
    if (label === "assetid") {
      setSelectedAssetId(event.target.value);
    } else if (label === "alarmseverityname") {
      setSelectedAlarmSeverity(event.target.value);
    } else if (label === "alarmclassname") {
      setSelectedClassName(event.target.value);
    }
  }

  const setValue = (advid) => {
    if (advid) setSelectedId(advid);
  };

  const onClickBack = () => {
    commonContext.setShowAnalytics(false);
  };

  return (
    <div className={`dashboard background-data ${chartContext.openModel ? "disabled" : ""}`}>
      <div className=" row fc-white fw-bold fs-3 text-center p-2">
        <div className="col-2">
          <button className="btn back-btn ml-auto" onClick={onClickBack}>
            Back
          </button>
        </div>
        <div className="col-10 analytics-title">Analytics</div>
      </div>
      <div className="dashboard-body m-2">
        <div className="d-flex justify-content-end m-3 mb-4">
          <div className=" d-flex align-items-baseline w-25">
            <label className="form-label mr-1">Asset Id : &nbsp;</label>
            <select className="form-select" name="warranty_type_id" onChange={(event) => handlesort(event, "assetid")}>
              <option value=""> Select Asset Id</option>
              <option value="All">All</option>
              {assetIds?.map((aid, i) => {
                return (
                  <option key={i} value={aid[0]}>
                    {aid[0]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-baseline w-25">
            <label className="form-label mr-1">Alarm Severity : &nbsp;</label>
            <select className="form-select" name="warranty_type_id" onChange={(event) => handlesort(event, "alarmseverityname")}>
              <option value=""> Select Severity</option>
              <option value="All">All</option>
              {alarmSeverity?.map((aid, i) => {
                return (
                  <option key={i} value={aid[0]}>
                    {aid[0]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-baseline w-25">
            <label className="form-label mr-1">Alarm Class Name : &nbsp;</label>
            <select className="form-select" name="warranty_type_id" onChange={(event) => handlesort(event, "alarmclassname")}>
              <option value=""> Select Class Name</option>
              <option value="All">All</option>
              {className?.map((aid, i) => {
                return (
                  <option key={i} value={aid[0]}>
                    {aid[0]}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
          <Tab eventKey="frequency" title="Frequency Analysis" className=" cursor-pointer">
            <div>
              <div className="fw-bold fs-5 m-1">Frequency Analysis Of Alarms</div>
              <div className="row p-2">
                {subsystems.bms.analytics.frequencyPlots.map((freq, i) => (
                  <div key={i} className="col-6 mt-2">
                    {freq.chartType === "bar" && (
                      <BarHighChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]} click={setValue}></BarHighChart>
                    )}
                    {freq.chartType === "wordcloud" && (
                      <Wordcloud data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]} click={setValue}></Wordcloud>
                    )}
                    {freq.chartType === "pie" && (
                      <PieChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]} click={setValue}></PieChart>
                    )}
                    {freq.chartType === "donut" && (
                      <DonutChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]} click={setValue}></DonutChart>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Tab>
          <Tab eventKey="timeseries" title="Time Series Analysis" className=" cursor-pointer">
            <div>
              <div className="fw-bold fs-5 m-1">Time Series Analysis Of Alarms</div>
              <div className="row p-2">
                {subsystems?.bms.analytics.timeSeriesPlots.map((freq, i) => (
                  <div key={i} className="col-6 mt-2">
                    {freq.chartType === "line" && (
                      <LineHighChart
                        data={freq}
                        filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]}
                        click={setValue}
                      ></LineHighChart>
                    )}
                    {freq.chartType === "bar" && (
                      <BarHighChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]} click={setValue}></BarHighChart>
                    )}
                    {freq.chartType === "pie" && (
                      <PieChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]} click={setValue}></PieChart>
                    )}
                    {freq.chartType === "heatmap" && (
                      <Heatmap data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]} click={setValue}></Heatmap>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Tab>
          <Tab eventKey="Forecast" title="Forecast Analysis" className=" cursor-pointer">
            <div>
              <div className="fw-bold fs-5 m-1">Forecast Alarm Trend</div>
              <div className="row p-2">
                {subsystems?.bms.analytics.forecastPlots.map((freq, i) => (
                  <div key={i} className="col-6 mt-2">
                    {freq.chartType === "line" && (
                      <LineHighChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]}></LineHighChart>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="fw-bold fs-5 m-1">Fan Related Issues</div>
              <div className="row p-2">
                {subsystems?.bms.analytics.fanRelatedPlots.map((freq, i) => (
                  <div key={i} className="col-6 mt-2">
                    {freq.chartType === "line" && (
                      <LineHighChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]}></LineHighChart>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="fw-bold fs-5 m-1">Temperature Related Issues</div>
              <div className="row p-2">
                {subsystems.bms.analytics.temperatureRelatedPlots.map((freq, i) => (
                  <div key={i} className="col-6 mt-2">
                    {freq.chartType === "line" && (
                      <LineHighChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]}></LineHighChart>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="fw-bold fs-5 m-1">Device offline Related Issues</div>
              <div className="row p-2">
                {subsystems.bms.analytics.deviceOfflineRelatedPlots.map((freq, i) => (
                  <div key={i} className="col-6 mt-2">
                    {freq.chartType === "line" && (
                      <LineHighChart data={freq} filter={[selectedAssetId, selectedAlarmSeverity, selectedClassName]}></LineHighChart>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Tab>
          <Tab eventKey="advisory" title="Advisory" className="cursor-pointer">
            <div className="d-flex flex-row flex-wrap">
              {advData?.advisory.analytics.map((adv, index) => (
                <div
                  className="card mb-2 m-2"
                  style={{
                    width: "48%",
                    backgroundColor: "hsla(202, 87%, 53%, 0.16)",
                    color: "white",
                  }}
                  key={index}
                >
                  <div className="card-body mx-2 mb-2">
                    {adv.observation != null ? (
                      <div>
                        <h6 className="card-subtitle">Observation</h6>
                        <p className="text-justify  py-1">{adv.observation}</p>
                      </div>
                    ) : (
                      <div>
                        <h6 className="card-subtitle">Cause</h6>
                        <p className="text-justify  py-1">{adv.cause}</p>
                      </div>
                    )}

                    {adv.cause != null ? (
                      <div>
                        <h6 className="card-subtitle">Cause</h6>
                        <p className="text-justify  py-1">{adv.cause}</p>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <button className="btn back-btn ml-auto" style={{ width: "15%", margin: "0% 0% 1% 80%" }} onClick={SOPAction}>
                    Act
                  </button>
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>
        {selectedId && (
          <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block", color: "black", top: "20%" }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ backgroundColor: "#09477a" }}>
                <div className="modal-header d-flex justify-content-between align-items-center">
                  <h5 className="modal-title  " style={{ fontSize: "20px", color: "white" }}>
                    Advisory
                  </h5>
                  <div>
                    <button type="button" className="close btn bttn w-10" onClick={() => setSelectedId(null)} aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
                <div className="modal-body">
                  {advData?.advisory.analytics
                    .filter((adv) => adv.id === selectedId)
                    .map((adv) => (
                      <div key={adv.id} style={{ fontSize: "14px", color: "white" }}>
                        <h6 className="card-subtitle  p-1">Observation</h6>
                        <p className="text-justify px-2">{adv.observation}</p>
                        <h6 className="card-subtitle p-1">Cause</h6>
                        <p className="text-justify px-2">{adv.cause}</p>
                      </div>
                    ))}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={SOPAction}>
                    Act
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Analytics;

import React, { useEffect, useState } from "react";
import axios from "axios";
export const CommonServiceContext = React.createContext();

const trafficIframeURL = "/deckgl-traffic/traffic.html";
const traffic3DIframeURL = "/trips";
const trafficdeckglIframeURL = "/deckgl-traffic/deck.html";
const sopURL = "https://100.astrikos.xyz:3443/createg?Summary=Anomaly%20Present%20Advice&Details=&MasterCriticalityId=3&MasterCategoryTypeId=63";
const sop = "http://192.168.0.43:8091/";
const deltaURL = "/Delta";
const modelIssueURL = "/model?anomalies=ahu";
const modelURL = "/model";
const analyticsURL = "/analytics";
const visioWebURL = "/VisioWeb-1.16.5-20240628/application/mapviewer-uikit/mapviewer.html?hash=kfef2d90dadc585d4f0657d84feb57d1a866f7800#";
const gmapURL = "/gmaps";
const URL = "http://omnific_api.astrikos.xyz:3080";
export const CommonServiceProvider = ({ children }) => {
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [selectedtype, setSelectedType] = useState([]);
  const [trackOption, setTrackOption] = useState();
  const [disableDeploy, setDisableDeploy] = useState(false);
  const [startPolice, setStartPolice] = useState(false);
  const [startAmbulance, setStartAmbulance] = useState(false);
  const [startFire, setStartFire] = useState(false);
  const [sopStatus, setSOPStatus] = useState({
    fileReport: true,
    police: true,
    ambulance: true,
    fire: true,
  });

  async function updateDeviceState() {
    let data = await getDeviceState();
    if (data) {
      console.log(data);
      data["SIM-004"].state = false;
      setDeviceState(data);
    }
  }

  useEffect(() => {
    if (
      (selectedtype.length === 1 && !sopStatus.police) ||
      (selectedtype.length === 2 && !sopStatus.police && !sopStatus.ambulance) ||
      (selectedtype.length === 3 && !sopStatus.police && !sopStatus.ambulance && !sopStatus.fire)
    ) {
      updateDeviceState();
    }
  }, [sopStatus]);

  async function getBmsAlarmsDataCount(type, assetid, severity, classname, buildingId, order, limit) {
    let url = `${URL}/bms/alarms/data/count?type=${type}`;
    if (assetid) url += `&assetid=${assetid}`;
    if (severity) url += `&severity=${severity}`;
    if (classname) url += `&classname=${classname}`;
    if (buildingId) url += `&buildingId=${buildingId}`;
    if (order) url += `&order=${order}`;
    if (limit) url += `&limit=${limit}`;

    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getFrequencyOfAssetsAll(type, assetid, severity, classname, value, entiredatavalue, buildingId) {
    let url = `${URL}/bms/alarms/frequency/column/all?type=${type}`;
    if (assetid) {
      url += `&assetid=${assetid}`;
    }
    if (severity) {
      url += `&severity=${severity}`;
    }
    if (classname) {
      url += `&classname=${classname}`;
    }
    if (value) {
      url += `&value=${value}`;
    }
    if (entiredatavalue) {
      url += `&entireData=${entiredatavalue}`;
    }
    if (buildingId) {
      url += `&buildingID=${buildingId}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getBmsAlarmsColumnDataCount(xaxis, yaxis, assetid, severity, classname, buildingId) {
    let url = `${URL}/bms/alarms/columns/data/count?xaxis=${xaxis}&yaxis=${yaxis}`;
    if (assetid) url += `&assetid=${assetid}`;
    if (severity) url += `&severity=${severity}`;
    if (classname) url += `&classname=${classname}`;
    if (buildingId) url += `&buildingId=${buildingId}`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }
  async function getFrequencyOfyearmonth(assetid, severity, classname) {
    let url = `${URL}/bms/alarms/frequency/yearmonth?`;
    if (assetid) {
      url += `&assetid=${assetid}`;
    }
    if (severity) {
      url += `&severity=${severity}`;
    }
    if (classname) {
      url += `&classname=${classname}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTimeseriesOfColumnsValue(type, value, assetid, severity, classname) {
    let url = `${URL}/bms/alarms/timeseries/columnvalue?type=${type}&value=${value}`;
    if (assetid) {
      url += `&assetid=${assetid}`;
    }
    if (severity) {
      url += `&severity=${severity}`;
    }
    if (classname) {
      url += `&classname=${classname}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTimeseriesColumnData(type, assetid, severity, classname) {
    let url = `${URL}/bms/alarms/data?type=${type}`;
    if (assetid) {
      url += `&assetid=${assetid}`;
    }
    if (severity) {
      url += `&severity=${severity}`;
    }
    if (classname) {
      url += `&classname=${classname}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function gettrafficheatmap() {
    let url = `${URL}/traffic/heatmap`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTrafficDataByHour(selectedDate) {
    let url = `${URL}/traffic/hour?`;

    if (selectedDate) {
      const [day, month, year] = selectedDate.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      url += `&date=${formattedDate}`;
    }

    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function getTrafficVolumneByCategory(type, key, selectedDate, selectedtime) {
    let url = `${URL}/traffic/avg/category?`;
    if (type) {
      url += `&type=${type}`;
    }
    if (key) {
      url += `&key=${key}`;
    }
    if (selectedDate) {
      const [day, month, year] = selectedDate.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      url += `&date=${formattedDate}`;
    }
    if (selectedtime) {
      url += `&time=${selectedtime}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTrafficVolumneByCategoryAll(type, value) {
    let url = `${URL}/traffic/all?`;
    if (type) {
      url += `&type=${type}`;
    }
    if (value) {
      url += `&value=${value}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTrafficDataForTable(type, value) {
    let url = `${URL}/traffic/tableData?`;
    if (type) {
      url += `&type=${type}`;
    }
    if (value) {
      url += `&value=${value}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTrafficDataByDay() {
    let url = `${URL}/traffic/day?`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getData() {
    let url = `${URL}/advisory/jsonData`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getheatmapTableData(xaxis, yaxis, xvalue, yvalue, buildingId) {
    let url = `${URL}/bms/alarms/timeseries/heatmap/tableData?xaxis=${xaxis}&yaxis=${yaxis}&xvalue=${xvalue}&yvalue=${yvalue}`;
    if (buildingId) {
      url += `&buildingID=${buildingId}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getDeviceState() {
    return axios
      .get("http://omnific_sim.astrikos.xyz/device_state")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error fetching data from API", error);
        throw error;
      });
  }

  async function setDeviceState(data) {
    return axios
      .post("http://omnific_sim.astrikos.xyz/device_state", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function verifyIncident(summary) {
    return axios
      .get(`${URL}/sop/notify?summary=${summary}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const ahutemperaturedata = (x, y) => {
    var ahutemp;
    var tempval;
    setInterval(() => {
      ahutemp = getRandomNum(x, y);
      tempval = ahutemp + "°C";
      setInputFieldValue(tempval);
    }, 5000);
  };
  const getRandomNum = (min, max) => {
    return (Math.floor(Math.random() * (max - min + 1)) + min) / 100;
  };

  async function getAiPromt(input) {
    return axios
      .get(`${URL}/converse?input=${input}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function updateSOPStatus(summary, status) {
    return axios
      .get(`${URL}/sop/status?summary=${summary}&status=${status}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const getLocation = async (latitude, longitude) => {
    return axios
      .get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.error("Error :", error));
  };

  return (
    <CommonServiceContext.Provider
      value={{
        showAnalytics,
        trafficIframeURL,
        traffic3DIframeURL,
        trafficdeckglIframeURL,
        sopURL,
        sop,
        deltaURL,
        analyticsURL,
        visioWebURL,
        modelIssueURL,
        gmapURL,
        modelURL,
        setShowAnalytics,
        getBmsAlarmsDataCount,
        getFrequencyOfAssetsAll,
        getBmsAlarmsColumnDataCount,
        getFrequencyOfyearmonth,
        getTimeseriesOfColumnsValue,
        getTimeseriesColumnData,
        gettrafficheatmap,
        getTrafficDataByHour,
        getTrafficVolumneByCategory,
        getTrafficVolumneByCategoryAll,
        getTrafficDataForTable,
        getTrafficDataByDay,
        getData,
        getheatmapTableData,
        getDeviceState,
        setDeviceState,
        verifyIncident,
        getRandomNumber,
        inputFieldValue,
        ahutemperaturedata,
        getAiPromt,
        startPolice,
        setStartPolice,
        startAmbulance,
        setStartAmbulance,
        startFire,
        setStartFire,
        setDisableDeploy,
        disableDeploy,
        setTrackOption,
        trackOption,
        setSelectedType,
        selectedtype,
        getLocation,
        updateSOPStatus,
        setSOPStatus,
        sopStatus,
      }}
    >
      {children}
    </CommonServiceContext.Provider>
  );
};

import React from "react";
import * as echarts from "echarts";

const TempChart = ({ data }) => {
  const chartRef = React.useRef(null);

  React.useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const option = {
      title: {
        text: data?.title ?? "",
        textStyle: {
          color: "white",
          fontSize: 12,
        },
        left: "20px",
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: data?.xData,
        name: data?.xaxis,
        nameLocation: "center",
        nameGap: 37,
        nameTextStyle: {
          color: "white",
          fontSize: 12,
        },
        axisLabel: {
          textStyle: {
            color: "white",
            fontSize: 12,
          },
        },
      },
      yAxis: {
        name: data?.yaxis,
        nameLocation: "center",
        nameGap: 37,
        nameTextStyle: {
          color: "white",
          fontSize: 12,
        },
        type: "value",
        axisLabel: {
          textStyle: {
            color: "white",
            fontSize: 12,
          },
        },
      },
      series: [
        {
          name: "Reactive Actual",
          type: "line",
          data: data?.data?.rpa,
          itemStyle: {
            color: "green",
          },
        },
        {
          name: "Reactive Predicted",
          type: "line",
          data: data?.data?.rpp,
          itemStyle: {
            color: "orange",
          },
        },
        {
          name: "Actual",
          type: "line",
          data: data?.data?.actual,
          itemStyle: {
            color: "green", // Example: use a different color
          },
        },
        {
          name: "Predicted",
          type: "line",
          data: data?.data?.predicted,
          itemStyle: {
            color: "orange", // Example: use a different color
          },
        },
      ],
    };

    chartInstance.setOption(option);

    // Handle window resize
    window.addEventListener("resize", () => {
      chartInstance.resize();
    });

    return () => {
      window.removeEventListener("resize", () => {
        chartInstance.resize();
      });
      chartInstance.dispose();
    };
  }, [data]);

  return <div ref={chartRef} className="chart-container m-2" style={{ height: "60vh", width: "45vw" }} />;
};

export default TempChart;

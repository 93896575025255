/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import Variablepie from "highcharts/modules/variable-pie";
import { useState } from "react";
import { CommonServiceContext } from "../../config/commonContext";
import { ChartServiceContext } from "../../config/chartContext";
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
Variablepie(Highcharts);

function PieChart(props) {
  const { data, filter, click } = props;
  const [chartData, setChartData] = useState();
  const [chartType, setchartType] = useState("pie");
  function handlechartType(event) {
    setchartType(event.target.value);
  }
  const commonContext = useContext(CommonServiceContext);
  const chartContext = useContext(ChartServiceContext);

  async function getBmsAlarmsData() {
    let dt = await commonContext.getBmsAlarmsDataCount(data.type, filter[0], filter[1], filter[2], null, data.order, data.limit ?? data.limit);
    if (dt) {
      setChartData(dt.data);
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getBmsAlarmsData();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [filter[0], filter[1], filter[2]]);
  useEffect(() => {
    const chart = Highcharts.chart(data.id, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: chartType,
        marginTop: 50,
        marginRight: 35,
        // backgroundColor: "hsla(237, 61%, 60%, 0.16)",
        backgroundColor: "hsla(202, 87%, 53%, 0.16)",
      },
      title: {
        text: data.title,
        style: {
          fontSize: "12px",
          color: "#fff",
        },
        floating: true,
        align: "left",
        x: 50,
        y: 10,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      legend: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
        },
      },
      colors: [
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [0, "hsla(158, 63%, 69%, 1)"],
            [1, "hsla(205, 65%, 60%, 1)"],
          ],
        },
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [0, "#CBEAD4"],
            [1, "#35C05F"],
          ],
        },
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [1, "hsla(194, 80%, 61%, 1)"],
            [0, "hsla(266, 68%, 65%, 1)"],
          ],
        },
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [1, "#4CDCE4"],
            [0, "#4CB9E4"],
          ],
        },
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [1, "hsla(0, 69%, 59%, 1)"],
            [0, "hsla(32, 62%, 60%, 1)"],
          ],
        },
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [1, "hsla(345, 74%, 63%, 1)"],
            [0, "hsla(15, 73%, 64%, 1)"],
          ],
        },
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [1, "#99C3FA"],
            [0, "#597EEC"],
          ],
        },
      ],
      xAxis:
        chartType === "column"
          ? {
              labels: {
                style: {
                  color: "#fff",
                },
              },
            }
          : {},
      yAxis:
        chartType === "column"
          ? {
              labels: {
                style: {
                  color: "#fff",
                },
              },
            }
          : {},
      series: [
        {
          name: "Alarm",
          data: chartData?.map((item) => ({
            name: item[0],
            y: item[1],
          })),

          point: {
            events: {
              click: function (params) {
                chartContext.handleChartClick(params.point.name, data, filter);
              },
            },
          },
        },
      ],
    });
    if (data.advId) {
      const gradientId = "linearGradientButton";

      const defs = chart.renderer.defs;
      defs.element.innerHTML += `
        <linearGradient id="${gradientId}" x1="0%" y1="0%" x2="180%" y2="180%">
          <stop offset="0%" style="stop-color: hsla(197, 85.1%, 52.5%, 1); stop-opacity: 1" />
          <stop offset="50%" style="stop-color: hsla(210, 84.1%, 42.0%, 1); stop-opacity: 1" />
        </linearGradient>
      `;
      chart.renderer
        .button(
          data.button,
          chart.plotWidth + chart.plotLeft - 100,
          chart.plotTop - 40,
          function () {
            click(data.advId);
          },
          {
            fill: `url(#${gradientId})`,
            style: {
              color: "white",
              fontWeight: "bold",
            },
            states: {
              hover: {
                // fill: "#135296",
                fill: `url(#${gradientId})`,
                color: "#fff",
              },
            },
          }
        )
        .add();
    }
  }, [data.id, data.button, chartData, chartType]);

  return (
    <div style={{ width: "46vw", height: "60vh", position: "relative" }}>
      <div style={{ position: "absolute", top: 10, right: 10, zIndex: 100 }}>
        <select className="dropdown" value={chartType} onChange={handlechartType}>
          <option value="pie" className="opt">
            Pie
          </option>
          <option value="column" className="opt">
            Bar
          </option>
        </select>
      </div>
      <div id={data.id} style={{ height: "100%" }}></div>
    </div>
  );
}

export default PieChart;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/styles.scss";
import Home from "./components/bms/Home";
import Traffic from "./components/traffic/traffic";
import Trips from "./components/traffic/trips";
import Analytics from "./components/bms/analytics";
import Model from "./components/model/model";
import AhuAnalytics from "./components/bms/ahuAnalytics";
import Delta from "./components/bms/delta";
import Accident from "./components/cctv/accidents/accident";
import Gmap from "./components/bms/gmap.jsx";
// import Overspeed from "./components/cctv/violation/violation.jsx";
// import Signaljump from "./components/cctv/violation/violation.jsx";
// import Wronglane from "./components/cctv/violation/violation.jsx";
import Violation from "./components/cctv/violation/violation.jsx";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/bms" element={<Home />} />
          <Route path="/traffic" element={<Traffic />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/trips" element={<Trips />} />
          <Route path="/model" element={<Model />} />
          <Route path="/delta" element={<Delta />} />
          <Route path="/ahu-analytics" element={<AhuAnalytics />} />
          <Route path="/accident" element={<Accident />} />
          <Route path="/overspeed" element={<Violation />} />
          <Route path="/signaljump" element={<Violation />}></Route>
          <Route path="/wronglane" element={<Violation />}></Route>
          <Route path="/gmaps" element={<Gmap />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

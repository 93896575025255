/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* global google */

import React, { useContext, useEffect, useRef, useState } from "react";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { PathLayer } from "@deck.gl/layers";
import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import TripBuilder from "./tripBuilder";
import DATA_URL from "../../../assets/trips.json";
import ProgressTracking from "./progressTracking";
import AccidentTabs from "../../common/dashboardtabs";
import { HttpServiceContext } from "../../../config/httpContext";
import { CommonServiceContext } from "../../../config/commonContext";

const GOOGLE_MAPS_API_KEY = "AIzaSyDMlVAALBCx-2tliIzQKST8qFwIyCvIRc4";
const GOOGLE_MAP_ID = "b5908005c4b10751";
const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=beta`;
const AMBULANCE_MODEL_URL = "./assets/ambulance_car_-_low_poly/scene.gltf";
const FIRE_TRUCK_MODEL_URL = "./assets/firetruck/scene.gltf";
const POLICE_MODEL_URL = "./assets/honda_civic_malaysia_police_car/scene.gltf";

const option1 = [
  { value: "option1", label: "Ignore" },
  { value: "option2", label: "Minor - File Report" },
  {
    value: "Deploy",
    label: "Major Incident Severity",
  },
];

const option2 = [
  { key: "Po", name: "Deploy Police" },
  { key: "Po-Amb", name: "Deploy Police and Ambulance" },
  { key: "Po-Amb-Fi", name: "Deploy Police, Fire and Ambulance" },
];

const option3 = [
  { key: "police", name: "Start Police" },
  { key: "fire", name: "Start Fire" },
  { key: "ambulance", name: "Start Ambulance" },
];

const trackdropdown = [
  { key: "police", name: "Track Police" },
  { key: "fire", name: "Track Fire" },
  { key: "ambulance", name: "Track Ambulance" },
];

const Accident = () => {
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionType, setSelectedOptionType] = useState();
  const [gmap, setGmap] = useState();
  const [activeTab, setActiveTab] = useState("Progress");
  const [reachedDestination, setReachedDestination] = useState({
    police: false,
    ambulance: false,
    fire: false,
  });
  const mapRef = useRef(null);
  const overlayRef = useRef(null);
  const [option, setOption] = useState({ tracking: true, showPaths: true });
  const [mapCenter, setMapCenter] = useState({
    lat: 37.783413877011085,
    lng: -122.42432091193467,
  });
  const [policeFrame, setPoliceFrame] = useState(null);
  const [ambulanceFrame, setAmbulanceFrame] = useState(null);
  const [fireFrame, setFireFrame] = useState(null);
  const [policeLastTimestamp, setPoliceLastTimestamp] = useState(0);
  const [ambulanceLastTimestamp, setAmbulanceLastTimestamp] = useState(0);
  const [fireTruckLastTimestamp, setFireTruckLastTimestamp] = useState(0);
  const [closecctv1, setCloseCCTV1] = useState(true);
  const [showWindow, setShowWindow] = useState(false);
  const [closecctv2, setCloseCCTV2] = useState(false);
  const [accidentDetails, setAccidentDetails] = useState({
    latitude: 37.783413877011085,
    longitude: -122.42432091193467,
    place: "",
  });
  const [workOrder, setWorkOrder] = useState(false);
  const [policeReached, setPoliceReached] = useState(false);
  const [ambulanceReached, setAmbulanceReached] = useState(false);
  const [fireReached, setFireReached] = useState(false);
  const [policeclosed, setPoliceClosed] = useState(false);
  const [ambulanceClosed, setAmbulanceClosed] = useState(false);
  const [fireClosed, setFireClosed] = useState(false);

  const policeAnimationRef = useRef(null);
  const ambulanceAnimationRef = useRef(null);
  const fireTruckAnimationRef = useRef(null);

  var shouldCenter = true;
  let policeAnimation, ambulanceAnimation, fireTruckAnimation;

  const commonContext = useContext(CommonServiceContext);
  const httpContext = useContext(HttpServiceContext);

  let timestamp = 0;
  var destinationStatus = {
    police: false,
    ambulance: false,
    fire: false,
  };
  const policeTrips = DATA_URL.policeVans.map((waypoints) => new TripBuilder({ waypoints, loop: false }));
  const ambulanceTrips = DATA_URL.ambulances.map((waypoints) => new TripBuilder({ waypoints, loop: false }));
  const fireTruckTrips = DATA_URL.fireTrucks.map((waypoints) => new TripBuilder({ waypoints, loop: false }));

  const startEndPoints = [...DATA_URL.policeVans, ...DATA_URL.ambulances, ...DATA_URL.fireTrucks]
    .map((trip) => [trip[0], trip[trip.length - 1]])
    .flat();
  const endPoint = startEndPoints[1];

  useEffect(() => {
    setPlacesName();
    loadScript(GOOGLE_MAPS_API_URL);

    const interval = setInterval(() => {
      if (window.google) {
        clearInterval(interval);

        const map = new google.maps.Map(mapRef.current, {
          center: mapCenter,

          zoom: 19,
          heading: 180,
          tilt: 90,
          isFractionalZoomEnabled: true,
          mapId: GOOGLE_MAP_ID,

          mapTypeControlOptions: {
            mapTypeIds: ["roadmap", "terrain"],
          },
          streetViewControl: false,
          draggable: true,
        });
        const marker = new google.maps.Marker({
          position: mapCenter,
          map: map,
          draggable: false,
          icon: {
            url: "./assets/accident-spot.png",
            scaledSize: new google.maps.Size(35, 45),
          },
          title: "Accident Spot",
        });

        const marker1 = new google.maps.Marker({
          position: { lat: 37.783403877011085, lng: -122.42420091193467 },
          map: map,
          draggable: false,
          icon: {
            url: "./assets/cctv.png",
            scaledSize: new google.maps.Size(30, 30),
          },
          title: "Accident Spot",
        });
        marker1.addListener("click", function () {
          setCloseCCTV1(true);
          setCloseCCTV2(false);
        });
        const marker2 = new google.maps.Marker({
          position: { lat: 37.783403877011085, lng: -122.42450091193467 },
          map: map,
          draggable: false,
          icon: {
            url: "./assets/cctv.png",
            scaledSize: new google.maps.Size(30, 30),
          },
          title: "Accident Spot",
        });
        marker2.addListener("click", function () {
          setCloseCCTV2(true);
          setCloseCCTV1(false);
        });

        google.maps.event.addListener(marker, "dragend", function (event) {
          const newCenter = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          setMapCenter(newCenter);
          map.setCenter(newCenter);
        });

        setGmap(map);
        if (overlayRef.current) {
          overlayRef.current.setMap(map);
        }
      }
    }, 100);

    return () => {
      if (overlayRef.current) {
        overlayRef.current.finalize();
      }
      clearInterval(interval);
    };
  }, [mapCenter]);

  useEffect(() => {
    if (commonContext.startPolice) startPoliceVanAnimation("police");
    if (commonContext.startAmbulance) startPoliceVanAnimation("ambulance");
    if (commonContext.startFire) startPoliceVanAnimation("fire");
  }, [commonContext.startPolice, commonContext.startAmbulance, commonContext.startFire]);

  useEffect(() => {
    if (policeReached) {
      httpContext.updateSOPStatus("Police Deployed", "Completed");
    }
  }, [policeReached]);

  useEffect(() => {
    if (fireReached) {
      httpContext.updateSOPStatus("Fire Engine Deployed", "Completed");
    }
  }, [fireReached]);

  useEffect(() => {
    if (ambulanceReached) {
      httpContext.updateSOPStatus("Ambulance Deployed", "Completed");
    }
  }, [ambulanceReached]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    commonContext.setSelectedType([]);
  };

  const closeVideo = () => {
    setCloseCCTV1(false);
    setCloseCCTV2(false);
  };

  const handleSubOptionChange = (opttype) => {
    setSelectedOptionType(opttype);
    if (opttype === "Po") {
      commonContext.setSelectedType(["police"]);
      addLayersToMap(["police"]);
      setPoliceLastTimestamp(0);
      cancelAnimationFrame(policeAnimationRef.current);
      setPoliceFrame(null);
    }
    if (opttype === "Po-Amb") {
      commonContext.setSelectedType(["police", "ambulance"]);
      addLayersToMap(["police", "ambulance"]);
      setPoliceLastTimestamp(0);
      setAmbulanceLastTimestamp(0);
      cancelAnimationFrame(policeAnimationRef.current);
      cancelAnimationFrame(ambulanceAnimationRef.current);
    }
    if (opttype === "Po-Amb-Fi") {
      commonContext.setSelectedType(["police", "fire", "ambulance"]);
      addLayersToMap(["police", "fire", "ambulance"]);
      setPoliceLastTimestamp(0);
      setAmbulanceLastTimestamp(0);
      cancelAnimationFrame(policeAnimationRef.current);
      cancelAnimationFrame(ambulanceAnimationRef.current);
      cancelAnimationFrame(fireTruckAnimationRef.current);
    }
  };

  function addLayersToMap(deployTypes) {
    loadScript(GOOGLE_MAPS_API_URL);
    timestamp += 0.02;
    const frame = policeTrips.map((trip) => trip.getFrame(timestamp));

    const interval = setInterval(() => {
      if (window.google) {
        clearInterval(interval);

        const map = new google.maps.Map(mapRef.current, {
          center: { lat: 37.783213877011085, lng: -122.42662091193467 },
          zoom: 16.5,
          heading: 180,
          tilt: 90,
          isFractionalZoomEnabled: true,
          mapId: GOOGLE_MAP_ID,
          mapTypeControlOptions: {
            mapTypeIds: ["roadmap", "terrain"],
          },
          streetViewControl: false,
          draggable: true,
        });

        setGmap(map);
        var data;
        var markerData;
        var policeScenegraphLayer, ambulanceScenegraphLayer, fireTruckScenegraphLayer;
        deployTypes?.forEach((type) => {
          if (type === "police") {
            policeScenegraphLayer = new ScenegraphLayer({
              id: "police-van",
              data: policeTrips.map((trip) => trip.getFrame(timestamp)),
              scenegraph: POLICE_MODEL_URL,
              sizeScale: 250,
              getPosition: (d) => d.point,
              getTranslation: [0, 0, 1],
              getOrientation: (d) => [0, 180 - d.heading, 90],
            });
            data = [...policeTrips];
            markerData = [...DATA_URL.policeVans];
            let markerPosition = [...DATA_URL.policeVans[0][0]];
            const marker1 = new google.maps.Marker({
              position: { lat: markerPosition[1], lng: markerPosition[0] },
              map: map,
              draggable: false,
              icon: {
                url: "./assets/police-station.png",
                scaledSize: new google.maps.Size(35, 35),
              },
              title: "Accident Spot",
            });
          } else if (type === "ambulance") {
            ambulanceScenegraphLayer = new ScenegraphLayer({
              id: "ambulance",
              data: ambulanceTrips.map((trip) => trip.getFrame(timestamp)),
              scenegraph: AMBULANCE_MODEL_URL,
              sizeScale: 6,
              getPosition: (d) => d.point,
              getTranslation: [0, 0, 1],
              getOrientation: (d) => [0, 180 - d.heading, 90],
            });
            data = [...data, ...ambulanceTrips];
            markerData = [...markerData, ...DATA_URL.ambulances];
            let markerPosition = [...DATA_URL.ambulances[0][0]];
            const marker1 = new google.maps.Marker({
              position: { lat: markerPosition[1], lng: markerPosition[0] },
              map: map,
              draggable: false,
              icon: {
                url: "./assets/hospital.png",
                scaledSize: new google.maps.Size(35, 35),
              },
              title: "Accident Spot",
            });
          } else if (type === "fire") {
            fireTruckScenegraphLayer = new ScenegraphLayer({
              id: "fire-truck",
              data: fireTruckTrips.map((trip) => trip.getFrame(timestamp)),
              scenegraph: FIRE_TRUCK_MODEL_URL,
              sizeScale: 5,
              getPosition: (d) => d.point,
              getTranslation: [0, 0, 1],
              getOrientation: (d) => [0, 180 - d.heading, 90],
            });
            data = [...data, ...fireTruckTrips];
            markerData = [...markerData, ...DATA_URL.fireTrucks];
            let markerPosition = [...DATA_URL.fireTrucks[0][0]];
            const marker1 = new google.maps.Marker({
              position: { lat: markerPosition[1], lng: markerPosition[0] },
              map: map,
              draggable: false,
              icon: {
                url: "./assets/fire-station.png",
                scaledSize: new google.maps.Size(35, 35),
              },
              title: "Accident Spot",
            });
          }
        });
        const startEndP = markerData?.map((trip) => [trip[0]]).flat();
        const pathLayer = new PathLayer({
          id: "trip-lines",
          data: data,
          getPath: (d) => d.keyframes.map((f) => f.point),
          getColor: (_) => [10, 100, 255],
          jointRounded: true,
          opacity: 0.5,
          getWidth: 2,
        });
        const marker = new google.maps.Marker({
          position: mapCenter,
          map: map,
          draggable: false,
          icon: {
            url: "./assets/accident-spot.png",
            scaledSize: new google.maps.Size(35, 45),
          },
          title: "Accident Spot",
        });

        const marker1 = new google.maps.Marker({
          position: { lat: 37.783403877011085, lng: -122.42420091193467 },
          map: map,
          draggable: false,
          icon: {
            url: "./assets/cctv.png",
            scaledSize: new google.maps.Size(30, 30),
          },
          title: "Accident Spot",
        });
        marker1.addListener("click", function () {
          setCloseCCTV1(true);
          setCloseCCTV2(false);
        });
        const marker2 = new google.maps.Marker({
          position: { lat: 37.783403877011085, lng: -122.42450091193467 },
          map: map,
          draggable: false,
          icon: {
            url: "./assets/cctv.png",
            scaledSize: new google.maps.Size(30, 30),
          },
          title: "Accident Spot",
        });
        marker2.addListener("click", function () {
          setCloseCCTV2(true);
          setCloseCCTV1(false);
        });

        google.maps.event.addListener(marker, "dragend", function (event) {
          const newCenter = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          setMapCenter(newCenter);
          map.setCenter(newCenter);
        });

        overlayRef.current = new GoogleMapsOverlay({
          layers: [pathLayer, policeScenegraphLayer, fireTruckScenegraphLayer, ambulanceScenegraphLayer],
        });

        overlayRef.current.setMap(map);
      }
    }, 100);
  }
  function track(event) {
    if (event.target.value === "police") {
      if (commonContext.startPolice) {
        shouldCenter = true;
        startPoliceVanAnimation("police");
      } else {
        shouldCenter = false;
        gmap?.moveCamera({
          center: { lat: -122.43232927238199, lng: 37.780449929800014 },
          zoom: 25,
        });
        overlayRef.current.setMap(gmap);
      }
    } else if (event.target.value === "ambulance") {
      if (commonContext.startAmbulance) {
        shouldCenter = true;
        startPoliceVanAnimation("ambulance");
      } else {
        shouldCenter = false;
        gmap?.moveCamera({
          center: { lat: -122.43232927238199, lng: 37.780449929800014 },
          zoom: 25,
        });
        overlayRef.current.setMap(gmap);
      }
    } else if (event.target.value === "fire") {
      if (commonContext.startFire) {
        shouldCenter = true;
        startPoliceVanAnimation("fire");
      } else {
        shouldCenter = false;
        gmap?.moveCamera({
          center: { lat: -122.43046618705746, lng: 37.780387024090814 },
          zoom: 25,
        });
        overlayRef.current.setMap(gmap);
      }
    }
  }

  async function setPlacesName() {
    let plc = await httpContext.getLocation(mapCenter.lat, mapCenter.lng);
    if (plc) {
      setAccidentDetails({
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        place: plc.display_name,
      });
    }
  }

  async function sopAction(event) {
    commonContext.setDisableDeploy(true);
    setWorkOrder(true);
    console.log(event);
    if (event.target.value === "deploy") {
      commonContext.selectedtype.forEach(async (type) => {
        if (type === "police") {
          let sopResponse = await httpContext.createSop("Police Deployed", "", 2, 56);
          let intervalId = setInterval(async () => {
            let status = await httpContext.verifyIncident("Police Deployed");
            if (status && status.status === "Responded") {
              commonContext.setStartPolice(true);
            }
            if (status && status.status === "Completed") {
              setPoliceReached(true);
              console.log("setting close false: ", policeReached);
            }
            if (status && status.status === "Closed") {
              commonContext.setSOPStatus((prevStatus) => ({
                ...prevStatus,
                [type]: false,
              }));
              setPoliceClosed(true);
              clearInterval(intervalId);
              console.log(commonContext.sopStatus);
            }
          }, 5000);
        } else if (type === "fire") {
          let sopResponse = await httpContext.createSop("Fire Engine Deployed", "", 2, 56);
          let intervalId = setInterval(async () => {
            let status = await httpContext.verifyIncident("Fire Engine Deployed");
            if (status && status.status === "Responded") {
              commonContext.setStartFire(true);
            }
            if (status && status.status === "Completed") {
              setFireReached(true);
            }
            if (status && status.status === "Closed") {
              commonContext.setSOPStatus((prevStatus) => ({
                ...prevStatus,
                [type]: false,
              }));
              clearInterval(intervalId);
              setFireClosed(true);
              console.log(commonContext.sopStatus);
            }
          }, 5000);
        } else if (type === "ambulance") {
          let sopResponse = await httpContext.createSop("Ambulance Deployed", "", 2, 56);
          let intervalId = setInterval(async () => {
            let status = await httpContext.verifyIncident("Ambulance Deployed");
            if (status && status.status === "Responded") {
              commonContext.setStartAmbulance(true);
            }
            if (status && status.status === "Completed") {
              setAmbulanceReached(true);
            }
            if (status && status.status === "Closed") {
              commonContext.setSOPStatus((prevStatus) => ({
                ...prevStatus,
                [type]: false,
              }));
              clearInterval(intervalId);
              setAmbulanceClosed(true);
            }
          }, 5000);
          console.log(commonContext.sopStatus);
        }
      });
    } else {
      let sopResponse = await httpContext.createSop("Reporting Accident Placed", "", 2, 56);
      let intervalId = setInterval(async () => {
        let status = await httpContext.verifyIncident("Reporting Accident Placed");
        if (status && status.status === "Closed") {
          commonContext.setSOPStatus((prevStatus) => ({
            ...prevStatus,
            fileReport: false,
          }));
          clearInterval(intervalId);
          console.log(commonContext.sopStatus);
        }
      }, 5000);
    }
  }

  function fetchSOP(url) {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }
  function openWindow() {
    setShowWindow(true);
  }

  function closeWindow() {
    setShowWindow(false);
    addLayersToMap(commonContext.selectedtype);
  }
  return (
    <div className="sop-panel p-3">
      <div>
        {!commonContext.showAnalytics && (
          <div className="row">
            <div className="col-7 p-2">
              <div className={`mx-lg-2 mx-md-2 accident-map ${workOrder ? "map-height" : "map-height-full"}`} ref={mapRef} />
              {commonContext.selectedtype.length !== 0 && workOrder && (
                <div className="d-flex m-3">
                  <div className="fc-white">Track : </div>
                  <select className="track-dropdown" name="" id="" value={commonContext.trackOptiFon} onChange={track}>
                    <option className="fc-black" value="">
                      Track option
                    </option>
                    {trackdropdown
                      .filter((trk) => commonContext.selectedtype.includes(trk.key))
                      .map((trk) => (
                        <option key={trk.key} className="fc-black" value={trk.key}>
                          {trk.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {workOrder && (
                <div className="mytabs p-2">
                  <div className="tabOptions">
                    <div>
                      <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                        Incidents
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setActiveTab("Progress")} className={`tabBtns ${activeTab === "Progress" ? "active" : null}`}>
                        Progress
                      </button>
                    </div>
                    <div>
                      <th className="mini col-6">
                        <img src="/maximize.png" alt="" className="maximize" onClick={openWindow} />
                      </th>
                    </div>
                  </div>
                  <div className="tabContent ">
                    {activeTab === "Incidents" && <iframe className="sop-iframe" src={commonContext.sop} title="Sop"></iframe>}
                    {activeTab === "Progress" && (
                      <div>
                        <div className="set m-2">
                          {option3.map((opttype) =>
                            commonContext.selectedtype?.map((ty) => {
                              const getStartedStatus = (type) => {
                                switch (type) {
                                  case "police":
                                    return commonContext.startPolice;
                                  case "fire":
                                    return commonContext.startFire;
                                  case "ambulance":
                                    return commonContext.startAmbulance;
                                  default:
                                    return false;
                                }
                              };
                              const getReachedStatus = (type) => {
                                switch (type) {
                                  case "police":
                                    return policeReached;
                                  case "fire":
                                    return fireReached;
                                  case "ambulance":
                                    return ambulanceReached;
                                  default:
                                    return false;
                                }
                              };
                              const getClosedStatus = (type) => {
                                switch (type) {
                                  case "police":
                                    return policeclosed;
                                  case "fire":
                                    return fireClosed;
                                  case "ambulance":
                                    return ambulanceClosed;
                                  default:
                                    return false;
                                }
                              };

                              return (
                                ty === opttype.key && (
                                  <ProgressTracking
                                    key={ty}
                                    title={ty}
                                    label="accident"
                                    started={getStartedStatus(ty)}
                                    reached={getReachedStatus(ty)}
                                    closed={getClosedStatus(ty)}
                                  />
                                )
                              );
                            })
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className=" col-5 p-2">
              <div className="m-1 side-panel side-panel-h">
                <div className="m-1 p-2 deatils">
                  <div className="fs-5 fw-bold text-center">Incident Details</div>
                  <div className="fw-bold">
                    <div className="m-2">
                      Location: &nbsp;
                      <span className="fs-7 fw-lighter">
                        <a href={`https://www.google.com/maps/place/${accidentDetails.latitude},${accidentDetails.longitude}`}>
                          {accidentDetails.place}
                        </a>
                      </span>
                    </div>
                    <div className="m-2">
                      Coordinates: &nbsp;
                      <span className=" fw-lighter">
                        {accidentDetails.latitude}, {accidentDetails.longitude}
                      </span>
                    </div>
                  </div>
                </div>
                {(closecctv1 || closecctv2) && (
                  <div className="cctv-window">
                    {closecctv1 && (
                      <video autoPlay muted loop width="100%" height="100%">
                        <source src="./assets/cctv.mp4" type="video/mp4" />
                      </video>
                    )}
                    {closecctv2 && (
                      <video autoPlay muted loop width="100%" height="100%">
                        <source src="./assets/cctv_1.mp4" type="video/mp4" />
                      </video>
                    )}
                  </div>
                )}
              </div>
              <div className="m-1 side-panel side-panel-h-down">
                <div className="headertext fw-bold fs-5">Select Option for SOP</div>
                <div className="main-block">
                  {option1.map((option) => (
                    <div className="fs-6 m-1" key={option.value}>
                      <input
                        className="me-1"
                        type="radio"
                        id={option.value}
                        name="option1"
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={() => handleOptionChange(option.value)}
                      />
                      <label htmlFor={option.value}>{option.label}</label>
                    </div>
                  ))}
                </div>
                {selectedOption === "option2" && (
                  <div className="m-2 action p-2 ">
                    <div className=" fw-bold fs-6">Action</div>
                    <div className="d-flex fs-7 align-items-center justify-content-around">
                      <div>Create a new work order request for File Report</div>
                      <div className="">
                        <button className="bttn btn fs-7" onClick={(event) => sopAction(event)}>
                          Work Order
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {selectedOption === "Deploy" && (
                  <div>
                    <div className="sub-block">
                      <div className="headertext fw-bold fs-6">Select Deploy Types</div>
                      <div className="radio">
                        {option2.map((type) => (
                          <div className="fs-6 m-1" key={type.value}>
                            <input
                              className="me-1"
                              disabled={commonContext.disableDeploy}
                              type="radio"
                              id={type.key}
                              name="Option2"
                              value={type.key}
                              checked={selectedOptionType === type.key}
                              onChange={() => handleSubOptionChange(type.key)}
                            />
                            <label htmlFor={type.key}>{type.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {commonContext.selectedtype.length !== 0 && (
                      <div className="m-2 action p-2  ">
                        <div className=" fw-bold fs-6">Action</div>
                        <div className="d-flex fs-7 align-items-center justify-content-around">
                          <div>Deploy </div>
                          <div className="">
                            <button className="bttn btn fs-7" value="deploy" onClick={(event) => sopAction(event)}>
                              Work Order
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {showWindow && (
          <div className="ahu-analytics">
            <AccidentTabs closeWindow={closeWindow} active={activeTab} label={"AccidentTabs"} />
          </div>
        )}
      </div>
    </div>
  );

  function startPoliceVanAnimation(vehicleType) {
    let localFrame, setFrame, lastTimestamp, trips, layerId, animationRef;

    switch (vehicleType) {
      case "police":
        trips = policeTrips;
        layerId = "police-van";
        localFrame = policeFrame;
        lastTimestamp = policeLastTimestamp;
        animationRef = policeAnimationRef;
        setFrame = setPoliceFrame;
        break;
      case "ambulance":
        trips = ambulanceTrips;
        layerId = "ambulance";
        localFrame = ambulanceFrame;
        lastTimestamp = ambulanceLastTimestamp;
        animationRef = ambulanceAnimationRef;
        setFrame = setAmbulanceFrame;
        break;
      case "fire":
        trips = fireTruckTrips;
        layerId = "fire-truck";
        localFrame = fireFrame;
        lastTimestamp = fireTruckLastTimestamp;
        animationRef = fireTruckAnimationRef;
        setFrame = setFireFrame;
        break;
      default:
        return;
    }

    let timestamp = lastTimestamp || 0;

    const onAnimationFrame = () => {
      timestamp += 0.12;

      const frame = trips?.map((trip) => trip.getFrame(timestamp));
      const hasReachedDestination = trips?.every((trip, index) => {
        const lastKeyframe = trip.keyframes[trip.keyframes.length - 1];
        return frame[index].point[0] === lastKeyframe.point[0] && frame[index].point[1] === lastKeyframe.point[1];
      });
      if (option.tracking && shouldCenter && !hasReachedDestination) {
        gmap?.moveCamera({
          center: { lat: frame[0].point[1], lng: frame[0].point[0] },
          heading: frame[0].heading,
        });
      }
      overlayRef.current.setProps({
        layers: overlayRef.current.props.layers.map((layer) => (layer?.id === layerId ? layer.clone({ data: frame }) : layer)),
      });
      if (hasReachedDestination) {
      }

      setReachedDestination(destinationStatus);

      switch (vehicleType) {
        case "police":
          policeAnimationRef.current = requestAnimationFrame(onAnimationFrame);
          setPoliceLastTimestamp(timestamp);
          if (hasReachedDestination) {
            setPoliceReached(true);
          }
          break;
        case "ambulance":
          ambulanceAnimationRef.current = requestAnimationFrame(onAnimationFrame);
          setAmbulanceLastTimestamp(timestamp);
          if (hasReachedDestination) {
            setAmbulanceReached(true);
          }
          break;
        case "fire":
          fireTruckAnimationRef.current = requestAnimationFrame(onAnimationFrame);
          setFireTruckLastTimestamp(timestamp);
          if (hasReachedDestination) {
            setFireReached(true);
          }
          break;
        default:
          break;
      }
    };

    onAnimationFrame();

    return () => {
      switch (vehicleType) {
        case "police":
          cancelAnimationFrame(policeAnimationRef.current);
          break;
        case "ambulance":
          cancelAnimationFrame(ambulanceAnimationRef.current);
          break;
        case "fireTruck":
          cancelAnimationFrame(fireTruckAnimationRef.current);
          break;
        default:
          break;
      }
    };
  }
};

export default Accident;

function loadScript(url) {
  if (typeof google !== "undefined") {
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.onload = resolve;
    document.head.appendChild(script);
  });
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { CommonServiceProvider } from "./config/commonContext";
import { ChartServiceProvider } from "./config/chartContext";
import { ModelServiceProvider } from "./config/modelContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CommonServiceProvider>
      <ChartServiceProvider>
        <ModelServiceProvider>
          <App />
        </ModelServiceProvider>
      </ChartServiceProvider>
    </CommonServiceProvider>
  </React.StrictMode>
);

import React, { useEffect, useRef, useState, useContext } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { CommonServiceContext } from "../../../config/commonContext";
import { useLocation } from "react-router-dom";
import Sop from "../../common/sop";

const GOOGLE_MAPS_API_KEY = "AIzaSyDMlVAALBCx-2tliIzQKST8qFwIyCvIRc4";

const mapContainerStyle = {
  width: "100%",
  height: "95vh",
  border: "transparent",
  borderRadius: "25px",
};

const center = {
  lat: 37.783413877011085,
  lng: -122.42432091193467,
};

const Violation = () => {
  const mapRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState();

  const markerRef = useRef(null);
  const [videoVisible, setVideoVisible] = useState(false);
  const [imageVisible, setImageVisible] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [showSOPPanel, setShowSOPPanel] = useState(false);
  const [voilation, setVoilation] = useState("-----");
  const [videoUrl, setVideoUrl] = useState("./assets/videoplayback.mp4");
  const [imageUrl, setImageUrl] = useState("./assets/nmm.jfif");
  const commonContext = useContext(CommonServiceContext);
  const [sopSuccessful, setSopSuccessFul] = useState(false);

  const location = useLocation();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    commonContext.setSelectedType([]);
  };

  const [accidentDetails, setAccidentDetails] = useState({
    latitude: "----",
    longitude: "-----",
    place: "",
  });

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;

      // Cleanup function
      return () => {
        if (markerRef.current) {
          markerRef.current.setMap(null);
        }
      };
    }
  }, [mapRef.current]);

  async function setPlacesName() {
    let plc = await commonContext.getLocation(center.lat, center.lng);
    if (plc) {
      setAccidentDetails({
        latitude: center.lat,
        longitude: center.lng,
        place: plc.display_name,
      });
    }
  }

  const onMapLoad = (map) => {
    mapRef.current = map;

    const marker = new window.google.maps.Marker({
      position: { lat: 37.78332, lng: -122.4244 },
      map: map,
      title: "Incident Location",
      icon: {
        url: "./assets/speedlimit.png",
        scaledSize: new window.google.maps.Size(70, 70),
      },
    });

    markerRef.current = marker;

    const marker1 = new window.google.maps.Marker({
      position: { lat: 37.78342, lng: -122.424288 },
      map: map,
      title: "Incident Location",
      icon: {
        url: "./assets/alert.png",
        scaledSize: new window.google.maps.Size(40, 40),
      },
    });
    markerRef.current = marker1;
    marker1.addListener("click", () => {
      setAccidentDetails({
        latitude: 37.783413877011085,
        longitude: -122.42432091193467,
        place: "",
      });
      setPlacesName();
      setVideoVisible((prev) => {
        if (location.pathname === "/overspeed") {
          setVideoUrl("assets/videoplayback.mp4");
          setImageUrl("./assets/nmm.jfif");
          setVoilation("Overspeed");
        } else if (location.pathname === "/wronglane") {
          setVideoUrl("./assets/wronlane.mp4");
          setImageUrl("./assets/OIP.png");
          setVoilation("Wronglane");
        } else if (location.pathname === "/signaljump") {
          setVideoUrl("./assets/singalbreak.mp4");

          setImageUrl("./assets/numberbd.jfif");
          setVoilation("Signal jump");
        }
        if (!prev) {
          setImageVisible(false);
        }
        setShowSOPPanel(false);
        return !prev;
      });
    });
  };

  useEffect(() => {
    if (videoVisible) {
      const timer = setTimeout(() => {
        setImageVisible(true);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setImageVisible(false);
    }
  }, [videoVisible]);
  useEffect(() => {
    if (imageVisible) {
      const timer = setTimeout(() => {
        setShowSOPPanel(true);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setShowSOPPanel(false);
    }
  }, [imageVisible]);
  function handlesop() {
    setSopSuccessFul(true);
    setWorkOrder(true);

    // URL changes based on path
    let url;
    let summary;
    switch (location.pathname) {
      case "/overspeed":
        url =
          "http://omnific_api.astrikos.xyz:3080/sop/create?Summary=Traffic%20Violations%20OverSpeeding&Details=Vehicle:%20GZJ%200196&MasterCriticalityId=2&MasterCategoryTypeId=83";
        summary = "Traffic%20Violations%20OverSpeeding";
        break;
      case "/wronglane":
        url =
          "http://omnific_api.astrikos.xyz:3080/sop/create?Summary=Traffic%20Violations%20WrongLane&Details=Vehicle:%206XS%20U832&MasterCriticalityId=2&MasterCategoryTypeId=83";
        summary = "Traffic%20Violations%20WrongLane";
        break;
      case "/signaljump":
        url =
          "http://omnific_api.astrikos.xyz:3080/sop/create?Summary=Traffic%20Violations%20Signaljump&Details=Vehicle:%20F%2053310&MasterCriticalityId=2&MasterCategoryTypeId=83";
        summary = "Traffic%20Violations%20Signaljump";
        break;
      default:
        break;
    }

    fetchSOP(url);
    let intervalId = setInterval(async () => {
      let status = await commonContext.verifyIncident(summary);
      if (status && status.status === "Closed") {
        commonContext.getDeviceState().then((deviceData) => {
          if (deviceData) {
            switch (location.pathname) {
              case "/overspeed":
                deviceData["SIM-005"].status = false;
                break;
              case "/wronglane":
                deviceData["SIM-007"].status = false;
                break;
              case "/signaljump":
                deviceData["SIM-006"].status = false;
                break;
              default:
                break;
            }
            commonContext.setDeviceState(deviceData);
          }
        });
        clearInterval(intervalId);
        console.log(commonContext.sopStatus);
      }
    }, 5000);
  }

  function fetchSOP(url) {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  function closeSOP() {
    setSopSuccessFul(false);
  }
  function sopTab() {
    setSopSuccessFul(false);
  }

  return (
    <div className="sop-panel p-3">
      <div className="row">
        <div className="col-8 p-2">
          <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={19} onLoad={onMapLoad}></GoogleMap>
          </LoadScript>
        </div>
        <div className="col-4 p-2">
          <div className="m-1 side-panel side-panel-up">
            <div className="m-0 p-2 details">
              <div className="fs-5 fw-bold text-center">Incident Details</div>
              <div className="m-2">
                Location: &nbsp;
                <span className="fs-7 fw-lighter">
                  <a href={`https://www.google.com/maps/place/${accidentDetails.latitude},${accidentDetails.longitude}`}>{accidentDetails.place}</a>
                </span>
              </div>
              <div className="m-2">
                Coordinates: &nbsp;
                <span className="fs-7  fw-lighter">
                  {accidentDetails.latitude}, {accidentDetails.longitude}
                </span>
              </div>
              <div className="m-2">
                Voilation-Type:&nbsp;
                <span className="fw-lighter">{voilation}</span>
              </div>
              {videoVisible && (
                <div>
                  <video autoPlay muted loop width="100%" height="100%">
                    <source src={videoUrl} type="video/mp4" />
                  </video>
                  {imageVisible && <img className="m-1 d-block mx-auto" width="40%" height="30%" src={imageUrl} alt="Details" />}
                </div>
              )}
            </div>
          </div>
          {showSOPPanel && (
            <div className="m-3 side-panel side-panel-down">
              <div className="fw-bold fs-5 p-2">Select Option for SOP</div>
              <div className="main-block-report">
                <label>
                  <input type="radio" onChange={() => handleOptionChange("Ignore")} />
                  Ignore
                </label>
                <label>
                  <input type="radio" onChange={() => handleOptionChange("File Report")} />
                  File Report
                </label>
              </div>

              {selectedOption && (
                <div className="m-2 action p-2 ">
                  <div className="fw-bold fs-6">Action</div>
                  <div className="d-flex fs-7 align-items-center justify-content-around">
                    <div>Create a new work order request for File Report</div>
                    <button className="bttns btn fs-7" onClick={handlesop}>
                      Work Order
                    </button>
                  </div>
                </div>
              )}
              {sopSuccessful && (
                <Sop
                  closeSOP={closeSOP}
                  settab={() => {
                    setSopSuccessFul(false);
                  }}
                ></Sop>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Violation;

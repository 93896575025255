import React, { useState, useCallback, useContext } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { CommonServiceContext } from "../../config/commonContext";

const containerStyle = {
  width: "100%",
  height: "100vh",
};
const center = {
  lng: 51.5257,
  lat: 25.2845,
};
const zoom = 17;
const mapOptions = {
  disableDefaultUI: true,
  mapTypeId: "satellite",
};

function Gmap({ show3dView }) {
  const [map, setMap] = useState(null);
  const [infowindow, setInfoWindow] = useState(null);
  const [strokeColor, setStrokeColor] = useState("green");
  const { ahuincident } = useContext(CommonServiceContext);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDMlVAALBCx-2tliIzQKST8qFwIyCvIRc4",
  });

  const geoJsonData = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [51.526991633367004, 25.284010136373112],
        },
        properties: {
          name: "T07",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [51.5277, 25.2842],
        },
        properties: {
          name: "Park Hytt",
        },
      },
      {
        type: "Feature",
        properties: {
          name: "T07",
        },
        geometry: {
          coordinates: [
            [
              [51.52660965319575, 25.284285916814028],
              [51.526599514646165, 25.284022202967797],
              [51.526582508062546, 25.283980289122283],
              [51.52657822841604, 25.28380396844699],
              [51.52672254381308, 25.283632662016647],
              [51.526750481389705, 25.283598263872015],
              [51.52701279022739, 25.283687916203057],
              [51.52731280952088, 25.283772412823964],
              [51.52727885720826, 25.284219328541738],
              [51.52725129234094, 25.28424866190185],
              [51.52722372747502, 25.284267289178217],
              [51.52701798097531, 25.28428141640177],
              [51.52690882180799, 25.284209988594462],
              [51.52681975039038, 25.28433711068858],
              [51.52660965319575, 25.284285916814028],
            ],
          ],
          type: "Polygon",
        },
      },
      {
        type: "Feature",
        properties: {
          name: "Park Hytt",
        },
        geometry: {
          coordinates: [
            [
              [51.527278951410125, 25.284321813811815],
              [51.527344571563276, 25.28378714053501],
              [51.52807572030551, 25.284068107271807],
              [51.527944977630256, 25.28448683282761],
              [51.527600076772316, 25.2847253156661],
              [51.52740262453321, 25.28466046011998],
              [51.52746913804611, 25.284423869797532],
              [51.527278951410125, 25.284321813811815],
            ],
          ],
          type: "Polygon",
        },
      },
    ],
  };

  const onLoad = useCallback(
    (map) => {
      setMap(map);
      const dataLayer = new window.google.maps.Data();
      dataLayer.addGeoJson(geoJsonData);
      dataLayer.setMap(map);

      dataLayer.setStyle((feature) => {
        const featureName = feature.getProperty("name");
        let color = strokeColor;

        if (ahuincident && featureName === ahuincident.buildingName) {
          color = "red";
        } else if (featureName === "T07") {
          color = "red";
        } else {
          color = "green";
        }

        if (feature.getGeometry().getType() === "Polygon") {
          return {
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.35,
          };
        }
        return {};
      });

      dataLayer.addListener("click", (event) => {
        const name = event.feature.getProperty("name");

        let data = "";
        if (name === "T07") {
          data = "T07";
        }

        window.parent.postMessage(data, "*");

        const contentString = `
          <div style="
            width: 150px;
            background: hsla(219, 59%, 22%, 1);
            padding: 10px;
            border-radius: 8px;
            font-family: Arial, sans-serif;
            font-size: 12px;
            color: white;
          ">
            <div style="
              text-align: center;
              margin-bottom: 5px;
            ">
              ${name}
            </div>
            <div>
              <button id="launchBtn" style="
                width: 100%;
                background: hsla(219, 59%, 22%, 1);
                color: white;
                border: solid red;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;
              ">
                Launch Digital Twin
              </button>
            </div>
          </div>
        `;

        if (infowindow) {
          infowindow.close();
        }

        const newInfowindow = new window.google.maps.InfoWindow({
          content: contentString,
          position: event.latLng,
        });

        setInfoWindow(newInfowindow);
        newInfowindow.open(map);

        window.google.maps.event.addListenerOnce(newInfowindow, "domready", () => {
          document.getElementById("launchBtn").addEventListener("click", () => {
            if (name === "T07") {
              let data = "T07";
              window.parent.postMessage(data, "*");
              launchDigitalTwin();
            } else {
              let data = "";
              window.parent.postMessage(data, "*");
            }
          });
        });

        setTimeout(() => {
          newInfowindow.close();
        }, 5000);
      });

      // Close infowindow and recenter map on map click
      map.addListener("click", (event) => {
        if (infowindow) {
          infowindow.close();
        }
        map.panTo(center);
      });

      function launchDigitalTwin() {
        let googledata = { message: "Launch Digital Twin" };
        window.parent.postMessage(googledata, "*");
      }
    },
    [infowindow, strokeColor, ahuincident]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
    setInfoWindow(null);
  }, []);

  if (loadError) {
    return <div>Error loading map</div>;
  }

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom} options={mapOptions} onLoad={onLoad} onUnmount={onUnmount}>
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
}

export default Gmap;

import React, { useContext, useEffect, useState } from "react";
import LineChart from "../echarts/coolingChart";
import { useNavigate } from "react-router-dom";
import subsystems from "../../assets/subsystem.json";
import TempChart from "../echarts/tempChart";
function SubAnalytics() {
  const nav = useNavigate();
  const [openTable, setOpenTable] = useState();
  const [showTable, setShowTable] = useState();
  const toggleTable = (tableName) => {
    setShowTable(true);
    setOpenTable(tableName);
  };

  const weatherData = [
    {
      atmosphericPressure: 930,
      dewPointTemperature: 8,
      rainfall: 0,
      relativeHumidity: 28,
      windChill: 4,
      activePower: 700,
      reactivePower: 183,
    },
    {
      atmosphericPressure: 924,
      dewPointTemperature: 7,
      rainfall: 0,
      relativeHumidity: 15,
      windChill: 4,
      activePower: 348,
      reactivePower: 84,
    },
    {
      atmosphericPressure: 920,
      dewPointTemperature: 3,
      rainfall: 9,
      relativeHumidity: 9,
      windChill: 5,
      activePower: 933,
      reactivePower: 105,
    },
    {
      atmosphericPressure: 926,
      dewPointTemperature: 4,
      rainfall: 0,
      relativeHumidity: 37,
      windChill: 5,
      activePower: 605,
      reactivePower: 110,
    },
  ];
  const correlationData = [
    {
      name: "Atmospheric Pressure",
      activePower: 0.01,
      reactivePower: 0.03,
    },
    {
      name: "Dew Point Temperature",
      activePower: 0.81,
      reactivePower: 0.63,
    },
    {
      name: "Rainfall",
      activePower: 0.07,
      reactivePower: 0.02,
    },
    {
      name: "Relative Humidity",
      activePower: 0,
      reactivePower: 0.01,
    },
    {
      name: "Wind Chill",
      activePower: 0.81,
      reactivePower: 0.56,
    },
  ];
  return (
    <div>
      <div>
        <button className="bttn ms-2 mt-2" onClick={() => nav(-1)}>
          Back
        </button>
      </div>
      <div>
        <div className="row">
          <div className="col p-2">
            <div className="col-5 mt-2">
              {subsystems?.bms.analytics.coolingFactor.map((freq, i) => {
                return (
                  <div key={i} className="col-6 mt-2">
                    <LineChart data={freq} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col p-2">
            <div className="col-5 mt-2">
              {subsystems?.bms.analytics.pwrEfficiency.map((freq, i) => {
                return (
                  <div key={i} className="col-6 mt-2">
                    <LineChart data={freq} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col p-2">
            <div className="col-5 mt-2">
              {subsystems?.bms.analytics.tempPrediction.map((freq, i) => {
                return (
                  <div key={i} className="col-6 mt-2">
                    <TempChart data={freq} />
                  </div>
                );
              })}
            </div>
            <div className="dataTable float-right">
              <button className="bttn" onClick={() => toggleTable("temp")}>
                View
              </button>
            </div>
          </div>
          <div className="col p-2">
            <div className="col-5 mt-2">
              {subsystems?.bms.analytics.windPrediction.map((freq, i) => {
                return (
                  <div key={i} className="col-6 mt-2">
                    <TempChart data={freq} />
                  </div>
                );
              })}
            </div>
            <div className="dataTable float-right">
              <button className="bttn" onClick={() => toggleTable("wind")}>
                View
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col p-2">
            <div className="col-5 mt-2">
              {subsystems?.bms.analytics.tempSvm.map((freq, i) => {
                return (
                  <div key={i} className="col-6 mt-2">
                    <LineChart data={freq} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {showTable && openTable === "temp" && (
        <div>
          <div className="modal d-block modal-md z-1 " tabIndex="1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header border-0 justify-content-between">
                  <div>Weather Prediction</div>{" "}
                  <div>
                    <button className="bttn" onClick={() => setShowTable(false)}>
                      Back
                    </button>
                  </div>
                </div>
                <div className="modal-body px-2 m-0">
                  <div className="modal-body px-2 m-0">
                    <div>
                      <table className="dataTable">
                        <thead>
                          <tr>
                            <th>Atmospheric Pressure</th>
                            <th>Dew Point Temperature</th>
                            <th>Rainfall</th>
                            <th>Relative Humidity</th>
                            <th>Wind Chill</th>
                            <th>Active Power</th>
                            <th>Reactive Power</th>
                          </tr>
                        </thead>
                        <tbody>
                          {weatherData.map((row, index) => (
                            <tr key={index}>
                              <td>{row.atmosphericPressure}</td>
                              <td>{row.dewPointTemperature}</td>
                              <td>{row.rainfall}</td>
                              <td>{row.relativeHumidity}</td>
                              <td>{row.windChill}</td>
                              <td>{row.activePower}</td>
                              <td>{row.reactivePower}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showTable && openTable === "wind" && (
        <div>
          <div className="modal d-block modal-md z-1" tabIndex="1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header border-0 justify-content-between">
                  <div>Correlation</div>{" "}
                  <div>
                    <button className="bttn" onClick={() => setShowTable(false)}>
                      Back
                    </button>
                  </div>
                </div>
                <div className="modal-body px-2 m-0">
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Active Power</th>
                          <th>Reactive Power</th>
                        </tr>
                      </thead>
                      <tbody>
                        {correlationData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.name}</td>
                            <td>{row.activePower}</td>
                            <td>{row.reactivePower}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubAnalytics;

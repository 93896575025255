import React, { useState, useEffect } from "react";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";

function ProgressTracking(props) {
  const { title, started, reached, closed } = props;
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (started) {
      setCurrentStep(2);
      console.log("Step 1 activated:", started);
    } else {
      setCurrentStep(1);
      console.log("Step reset:", started);
    }
  }, [started]);
  useEffect(() => {
    if (reached) {
      setCurrentStep(3);
      console.log("Step 2 activated:", reached);
    } else {
      setCurrentStep(1);
      console.log("Step 2 reset:", reached);
    }
  }, [reached]);

  useEffect(() => {
    if (closed) {
      setCurrentStep(4);
      console.log("Step 2 activated:", closed);
    } else {
      setCurrentStep(1);
      console.log("Step 2 reset:", closed);
    }
  }, [closed]);

  const steps = [
    {
      label: "Created",
      name: "step 1",
    },
    {
      label: "Notified",
      name: "step 2",
    },
    {
      label: "Responded/Acknowledged",
      name: "step 3",
    },
    {
      label: "Completed",
      name: "step 4",
    },
    {
      label: "Closed",
      name: "step 5",
    },
  ];

  return (
    <div className="m-2 fc-white row align-items-center">
      <div className="col-1">{title}</div>
      <div className="col-11">
        <StepProgressBar key={currentStep} startingStep={currentStep} steps={steps} />
      </div>
    </div>
  );
}

export default ProgressTracking;

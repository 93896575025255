import React from "react";
import LineChartStatic from "../echarts/lineChartStatic";
import subsystems from "../../assets/subsystem.json";

function Delta() {
  return (
    <div className="chartContainer">
      {subsystems.bms.delta.map((dt) => {
        return (
          <div className="chart3">
            <LineChartStatic data={dt}></LineChartStatic>
          </div>
        );
      })}
    </div>
  );
}

export default Delta;

import React, { useState, useRef, useContext, useEffect } from "react";
import { gsap } from "gsap";
import { ChartServiceContext } from "./chartContext";

export const ModelServiceContext = React.createContext();

export const ModelServiceProvider = ({ children }) => {
  const [showDashboardCards, setShowDashboardCards] = useState(true);
  const [selectedMeshId, setSelectedMeshId] = useState("");
  const [isDetailView, setIsDetailView] = useState(true); //
  const [light, setLight] = useState(true);
  const [cupMaterial1, setCupMaterial1] = useState(null);
  const [cupMaterial2, setCupMaterial2] = useState(null);
  const [cupMaterial3, setCupMaterial3] = useState(null);
  const [cupMaterial4, setCupMaterial4] = useState(null);
  const [cupMaterial5, setCupMaterial5] = useState(null);
  const [cupMaterial6, setCupMaterial6] = useState(null);
  const [cupMaterial7, setCupMaterial7] = useState(null);
  const [cupMaterial8, setCupMaterial8] = useState(null);

  const [ahuMaterial1, setAhuMaterial1] = useState(null);
  const [ahuMaterial2, setAhuMaterial2] = useState(null);
  const [ahuMaterial3, setAhuMaterial3] = useState(null);
  const [ahuIncident, setAhuIncident] = useState(false);

  const [issuesId, setIssuesId] = useState();

  const controls = useRef();
  const [object, setObject] = useState(null);

  const chartContext = useContext(ChartServiceContext);

  useEffect(() => {
    if (object) {
      setCupMaterial1(object.getObjectByName("Mesh171_2"));
      setCupMaterial2(object.getObjectByName("AllModern_Aloisio_Sofa07001"));
      setCupMaterial3(object.getObjectByName("pasted__pCube241"));
      setCupMaterial4(object.getObjectByName("pasted__pasted__polySurface231"));
      setCupMaterial5(object.getObjectByName("Mesh10006"));
      setCupMaterial6(object.getObjectByName("Mesh10006"));
      setAhuMaterial1(object.getObjectByName("data_sit_02_113"));
      setAhuMaterial2(object.getObjectByName("data_sit_02_31"));
      setAhuMaterial3(object.getObjectByName("data_sit_02_31"));
    }
  }, [object]);

  useEffect(() => {
    setAhuIncident(chartContext.ahuIncident);
  }, [chartContext.ahuIncident]);

  useEffect(() => {
    if (object) {
      let timeoutId;
      setAhuMaterial1(object.getObjectByName("data_sit_02_113"));
      setAhuMaterial2(object.getObjectByName("data_sit_02_31"));
      setAhuMaterial3(object.getObjectByName("data_sit_02_31"));
      if (ahuIncident && ahuMaterial1 && ahuMaterial2 && ahuMaterial3) {
        let blink = true;
        function blinkColors() {
          if (blink) {
            // ahuMaterial1?.material.color.setHex(parseInt("#f96161".substring(1), 16)); ebb514
            ahuMaterial1?.material.color.setHex(parseInt("#fbc02d".substring(1), 16));
            ahuMaterial2.material.color.setHex(parseInt("#808080".substring(1), 16));
            ahuMaterial3.material.color.setHex(parseInt("#808080".substring(1), 16));
          } else {
            ahuMaterial1.material.color.setHex(parseInt("#ffffff".substring(1), 16));
            ahuMaterial2.material.color.setHex(parseInt("#ffffff".substring(1), 16));
            ahuMaterial3.material.color.setHex(parseInt("#ffffff".substring(1), 16));
          }
          blink = !blink;
          timeoutId = setTimeout(blinkColors, 500);
        }
        blinkColors();
      } else if (ahuMaterial1 && ahuMaterial2 && ahuMaterial3) {
        recenter();
        ahuMaterial1.material.color.setHex(parseInt("#ffffff".substring(1), 16));
        ahuMaterial2.material.color.setHex(parseInt("#ffffff".substring(1), 16));
        ahuMaterial3.material.color.setHex(parseInt("#ffffff".substring(1), 16));
      }
      return () => clearTimeout(timeoutId);
    }
  }, [ahuIncident, object]);

  const navigate = (x, y, z, meshId, label, id) => {
    if (controls?.current) {
      const currentPosition = controls.current?.object.position.clone();
      gsap.to(currentPosition, {
        duration: 3,
        x: x,
        y: y,
        z: z,
        onUpdate: () => {
          controls.current?.object.position.copy(currentPosition);
        },
      });
      if (label === "detailView") {
        setIsDetailView(true);
      } else {
        setIsDetailView(false);
      }
      setShowDashboardCards(false);
      setSelectedMeshId(meshId);
      if (id) {
        setIssuesId(id);
      }
    }
    // openMeshDetails(id, x, y, z);
  };
  function recenter() {
    const currentPosition = controls?.current?.object.position.clone();
    gsap.to(currentPosition, {
      duration: 3,
      x: 60,
      y: 55.9,
      z: 68.85,
      onUpdate: () => {
        controls.current?.object.position.copy(currentPosition);
      },
    });
    setShowDashboardCards(true);
  }

  const openMeshDetails = (id, x, y, z) => {
    setSelectedMeshId(id);

    switch (id) {
      case "data_sit_02_41":
        setCupMaterial1(object.getObjectByName("data_sit_02_14"));
        setCupMaterial2(object.getObjectByName("data_sit_02_64"));
        setCupMaterial3(object.getObjectByName("data_sit_02_66"));
        if (cupMaterial1 && cupMaterial2 && cupMaterial3) {
          if (light === true) {
            cupMaterial1.material.color.setHex(parseInt("#000000".substring(1), 16));
            cupMaterial2.material.color.setHex(parseInt("#808080".substring(1), 16));
            cupMaterial3.material.color.setHex(parseInt("#808080".substring(1), 16));
            setLight(false);
          } else {
            cupMaterial1.material.color.setHex(parseInt("#ffffff".substring(1), 16));
            cupMaterial2.material.color.setHex(parseInt("#ffffff".substring(1), 16));
            cupMaterial3.material.color.setHex(parseInt("#ffffff".substring(1), 16));

            setLight(true);
          }
        }
        setShowDashboardCards(true);
        break;
      case "data_sit_02_32":
        setCupMaterial4(object.getObjectByName("data_sit_02_24"));
        setCupMaterial5(object.getObjectByName("data_sit_02_8"));
        setCupMaterial3(object.getObjectByName("data_sit_02_7"));
        if (cupMaterial4 && cupMaterial2 && cupMaterial3) {
          if (light === true) {
            cupMaterial1.material.color.setHex(parseInt("#000000".substring(1), 16));
            setLight(false);
          } else {
            cupMaterial1.material.color.setHex(parseInt("#ffffff".substring(1), 16));

            setLight(true);
          }
        }
        navigate(x, y, z, id, "detailView");
        setShowDashboardCards(true);
        break;
      case "data_sit_02_31":
        // setAhuMaterial1(object.getObjectByName("data_sit_02_113"));
        // setAhuMaterial2(object.getObjectByName("data_sit_02_31"));
        // setAhuMaterial3(object.getObjectByName("data_sit_02_31"));
        // if (ahuMaterial1 && ahuMaterial2 && ahuMaterial3) {
        //   let blink = true;
        //   function blinkColors() {
        //     if (blink) {
        //       ahuMaterial1.material.color.setHex(parseInt("#f96161".substring(1), 16));
        //       ahuMaterial2.material.color.setHex(parseInt("#808080".substring(1), 16));
        //       ahuMaterial3.material.color.setHex(parseInt("#808080".substring(1), 16));
        //       ahuMaterial1.material.flatShading = true;
        //       //   cupMaterial6.material.color.setHex("#ab221d");
        //       //   cupMaterial7.material.color.setHex(0x808080);
        //       //   cupMaterial8.material.color.setHex(0x808080);
        //     } else {
        //       ahuMaterial1.material.color.setHex(parseInt("#ffffff".substring(1), 16));
        //       ahuMaterial2.material.color.setHex(parseInt("#ffffff".substring(1), 16));
        //       ahuMaterial3.material.color.setHex(parseInt("#ffffff".substring(1), 16));
        //     }
        //     blink = !blink;
        //     setTimeout(blinkColors, 500);
        //   }
        //   blinkColors();
        // } else {
        //   console.error("Materials not found for data_sit_02_131");
        // }
        navigate(x, y, z, id, "detailView");
        setShowDashboardCards(true);
        break;
      case "data_sit_02_131":
        setCupMaterial6(object.getObjectByName("data_sit_02_131"));
        setCupMaterial7(object.getObjectByName("data_sit_02_132"));
        setCupMaterial8(object.getObjectByName("data_sit_02_133"));
        if (cupMaterial6 && cupMaterial7 && cupMaterial8) {
          //   let blink = true;
          //   function blinkColors() {
          // if (blink) {
          cupMaterial6.material.color.setHex(parseInt("#ab221d".substring(1), 16));
          cupMaterial7.material.color.setHex(parseInt("#808080".substring(1), 16));
          cupMaterial8.material.color.setHex(parseInt("#808080".substring(1), 16));
          //   cupMaterial6.material.color.setHex("#ab221d");
          //   cupMaterial7.material.color.setHex(0x808080);
          //   cupMaterial8.material.color.setHex(0x808080);
          // } else {
          //   cupMaterial6.material.color.setHex(0x555555);
          //   cupMaterial7.material.color.setHex(0x404040);
          //   cupMaterial8.material.color.setHex(0x404040);
          // }
          // blink = !blink;
          // setTimeout(blinkColors, 500);
          //   }
          //   blinkColors();
          // } else {
          //   console.error("Materials not found for data_sit_02_131");
        }
        navigate(x, y, z, id, "detailView");
        setShowDashboardCards(true);
        break;
      default:
        setShowDashboardCards(false);
        navigate(x, y, z, id, "detailView");
        break;
    }
  };

  return (
    <ModelServiceContext.Provider
      value={{
        showDashboardCards,
        selectedMeshId,
        ahuIncident,
        setAhuIncident,
        setSelectedMeshId,
        setShowDashboardCards,
        isDetailView,
        controls,
        object,
        setIsDetailView,
        setIssuesId,
        issuesId,
        setObject,
        navigate,
        recenter,
        openMeshDetails,
      }}
    >
      {children}
    </ModelServiceContext.Provider>
  );
};

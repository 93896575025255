import React, { useContext, useEffect, useState } from "react";
import { CommonServiceContext } from "../config/commonContext";
import { useLocation, useNavigate } from "react-router-dom";
import { ChartServiceContext } from "../config/chartContext";

const Iframe = ({ closeIframe, active, show3dView, label, visioMaps }) => {
  const [Tab, setTab] = useState("2D");
  // const [visioMap, setVisioMaps] = useState(false);
  const commonContext = useContext(CommonServiceContext);

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);

  // useEffect(() => {
  //   const visioParam = params.get("visio");
  //   if (visioParam === "true") {
  //     setVisioMaps(true);
  //   } else {
  //     setVisioMaps(false);
  //   }
  // }, []);
  useEffect(() => {
    if (active) setTab(active);
  }, [active]);

  const chartContext = useContext(ChartServiceContext);

  const navigate = useNavigate();

  return (
    <div>
      {label === "traffic" && (
        <div className="p-2">
          <div className="tabs">
            <div className="tabOptions">
              <div>
                <button onClick={() => setTab("3D")} className={`tabBtns button-if ${Tab === "3D" ? "active" : ""}`}>
                  Live Traffic
                </button>
              </div>
              <div>
                <button onClick={() => setTab("2D")} className={`tabBtns button-if ${Tab === "2D" ? "active" : ""}`}>
                  Scenario Analysis
                </button>
              </div>

              <div>
                <button onClick={() => setTab("~")} className={`tabBtns  button-if ${Tab === "~" ? "active" : ""}`}>
                  Congestion Hotspot
                </button>
              </div>
              <div className="minimize mt-1">
                <img src="/minimize-s.png" className="mini-traf" onClick={closeIframe}></img>
              </div>
            </div>
          </div>
          <div>
            {Tab === "2D" && <iframe className="iframeMaxHeight mt-0" src={commonContext.trafficIframeURL} title="traffic"></iframe>}
            {Tab === "3D" && <iframe className="iframeMaxHeight mt-0" src={commonContext.traffic3DIframeURL} title="traffic"></iframe>}
            {Tab === "~" && <iframe className="iframeMaxHeight mt-0" src={commonContext.trafficdeckglIframeURL} title="traffic"></iframe>}
          </div>
        </div>
      )}
      {label === "bms" && (
        <div className="frameWindow p-2">
          <div className="minimize mb-4">
            <img src="/minimize-s.png" className="mini-traf" onClick={closeIframe}></img>
          </div>
          <div>
            {!show3dView && (
              <iframe className="iframeMaxHeight" src={`${visioMaps ? commonContext.visioWebURL : commonContext.gmapURL}`} title="visioWeb"></iframe>
            )}
            {show3dView && <iframe className="iframeMaxHeight" src={commonContext.modelURL} title="3d Model"></iframe>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Iframe;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import Variablepie from "highcharts/modules/variable-pie";
import { useState } from "react";
import { CommonServiceContext } from "../../config/commonContext";
import { ChartServiceContext } from "../../config/chartContext";
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
Variablepie(Highcharts);

function DonutChart(props) {
  const { data, filter } = props;
  const [chartData, setChartData] = useState();
  const [chartType, setchartType] = useState("pie");

  function handlechartType(event) {
    setchartType(event.target.value);
  }
  const commonContext = useContext(CommonServiceContext);
  const chartContext = useContext(ChartServiceContext);

  async function getBmsAlarmsData() {
    let dt = await commonContext.getBmsAlarmsDataCount(data.type, filter[0], filter[1], filter[2], null, data.order, data.limit ?? data.limit);
    if (dt) {
      setChartData(dt.data);
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getBmsAlarmsData();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [filter[0], filter[1], filter[2]]);
  useEffect(() => {
    const chart = Highcharts.chart(data.id, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: chartType,
        marginTop: 50,
        marginRight: 35,
        backgroundColor: "hsla(202, 87%, 53%, 0.16)",
      },
      title: {
        text: data.title,
        style: {
          fontSize: "12px",
          color: "#fff",
        },
        floating: true,
        align: "left",
        x: 50,
        y: 10,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      legend: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      pane: {
        center: ["50%", "65%"],
        size: "100%",

        background: {
          innerRadius: "50%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      plotOptions: {
        pie: {
          shadow: true,
          center: ["50%", "50%"],
          circumference: 180,
        },
      },
      colors: [
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [0, "hsla(158, 63%, 69%, 1)"],
            [1, "hsla(205, 65%, 60%, 1)"],
          ],
        },
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [0, "hsla(241, 68%, 61%, 1)"],
            [1, "hsla(274, 68%, 61%, 1)"],
          ],
        },
        {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [1, "hsla(194, 80%, 61%, 1)"],
            [0, "hsla(266, 68%, 65%, 1)"],
          ],
        },
      ],

      xAxis:
        chartType === "column"
          ? {
              labels: {
                style: {
                  color: "#fff",
                },
              },
            }
          : {},
      yAxis:
        chartType === "column"
          ? {
              labels: {
                style: {
                  color: "#fff",
                },
              },
            }
          : {},
      series: [
        {
          name: "Alarm",
          colorByPoint: true,
          innerSize: "70%",

          data: chartData?.map((item, i) => ({
            name: item[0],

            y: item[1],
          })),

          point: {
            events: {
              click: function (params) {
                chartContext.handleChartClick(params, data, filter);
              },
            },
          },
        },
      ],
    });
  }, [data.id, chartData, chartType]);

  return (
    <div style={{ width: "46vw", height: "60vh", position: "relative" }}>
      <div style={{ position: "absolute", top: 10, right: 10, zIndex: 100 }}>
        <select className="dropdown" value={chartType} onChange={handlechartType}>
          <option value="pie" className="opt">
            Pie
          </option>
          <option value="column" className="opt">
            Bar
          </option>
        </select>
      </div>
      <div id={data.id} style={{ height: "100%" }}></div>
    </div>
  );
}

export default DonutChart;

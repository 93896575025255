import React from "react";
import LineChartForecast from "../echarts/linechart-forecast";
import subsystems from "../../assets/subsystem.json";

function Forecast({ setforecast }) {
  return (
    <div>
      <div className="modal d-block modal-xl" tabIndex="-1" role="dialog" data-bs-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between align-items-center">
              <div className="modal-title fs-5">T07 Building Alarm Forecast Dashboard</div>
              <div className="w-10">
                <button type="button" className="close bttn btn" onClick={setforecast} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-9">
                  {subsystems.bms.forecast.charts.map((chart) => {
                    return (
                      <div>
                        <LineChartForecast data={chart}></LineChartForecast>
                      </div>
                    );
                  })}
                </div>
                <div className="col-3">
                  <div className="performance-matrics p-2">
                    <div className="m-1 text-center">Performance Metrics for Forecasting Model</div>
                    <div className="py-2 ps-3">
                      {subsystems.bms.forecast.matrics.map((t) => {
                        return (
                          <div className="fs-7" key={t.value}>
                            {t.title} : <span className="fc-green">{t.value}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forecast;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { CommonServiceContext } from "../../config/commonContext";

function LineChartForecast(props) {
  const { buildingId, data } = props;
  const [chartData, setChartData] = useState([]);
  const [option, setOption] = useState({});
  const [xlabel, setXlabel] = useState([]);
  const [seriesData1, setSeriesData1] = useState();
  const [seriesData2, setSeriesData2] = useState();

  const commonContext = useContext(CommonServiceContext);

  useEffect(() => {
    if (data.dataType === "compare") {
      setSeriesData1(data.data.data1);
      setSeriesData2(data.data.data2);
      setXlabel(data.xData);
    } else {
      setChartData(data.data);
      setXlabel(data.xData);
    }
  }, [buildingId]);
  useEffect(() => {
    const newOption = {
      title: {
        text: data?.title,
        textStyle: {
          color: "#ffffff",
          fontSize: 10,
        },
        textAlign: "left",
        left: "3%",
        top: "1%",
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: xlabel,
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
        splitLine: {
          show: false,
        },
      },
      grid: {
        top: 40,
        left: 40,
        right: 15,
        height: "60%",
      },
      series:
        data.dataType !== "compare"
          ? [
              {
                name: "Actual Alarms",
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.redOrange[0].color,
                },
                data: chartData,
              },
            ]
          : [
              {
                name: "Actual Alarms",
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.redOrange[0].color,
                },
                data: seriesData1,
              },
              {
                name: "Predicted Alarms",
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.yellowShade[0].color,
                },
                data: seriesData2,
              },
            ],
    };
    setOption(newOption);
  }, [seriesData1, seriesData2, xlabel, chartData]);

  return <ReactECharts option={option} className="chart-container m-2" style={{ height: "35vh", width: "100%" }} />;
}

export default LineChartForecast;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import Variablepie from "highcharts/modules/variable-pie";
import { useState } from "react";
import { CommonServiceContext } from "../../config/commonContext";
import { ChartServiceContext } from "../../config/chartContext";
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
Variablepie(Highcharts);

function BarHighChart(props) {
  const { data, filter, click } = props;
  const [chartData, setChartData] = useState();
  const [chartType, setchartType] = useState("column");
  function handlechartType(event) {
    setchartType(event.target.value);
  }

  const commonContext = useContext(CommonServiceContext);
  const chartContext = useContext(ChartServiceContext);

  async function getBmsAlarmsData() {
    let dt = await commonContext.getBmsAlarmsDataCount(data.type, filter[0], filter[1], filter[2], null, data.order, data.limit ?? data.limit);
    if (dt) {
      setChartData(dt.data);
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getBmsAlarmsData();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [filter[0], filter[1], filter[2]]);

  useEffect(() => {
    const totalCounts = chartData?.reduce((total, d) => total + d[1], 0);
    const chart = Highcharts.chart(data.id, {
      chart: {
        type: chartType,
        marginTop: 50,
        marginRight: 35,
        backgroundColor: "hsla(202, 87%, 53%, 0.16)",
        // backgroundColor: "hsla(237, 61%, 60%, 0.16)",
        options3d: {
          enabled: true,
          alpha: 15,
          beta: 15,
          depth: 50,
          viewDistance: 25,
        },
      },
      title: {
        text: data.title,
        style: {
          fontSize: "12px",
          color: "#fff",
        },
        floating: true,
        align: "left",
        x: 50,
        y: 10,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        title: {
          text: data.yaxis ?? "Count",
          style: {
            fontSize: "12px",
            color: "#fff",
          },
          x: 0,
          y: 5,
        },
        labels: {
          x: -10,
          y: 5,
          style: { color: "white", fontSize: 10 },
        },
      },
      xAxis: {
        type: "category",
        title: {
          text: data.column,
          style: {
            fontSize: "12px",
            color: "#fff",
          },
          x: -15,
          y: 0,
        },
        labels: {
          x: 10,
          y: 20,
          style: { color: "#FFFFFF", fontSize: 10 },
          formatter: function () {
            let label = this.value;
            const maxLength = 10;
            if (label.length > maxLength) {
              label = label.substring(0, maxLength) + "...";
            }
            return label;
          },
        },
      },
      tooltip: {
        headerFormat: `<div>${data.column}: {point.key}</div>`,
        pointFormat: `<div>Count: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        bar: {
          x: 10,
          y: 0,
          dataLabels: {
            enabled: true,
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            height: 12,
            width: 13,
            symbolSize: 10,
            symbolStrokeWidth: 1,
            symbolX: 7,
            symbolY: 6,
          },
        },
      },
      series: [
        {
          name: data.column,
          colorByPoint: true,
          data: chartData?.map((item, index) => ({
            name: item[0],
            y: data.value === "Probability" ? (item[1] / totalCounts) * 100 : item[1],
          })),
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, "#003399"],
              [1, "#ff66AA"],
            ],
          },
          point: {
            events: {
              click: function (params) {
                chartContext.handleChartClick(params.point.name, data, filter);
              },
            },
          },
        },
      ],
    });

    if (data.advId) {
      const gradientId = "linearGradientButton";
      const defs = chart.renderer.defs;
      defs.element.innerHTML += `
        <linearGradient id="${gradientId}" x1="0%" y1="0%" x2="180%" y2="180%">
          <stop offset="0%" style="stop-color: hsla(197, 85.1%, 52.5%, 1); stop-opacity: 1" />
          <stop offset="50%" style="stop-color: hsla(210, 84.1%, 42.0%, 1); stop-opacity: 1" />
        </linearGradient>
      `;
      chart.renderer
        .button(
          data.button,
          chart.plotWidth + chart.plotLeft - 100,
          chart.plotTop - 40,
          function () {
            click(data.advId);
          },
          {
            fill: `url(#${gradientId})`,
            style: {
              color: "white",
              fontWeight: "bold",
            },
            states: {
              hover: {
                // fill: "#135296",
                fill: `url(#${gradientId})`,
              },
            },
          }
        )
        .add();
    }
  }, [data.id, data.button, chartData, chartType]);

  return (
    <div style={{ width: "46vw", height: "60vh", position: "relative" }}>
      <div style={{ position: "absolute", top: 10, right: 10, zIndex: 100 }}>
        <select className="dropdown" value={chartType} onChange={handlechartType}>
          <option value="pie" className="opt">
            Pie
          </option>
          <option value="column" className="opt">
            Bar
          </option>
        </select>
      </div>
      <div id={data.id} style={{ height: "100%" }}></div>
    </div>
  );
}

export default BarHighChart;

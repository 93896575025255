import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { HttpServiceProvider } from "./config/httpContext";
import { CommonServiceProvider } from "./config/commonContext";
import { ModelServiceProvider } from "./config/modelContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HttpServiceProvider>
      <CommonServiceProvider>
        <ModelServiceProvider>
          <App />
        </ModelServiceProvider>
      </CommonServiceProvider>
    </HttpServiceProvider>
  </React.StrictMode>
);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import LineHighChart from "../highcharts/lineHighCharts";
import * as $ from "jquery";
import Ahutabs from "../common/dashboardtabs";
import subsystems from "../../assets/subsystem.json";
import { HttpServiceContext } from "../../config/httpContext";
import { CommonServiceContext } from "../../config/commonContext";
import AdvisoryModel from "../common/advisoryModel";

function AhuAnalytics() {
  const [activeTab, setActiveTab] = useState("analytics");
  const [showAdv, setShowAdv] = useState(false);
  const [showIssue, setShowIssue] = useState("");
  const [data, setData] = useState();
  const [showWindow, setShowWindow] = useState(false);
  const [showframe, setShowframe] = useState(false);

  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);

  useEffect(() => {
    async function getData() {
      let mydata = await httpContext.getData();
      if (mydata) {
        setData(mydata.data);
      }
    }
    getData();
  }, []);

  const handleSop = (event, id) => {
    setShowAdv(true);
    const advEntry = limitedData.find((adv) => adv.id === id);
    if (advEntry && advEntry !== "ahu") {
      setShowIssue(advEntry);
    }
  };
  function openWindow() {
    setShowWindow(true);
  }
  function closeWindow() {
    setShowWindow(false);
  }
  function openIframe() {
    setShowframe(true);
  }
  function closeIframe() {
    setShowframe(false);
  }

  const onClickBack = () => {
    commonContext.setShowAssetAnalytics(false);
  };

  function sopTab() {
    setActiveTab("Incidents");
    setShowAdv(false);
  }

  const limitedData = data && data.advisory && Array.isArray(data.advisory.bms) ? data.advisory.bms.slice(0, 1) : [];

  useEffect(() => {
    $("#RAT").val(commonContext.inputFieldValue);
  }, [commonContext.inputFieldValue]);

  const chartStyle = {
    height: "29vh",
    width: "100%",
  };
  return (
    <div className="h-100vh">
      <div className={`background-data ${commonContext.openModel ? "disabled" : ""}`}>
        {!commonContext.showAnalytics && !showframe && !showWindow && (
          <div>
            <div className="row">
              <div className="col-7 d-flex">
                <div className="btn-padding w-10">
                  <button className="btn back-btn bttn m-0" onClick={onClickBack}>
                    Back
                  </button>
                </div>
              </div>
              <div className="col-5">
                <div className="fc-1">Asset : 1-T2-T07-13-01.01-AC-ACON-AHU-0001</div>
              </div>
            </div>
            <div className="row ">
              <div className="col-7">
                <th className="  mini col-6">
                  <img src="/maximize.png" onClick={openIframe} alt="" className="maximize"></img>
                </th>
                <br />
                <div className="mx-2 ahu">
                  <img className="ahu-diagram" src={require("../../assets//icons/ahu_line_diagram.png")} alt="" />
                  <div className="exhaust">Exhaust Air 32°C</div>
                  <div className="fresh">Fresh Air 24°C</div>
                  <div
                    className="rat"
                    onClick={() => {
                      setActiveTab("advisory");
                    }}
                  >
                    RAT
                    <span>
                      <input
                        className={`cursor-pointer ${commonContext.ahuIncident ? "random" : "random-none"}`}
                        id="RAT"
                        readOnly={true}
                        onClick={() => {
                          setActiveTab("advisory");
                        }}
                        value={commonContext.inputFieldValue}
                      />
                    </span>
                  </div>
                  <div className="water-in">Chilled Water In 10°C</div>
                  <div className="water-out">Hot Water Out 26°C</div>
                  <div className="supply-air">Supply Air 24°C</div>
                  <div className="air-condition">Air Conditional Zone</div>
                </div>
                <div className="mytabs">
                  <div className="tabOptions">
                    <div>
                      <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                        Incidents
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                        Analytics
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                        Advisory
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setActiveTab("realtime")} className={`tabBtns ${activeTab === "realtime" ? "active" : null}`}>
                        Realtime
                      </button>
                    </div>
                    <div>
                      <th className=" mini col-6">
                        <img src="/maximize.png" className="maximize" alt="" onClick={openWindow}></img>
                      </th>
                    </div>
                  </div>
                  <div className="tabContent">
                    {activeTab === "analytics" && (
                      <div className="row">
                        {subsystems.bms.ahuanalytics.leftpanel.map((cd) => (
                          <div className="col-4">
                            <LineHighChart data={cd} style={chartStyle}></LineHighChart>
                          </div>
                        ))}
                      </div>
                    )}
                    {activeTab === "advisory" && (
                      <div className="p-1 ">
                        <table className="d-block text-light table px-0" style={{ height: "29vh" }}>
                          <tr className="d-flex justify-content-between  align-items-baseline">
                            <th className="col-11 fs-6 px-4">Observation</th>
                            <th className="col-auto px-0 fs-6">Action</th>
                          </tr>
                          {limitedData.map(
                            (adv, i) =>
                              commonContext.ahuIncident && (
                                <tr key={adv.id} className="d-flex justify-content-between px-4">
                                  <td className="col-10 text-justify py-1 border-0 text fs-7">
                                    {adv.observation}
                                    <span className="span-color">{adv?.assetId}</span>
                                  </td>
                                  <td>
                                    <button
                                      key={adv.id}
                                      className=" col-auto myBtns text-white cursor-pointer"
                                      onClick={(event) => handleSop(event, adv.id)}
                                    >
                                      ACT
                                    </button>
                                  </td>
                                </tr>
                              )
                          )}
                        </table>
                      </div>
                    )}
                    {activeTab === "Incidents" && (
                      <div>
                        <iframe src={httpContext.sop} title="Sop" style={{ height: "30vh", width: "100%" }}></iframe>
                      </div>
                    )}
                    {activeTab === "realtime" && (
                      <div className="ahuAnalytics p-1" style={{ height: "30vh" }}>
                        {subsystems.bms.ahuanalytics.realTimeData.description.map((item, index) => (
                          <div key={index} className="text-light fs-7 text-center m-2 realtimeCard p-2">
                            <div className="fs-6 fw-bold  ">{item.title}</div>
                            <div className="row">
                              {item.details.map((val, idx) => (
                                <div key={idx} className="col-6 mt-2">
                                  <div>
                                    <span className="fw-bold">{val.title} : </span>
                                    <span>
                                      &nbsp;
                                      {val.value ? (
                                        val.value === "random" ? (
                                          <input
                                            className={`cursor-pointer ${commonContext.ahuIncident ? "random" : "random-none"}`}
                                            id="RAT"
                                            readOnly
                                            value={commonContext.inputFieldValue}
                                          />
                                        ) : (
                                          val.value
                                        )
                                      ) : null}
                                    </span>
                                  </div>

                                  {val.details && val.details.length > 0 && (
                                    <div className="nested-details d-flex flex-row mx-2 ">
                                      {val.details.map((nestedVal, nestedIdx) => (
                                        <div key={nestedIdx} className="d-flex flex-column col-6 ">
                                          <div className="d-flex flex-column mx-2 ">
                                            <div className="fw-bold">{nestedVal.title}</div>
                                            <div>
                                              &nbsp;
                                              {nestedVal.value ? (
                                                nestedVal.value === "random" ? (
                                                  <input
                                                    className={`cursor-pointer ${commonContext.ahuIncident ? "random" : "random-none"}`}
                                                    id="RAT"
                                                    readOnly
                                                    value={commonContext.inputFieldValue}
                                                  />
                                                ) : (
                                                  nestedVal.value
                                                )
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-5">
                {subsystems.bms.ahuanalytics.rightpanel.map((cd) => (
                  <div className="p-1">
                    <LineHighChart data={cd} style={chartStyle}></LineHighChart>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {showWindow && <Ahutabs closeWindow={closeWindow} active={activeTab} label={"ahuTabs"} />}
      </div>
      {showframe && (
        <div>
          <div className="minimize mb-5">
            <img src="/minimize-s.png" className="mini-traf" alt="" onClick={closeIframe}></img>
          </div>
          <br />
          <div className="ahuWindow">
            <img
              className="ahu-diagram"
              src={require("../../assets//icons/ahu_line_diagram.png")}
              style={{ marginLeft: "10vw", height: "80vh", width: "80vw" }}
              alt=""
            />
            <div className="exhaust2">Exhaust Air 32°C</div>
            <div className="fresh2">Fresh Air 24°C</div>
            <div className="rat2">
              RAT
              <span>
                <input
                  className={`cursor-pointer ${commonContext.ahuIncident ? "random" : "random-none"}`}
                  id="RAT"
                  readOnly={true}
                  value={commonContext.inputFieldValue}
                />
              </span>
            </div>
            <div className="water-in2">Chilled Water In 10°C</div>
            <div className="water-out2">Hot Water Out 26°C</div>
            <div className="supply-air2">Supply Air 24°C</div>
            <div className="air-condition2">Air Conditional Zone</div>
          </div>
        </div>
      )}

      {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
    </div>
  );
}

export default AhuAnalytics;

import React, { useContext, useEffect, useRef, useState } from "react";
import AdvisoryModel from "../components/common/advisoryModel";
import { CommonServiceContext } from "../config/commonContext";
import PieChart from "../components/echarts/pieChart";
import BarChart from "../components/echarts/bar-chart";
import AreaChart from "../components/echarts/area";
import LineHighChart from "../components/highcharts/lineHighCharts";
import subsystems from "../assets/subsystem.json";
import Forecast from "../components/bms/forecast";
import { ChartServiceContext } from "../config/chartContext";

function Dashboardtabs({ closeWindow, active, building, label }) {
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [buildingId, setBuildingId] = useState();
  const [showIssue, setShowIssue] = useState("");
  const [isForecast, setIsForecast] = useState(false);
  const [advid, setadvid] = useState();
  const [activeTab, setActiveTab] = useState("analytics");
  const [showAdv, setShowAdv] = useState(false);
  const [show3dViewButton, setShow3dViewButton] = useState(false);
  const chartContext = useContext(ChartServiceContext);
  const commonContext = useContext(CommonServiceContext);
  const visio = useRef();

  useEffect(() => {
    async function getData() {
      let mydata = await commonContext.getData();
      if (mydata) {
        setData(mydata.data);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (active) setActiveTab(active);
  }, [active]);
  function handleClick() {
    setShow(true);
  }
  const chartStyle = {
    height: "85vh",
    width: " 100%",
  };

  const handleSop = (event, id) => {
    setShowAdv(true);
    const advEntry = data.advisory.bms.find((adv) => adv.id === id);
    setadvid(id);
    if (advEntry) {
      setShowIssue(advEntry);
    }
  };

  function sopTab() {
    setActiveTab("Incidents");
    setShowAdv(false);
  }

  const sendMessageToIframe = () => {
    const msgdata = {
      buildingID: "BT07",
      floorID: "BT07-UL0",
      mode: "floor",
    };
    visio?.current?.contentWindow.postMessage(msgdata, "*");
  };

  const limitedData = data && data.advisory && Array.isArray(data.advisory.bms) ? data.advisory.bms.slice(0, 6) : [];
  return (
    <div>
      {label === "BmsTabs" && (
        <div className="frameWindow">
          <div>
            <div className="tabs">
              <div className="tabOptions mb-2">
                <div>
                  <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                    Incidents
                  </button>
                </div>
                <div>
                  <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                    Analytics
                  </button>
                </div>
                <div>
                  <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                    Advisory
                  </button>
                </div>

                <div className="minimize mt-2">
                  <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow}></img>
                </div>
              </div>
              <div className="tabContent">
                {activeTab === "analytics" && (
                  <div className="row">
                    {subsystems.bms.charts.leftpanel.map((chData) =>
                      chData.chartType === "pie" ? (
                        <div className="col-4">
                          <PieChart buildingId={buildingId} data={chData} style={chartStyle} />
                        </div>
                      ) : (
                        <div className="col-4">
                          <BarChart buildingId={buildingId} data={chData} style={chartStyle} />
                        </div>
                      )
                    )}
                  </div>
                )}
                {activeTab === "advisory" && (
                  <div className="p-1">
                    {buildingId === "T07" ? (
                      <table className="d-block text-light table px-0" style={{ height: "29vh" }}>
                        <tr className="d-flex justify-content-between  align-items-baseline">
                          <th className="col-11 fs-6 px-4">Observation</th>
                          <th className="col-auto px-0 fs-6">Action</th>
                        </tr>
                        {limitedData.map((adv, i) => {
                          if (i === 0 && !chartContext.ahuIncident) {
                            return null;
                          }
                          return (
                            <tr key={adv.id} className="d-flex justify-content-between px-4">
                              <td className="col-10 text-justify py-1 border-0 text fs-7">
                                {adv.observation}
                                <span
                                  className="span-color"
                                  onClick={() => {
                                    setShow3dViewButton(true);
                                    sendMessageToIframe();
                                  }}
                                >
                                  {adv?.assetId}
                                </span>
                              </td>
                              <td>
                                <button
                                  key={adv.id}
                                  className="col-auto myBtns text-white cursor-pointer"
                                  onClick={(event) => handleSop(event, adv.id)}
                                >
                                  ACT
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    ) : (
                      <table className="d-block text-light table px-0 advtable">
                        <tr className="d-flex justify-content-between  align-items-baseline">
                          <th className="col-11 fs-6 px-4">Observation</th>
                          <th className="col-auto px-0 fs-6">Action</th>
                        </tr>
                        {data &&
                          data.advisory &&
                          data.advisory.bms.map((adv, i) => {
                            if (i === 0 && !chartContext.ahuIncident) {
                              return null;
                            }
                            return (
                              <tr key={adv.id} className="d-flex justify-content-between px-4">
                                <td className="col-10 text-justify py-1 border-0 text fs-7">
                                  {adv.observation}
                                  <span
                                    className="span-color"
                                    onClick={() => {
                                      setShow3dViewButton(true);
                                      sendMessageToIframe();
                                    }}
                                  >
                                    {adv?.assetId}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    key={adv.id}
                                    className=" col-auto myBtns text-white cursor-pointer"
                                    onClick={(event) => handleSop(event, adv.id)}
                                  >
                                    ACT
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </table>
                    )}
                  </div>
                )}
                {activeTab === "Incidents" && (
                  <div className="d-block mx-5">
                    <iframe className="iframetabs" src={commonContext.sop} title="Sop"></iframe>
                  </div>
                )}
              </div>
              {isForecast && <Forecast setforecast={() => setIsForecast(false)}></Forecast>}
              {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
            </div>
          </div>
        </div>
      )}
      {label === "trafficTabs" && (
        <div className="tabs">
          <div className="tabOptions">
            <div>
              <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                Incidents
              </button>
            </div>
            <div>
              <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                Analytics
              </button>
            </div>
            <div>
              <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                Advisory
              </button>
            </div>
            <div className="minimize mt-2">
              <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow}></img>
            </div>
          </div>
          <div className="tabContent">
            {activeTab === "analytics" && (
              <div className="row">
                {subsystems.traffic.charts.leftpanel.map((chData) =>
                  chData.chartType === "pie" ? (
                    <div className="col-4">
                      <PieChart data={chData} style={chartStyle} />
                    </div>
                  ) : chData.chartType === "area" ? (
                    <div className="col-4">
                      <AreaChart data={chData} style={chartStyle} />
                    </div>
                  ) : (
                    <div className="col-4">
                      <BarChart data={chData} style={chartStyle} />
                    </div>
                  )
                )}
              </div>
            )}
            {activeTab === "advisory" && (
              <table className="d-block text-light table px-0 advtable">
                <tr className="d-flex justify-content-between  align-items-baseline">
                  <th className="col-11 fs-6 px-4">Observation</th>
                  <th className="col-auto px-3 fs-6">Action</th>
                </tr>
                {data &&
                  data.advisory &&
                  data.advisory.traffic.map((adv) => (
                    <tr key={adv.id} className="d-flex justify-content-between px-4">
                      <td className="col-9 text-justify py-1 border-0 text fs-7">{adv.observation}</td>
                      <td>
                        <button key={adv.id} className=" col-auto myBtns text-white cursor-pointer" onClick={(event) => handleSop(event, adv.id)}>
                          ACT
                        </button>
                      </td>
                    </tr>
                  ))}
              </table>
            )}
            {activeTab === "Incidents" && (
              <div className="d-block mx-5">
                <iframe src={commonContext.sop} title="Sop" className="iframetabs"></iframe>
              </div>
            )}
          </div>
        </div>
      )}
      {label === "ahuTabs" && (
        <div className="frameWindow">
          <div className="tabs">
            <div className="tabOptions mb-2">
              <div>
                <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                  Incidents
                </button>
              </div>
              <div>
                <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                  Analytics
                </button>
              </div>
              <div>
                <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                  Advisory
                </button>
              </div>
              <div>
                <button onClick={() => setActiveTab("realtime")} className={`tabBtns ${activeTab === "realtime" ? "active" : null}`}>
                  Realtime
                </button>
              </div>

              <div className="minimize mt-2">
                <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow}></img>
              </div>
            </div>
            <div className="tabContent ">
              {activeTab === "analytics" && (
                <div className="row">
                  {subsystems.bms.ahuanalytics.leftpanel.map((cd) => (
                    <div className="col-4">
                      <LineHighChart data={cd} style={chartStyle}></LineHighChart>
                    </div>
                  ))}
                </div>
              )}
              {activeTab === "advisory" && (
                <div className="p-1 ">
                  <table className="d-block text-light table px-0" style={{ height: "90vh" }}>
                    <tr className="d-flex justify-content-between  align-items-baseline">
                      <th className="col-11 fs-6 px-4">Observation</th>
                      <th className="col-auto px-0 fs-6">Action</th>
                    </tr>
                    {limitedData.map(
                      (adv, i) =>
                        chartContext.ahuIncident && (
                          <tr key={adv.id} className="d-flex justify-content-between px-4">
                            <td className="col-10 text-justify py-1 border-0 text fs-7">
                              {adv.observation}
                              <span className="span-color">{adv?.assetId}</span>
                            </td>
                            <td>
                              <button
                                key={adv.id}
                                className=" col-auto myBtns text-white cursor-pointer"
                                onClick={(event) => handleSop(event, adv.id)}
                              >
                                ACT
                              </button>
                            </td>
                          </tr>
                        )
                    )}
                  </table>
                </div>
              )}
              {activeTab === "Incidents" && (
                <div style={{ height: "90vh" }}>
                  <iframe src={commonContext.sop} title="Sop" style={{ height: "100%", width: "100%" }}></iframe>
                </div>
              )}
              {activeTab === "realtime" && (
                <div className=" realtimeCard p-1" style={{ height: "90vh" }}>
                  {subsystems.bms.ahuanalytics.realTimeData.description.map((item, index) => (
                    <div key={index} className="text-light fs-7 text-center m-2 realtimeContent p-1">
                      <div className="fs-5 fw-bold  mt-4 p-4">{item.title}</div>
                      <div className="row">
                        {item.details.map((val, idx) => (
                          <div key={idx} className="col-6 mt-3">
                            <div>
                              <span className="fs-5 fw-bold">{val.title} : </span>
                              <span className="fs-6">
                                &nbsp;
                                {val.value ? (
                                  val.value === "random" ? (
                                    <input
                                      className={`cursor-pointer ${commonContext.incident ? "randomRat" : "random-none"}`}
                                      id="RAT"
                                      readOnly
                                      value={commonContext.inputFieldValue}
                                    />
                                  ) : (
                                    val.value
                                  )
                                ) : null}
                              </span>
                            </div>

                            {val.details && val.details.length > 0 && (
                              <div className="nested-details d-flex flex-row mx-2 mt-4">
                                {val.details.map((nestedVal, nestedIdx) => (
                                  <div key={nestedIdx} className="d-flex flex-column col-6 ">
                                    <div className="d-flex flex-column mx-2 ">
                                      <div className="fs-5 fw-bold">{nestedVal.title}</div>
                                      <div className="fs-6">
                                        &nbsp;
                                        {nestedVal.value ? (
                                          nestedVal.value === "random" ? (
                                            <input
                                              className={`cursor-pointer ${commonContext.incident ? "randomRat" : "random-none"}`}
                                              id="RAT"
                                              readOnly
                                              value={commonContext.inputFieldValue}
                                            />
                                          ) : (
                                            nestedVal.value
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboardtabs;

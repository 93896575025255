/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";

function Sop({ closeSOP, settab }) {
  return (
    <div className="sop-body">
      <div className="modal d-block modal-sm z-4 " tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between px-2">
              <h5 className="modal-title"></h5>
              <div className="btn-padding w-10">
                <button type="button" className="close btn bttn" onClick={() => closeSOP()} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body p-2 justify-content-between">
              <div id="panel">
                <div className="text-center p-20px">SOP Created Successfully!</div>
                <div className="text-center">
                  <button onClick={() => settab("realtime")} className="btn bttn m-0">
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sop;

import React, { useEffect, useState, useContext } from "react";
import ReactECharts from "echarts-for-react";
import { ChartServiceContext } from "../../config/chartContext";

function Assetchart(props) {
  const { data, name, handleSearch } = props;

  const [chartType, setchartType] = useState("pie");
  const [chartData, setChartData] = useState();
  const [option, setOption] = useState({});
  const [modalContent, setModalContent] = useState();
  const chartContext = useContext(ChartServiceContext);

  useEffect(() => {
    if (data) {
      setChartData(data);
    }
  }, [data]);

  function handlechartType(event) {
    setchartType(event.target.value);
  }

  useEffect(() => {
    if (chartContext.openModel) {
      setModalContent(chartContext.chartData);
    }
  }, [chartContext.openModel]);

  useEffect(() => {
    let newOption;

    if (chartType === "pie") {
      newOption = {
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: chartContext.colorStops.blueShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: chartContext.colorStops.yellowShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: chartContext.colorStops.blueShade,
          },
        ],
        title: {
          text: modalContent?.key === "traffic" ? `Traffic distribution by ${name}-Top 20` : `Alarm distribution by ${name}-Top 20`,
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "80%",
          itemHeight: 10,
          itemWidth: 15,
          orient: "horizontal",
          bottom: "center",
          textStyle: {
            fontSize: 8,
            color: "white",
          },
        },
        xAxis: {
          show: false,
        },
        series: [
          {
            type: chartType,
            radius: "55%",
            center: ["50%", "42%"],
            labelLine: {
              show: false,
            },

            label: {
              position: "inside",
              formatter: "{c}",
              color: "white",
              fontSize: 10,
            },
            data: chartData?.map((item) => ({
              value: item[1],
              name: item[0],
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    } else if (chartType === "bar") {
      newOption = {
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: chartContext.colorStops.blueShade,
          },
        ],
        title: {
          text: modalContent?.key === "traffic" ? `Traffic distribution by ${name} -Top 20` : `Alarm distribution by ${name} -Top 20`,
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "75%",
          itemHeight: 10,
          itemWidth: 15,
          orient: "horizontal",
          bottom: "center",
          textStyle: {
            fontSize: 8,
            color: "white",
          },
        },
        xAxis: {
          type: "category",
          data: chartData?.map((item) => item[0]),
          axisLabel: {
            color: "white",
            fontSize: 8,
            interval: 0,
            rotate: 30,
          },

          axisLine: {
            lineStyle: {
              color: "#ffffff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#ffffff",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#ffffff",
            },
          },
        },
        series: [
          {
            type: chartType,
            data: chartData?.map((item) => item[1]),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    }

    setOption(newOption);
  }, [chartData, chartType, name]);

  const onEvents = (chart) => {
    chart.on("click", (event) => {
      handleSearch(event.data.name, data);
    });
  };

  return (
    <div className="chart-container mt-2">
      <select className="dropdown" value={chartType} onChange={handlechartType}>
        <option value="pie" className="opt">
          Pie
        </option>
        <option value="bar" className="opt">
          Bar
        </option>
      </select>
      <ReactECharts option={option} style={{ width: "100%", height: "50vh" }} onChartReady={onEvents} />
    </div>
  );
}

export default Assetchart;

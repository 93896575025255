/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import BarChart from "../echarts/bar-chart";
import PieChart from "../echarts/pieChart";
import AreaChart from "../echarts/area";
import LineChart from "../echarts/lineChart";
import Sop from "../common/sop.jsx";
import { ChartServiceContext } from "../../config/chartContext.jsx";
import { CommonServiceContext } from "../../config/commonContext.js";
import ChartDetails from "../common/chartDetails.jsx";
import Iframe from "../iframes.jsx";
import TrafficTabs from "../dashboardtabs.jsx";
import AdvisoryModel from "../common/advisoryModel.jsx";
import subsystems from "../../assets/subsystem.json";

const Traffic = () => {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("analytics");
  const [showAdv, setShowAdv] = useState(false);
  const [showIssue, setShowIssue] = useState("");
  const chartContext = useContext(ChartServiceContext);
  const [Tab, setTab] = useState("2D");
  const commonContext = useContext(CommonServiceContext);
  const [data, setData] = useState();
  const [showWindow, setShowWindow] = useState(false);
  const [showframe, setShowframe] = useState(false);
  const [sopSuccessful, setSopSuccessFul] = useState(false);

  useEffect(() => {
    async function getData() {
      let mydata = await commonContext.getData();
      if (mydata) {
        setData(mydata.data);
      }
    }
    getData();
  }, []);

  const [selectedDate, setSelectedDate] = useState("20-08-2024");
  const [selectedtime, setSelectedTime] = useState("00:00:00");

  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;
      if (data[0] && data[1]) {
        setSelectedDate(data[0]);
        setSelectedTime(data[1]);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  function handleClick() {
    setShow(true);
  }

  const handleSop = (event, id) => {
    setShowAdv(true);
    const advEntry = data.advisory.traffic.find((adv) => adv.id === id);
    if (advEntry) {
      setShowIssue(advEntry);
    }
  };

  const chartStyle = {
    height: "26vh",
    width: "100%",
  };
  function openWindow() {
    setShowWindow(true);
  }
  function closeWindow() {
    setShowWindow(false);
  }
  function openIframe() {
    setShowframe(true);
  }
  function closeIframe() {
    setShowframe(false);
  }

  function closeSOP() {
    setSopSuccessFul(false);
  }

  function sopTab() {
    setActiveTab("Incidents");
    setShowAdv(false);
  }

  return (
    <div className="h-100vh">
      {!showframe && !showWindow && (
        <div>
          <div className="row ">
            <div className="mytabs">
              <div className="tabOptions">
                <div>
                  <button onClick={() => setTab("3D")} className={`tabBtns ${Tab === "3D" ? "active" : null}`}>
                    Live Traffic
                  </button>
                </div>
                <div>
                  <button onClick={() => setTab("2D")} className={`tabBtns ${Tab === "2D" ? "active" : null}`}>
                    Scenario Analysis
                  </button>
                </div>

                <div>
                  <button onClick={() => setTab("~")} className={`tabBtns ${Tab === "~" ? "active" : null}`}>
                    Congestion Hotspot
                  </button>
                </div>
                <th className="mini col-6">
                  <img src="/maximize.png" className="maximize" alt="" onClick={openIframe}></img>
                </th>
              </div>
            </div>
            <div className="col-7">
              {Tab === "2D" && <iframe className="iframe" src={commonContext.trafficIframeURL} title="traffic"></iframe>}
              {Tab === "3D" && <iframe className="iframe" src={commonContext.traffic3DIframeURL} title="traffic"></iframe>}
              {Tab === "~" && <iframe className="iframe" src={commonContext.trafficdeckglIframeURL} title="traffic"></iframe>}
              <div className="mytabs">
                <div className="tabOptions">
                  <div>
                    <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                      Incidents
                    </button>
                  </div>
                  <div>
                    <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                      Analytics
                    </button>
                  </div>
                  <div>
                    <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                      Advisory
                    </button>
                  </div>

                  <div>
                    <th className="mini ">
                      <img src="/maximize.png" className="maximize mb-3" alt="" onClick={openWindow}></img>
                    </th>
                  </div>
                </div>
                <div className="tabContent">
                  {activeTab === "analytics" && (
                    <div className="row">
                      {subsystems.traffic.charts.leftpanel.map((chData) =>
                        chData.chartType === "pie" ? (
                          <div className="col-4">
                            <PieChart data={chData} style={chartStyle} />
                          </div>
                        ) : chData.chartType === "area" ? (
                          <div className="col-4">
                            <AreaChart data={chData} style={chartStyle} />
                          </div>
                        ) : (
                          <div className="col-4">
                            <BarChart data={chData} style={chartStyle} />
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {activeTab === "advisory" && (
                    <table className="d-block text-light table px-0" style={{ height: "29vh" }}>
                      <tr className="d-flex justify-content-between  align-items-baseline">
                        <th className="col-11 fs-6 px-4">Observation</th>
                        <th className="col-auto px-3 fs-6">Action</th>
                        <th onClick={handleClick} className="col-auto mr-2">
                          <i className="fa-solid fa-expand" style={{ height: "1em" }}></i>
                        </th>
                      </tr>
                      {data &&
                        data.advisory &&
                        data.advisory.traffic.map((adv) => (
                          <tr key={adv.id} className="d-flex justify-content-between px-4">
                            <td className="col-9 text-justify py-1 border-0 text fs-7">{adv.observation}</td>
                            <td>
                              <button
                                key={adv.id}
                                className=" col-auto myBtns text-white cursor-pointer"
                                onClick={(event) => handleSop(event, adv.id)}
                              >
                                ACT
                              </button>
                            </td>
                          </tr>
                        ))}
                    </table>
                  )}
                  {activeTab === "Incidents" && (
                    <div className=" d-block">
                      <iframe src={commonContext.sop} title="Sop" style={{ height: "30vh", width: "100%" }}></iframe>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="d-flex justify-content-between fc-white m-1"></div>
              {subsystems.traffic.charts.rightpanel.map((chData) =>
                chData.chartType === "line" ? (
                  <div className="p-1">
                    <LineChart data={chData} selectedDate={selectedDate} selectedtime={selectedtime} style={chartStyle} />
                  </div>
                ) : (
                  <div className="p-1">
                    <BarChart data={chData} selectedDate={selectedDate} selectedtime={selectedtime} style={chartStyle} />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
      {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
      {show && (
        <div className="modal d-block modal-xl z-0" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-between px-2">
                <h5 className="modal-title">Advisory</h5>
                <div>
                  <button type="button" className="close btn bttn w-10" onClick={() => setShow(null)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body p-2 justify-content-between">
                {data &&
                  data.advisory &&
                  data.advisory.traffic.map((adv) => (
                    <tr key={adv.id} className="mdCont">
                      <td className="text-justify py-2 border-0 font">{adv.observation}</td>
                      <td>
                        <button key={adv.id} className="myBtns text-white cursor-pointer" onClick={(event) => handleSop(event, adv.id)}>
                          ACT
                        </button>
                      </td>
                    </tr>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {chartContext.openModel && <ChartDetails></ChartDetails>}
      {showframe && <Iframe closeIframe={closeIframe} active={Tab} label={"traffic"} />}
      {showWindow && <TrafficTabs closeWindow={closeWindow} active={activeTab} label={"trafficTabs"} />}
      {sopSuccessful && (
        <Sop
          closeSOP={closeSOP}
          settab={() => {
            setActiveTab("Incidents");
            closeSOP();
            setShowAdv(false);
          }}
        ></Sop>
      )}
    </div>
  );
};

export default Traffic;
